<template>
  <template v-if="!loading">
    <vl-grid v-vl-spacer:bottom.medium class="section">
      <vl-column width="8" width-xs="12">
        <vl-title tag-name="h3"> {{ $t('deliveryentity.detail.title') }} </vl-title>
      </vl-column>
      <vl-column v-if="editStore.canEdit" width="4" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button
            icon="plus"
            mod-icon-before
            :mod-disabled="editStore.isCreate(DeliveryProcessDefinitionEditType.DeliveryEntity)"
            @click="editStore.openCreate(DeliveryProcessDefinitionEditType.DeliveryEntity)"
            >{{ $t('general.add') }}</vl-button
          >
        </vl-action-group>
      </vl-column>
    </vl-grid>
    <vl-grid :class="{ edit: editStore.isCreate(DeliveryProcessDefinitionEditType.DeliveryEntity) }">
      <vl-column>
        <DeliveryEntityCreate
          v-if="editStore.isCreate(DeliveryProcessDefinitionEditType.DeliveryEntity)"
          :specification-id="id"
        />
      </vl-column>
    </vl-grid>
    <vl-grid v-vl-spacer:bottom.medium class="section">
      <vl-column width="12" width-xs="12">
        <vl-accordion-list v-if="items && items.length">
          <vl-accordion-list-item v-for="(entity, i) in items" :id="i" :key="i">
            <vl-accordion :id="i.toString()" :title="getLabel(entity)">
              <vl-grid :class="{ edit: editStore.isEdit(DeliveryProcessDefinitionEditType.DeliveryEntity, entity.id) }">
                <vl-column width="8">
                  <DeliveryEntityRead
                    v-if="!editStore.isEdit(DeliveryProcessDefinitionEditType.DeliveryEntity, entity.id)"
                    :detail="entity"
                  />
                  <DeliveryEntityEdit v-else ref="editForm" :detail="entity" :specification-id="id" />
                </vl-column>
                <vl-column v-if="editStore.canEdit" width="4">
                  <vl-action-group
                    v-if="entity && !editStore.isEdit(DeliveryProcessDefinitionEditType.DeliveryEntity, entity.id)"
                    mod-align-right
                    mod-space
                    between
                  >
                    <vl-button
                      icon="pencil"
                      mod-icon-before
                      mod-secondary
                      :mod-disabled="editStore.editing"
                      @click="editStore.openEdit(DeliveryProcessDefinitionEditType.DeliveryEntity, entity.id)"
                      >{{ $t('general.edit') }}</vl-button
                    >
                    <vl-button
                      v-if="!hasTransformation(entity.id) && !hasLink(entity.id)"
                      icon="trash"
                      mod-icon-before
                      mod-error
                      @click="editStore.openDelete(DeliveryProcessDefinitionEditType.DeliveryEntity, entity.id)"
                      >{{ $t('general.delete') }}</vl-button
                    >
                  </vl-action-group>
                  <vl-action-group v-else mod-align-right mod-space between>
                    <vl-button icon="save" x mod-icon-before :mod-loading="reloading" @click="save">{{
                      $t('general.save')
                    }}</vl-button>
                    <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeEdit">
                      {{ $t('general.cancel') }}
                    </vl-button>
                  </vl-action-group>
                </vl-column>
              </vl-grid>
            </vl-accordion>
          </vl-accordion-list-item>
        </vl-accordion-list>
        <template v-else>
          <vl-annotation>{{ $t('deliveryentity.detail.empty') }}</vl-annotation>
        </template>
      </vl-column>
    </vl-grid>
    <vl-modal
      :id="deleteKey.toString()"
      :title="$t('deliveryentity.detail.delete.title')"
      :open="editStore.isDelete(DeliveryProcessDefinitionEditType.DeliveryEntity, deleteKey)"
    >
      <vl-action-group>
        <vl-button icon="trash" mod-icon-before mod-error @click="remove(deleteKey)">
          {{ $t('general.delete') }}
        </vl-button>
        <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeDelete">
          {{ $t('general.cancel') }}
        </vl-button>
      </vl-action-group>
    </vl-modal>
  </template>
</template>
<script lang="ts" setup>
import DeliveryEntityRead from './DeliveryEntityRead.vue';
import DeliveryEntityEdit from './DeliveryEntityEdit.vue';
import { DeliveryEntityDetailDto } from '@/api/portal-api/clients';
import { useDeliveryEntityStore } from '@/modules/configuration/store/delivery-entity/delivery-entity.store';
import {
  DeliveryProcessDefinitionEditType,
  useDeliveryProcessDefinitionEditStore,
} from '@/modules/configuration/store/delivery-process-definition/delivery-process-definition-edit.store';
import { Ref, computed, onMounted, ref } from 'vue';
import DeliveryEntityCreate from './DeliveryEntityCreate.vue';
import { useRoute } from 'vue-router';
import { useDeliveryTransformationStore } from '@/modules/configuration/store/delivery-transformation/delivery-transformation.store';
import { useDeliveryEntityLinkStore } from '@/modules/configuration/store/delivery-entity-link/delivery-entity-link.store';

const store = useDeliveryEntityStore();
const transformationStore = useDeliveryTransformationStore();
const linkStore = useDeliveryEntityLinkStore();

const editStore = useDeliveryProcessDefinitionEditStore();
const route = useRoute();

const editForm: Ref<typeof DeliveryEntityEdit | (typeof DeliveryEntityEdit)[]> = ref(null);

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const loading = computed((): boolean => {
  return store.loading;
});

const reloading = computed((): boolean => {
  return store.reloading;
});

const items = computed((): DeliveryEntityDetailDto[] => {
  return store.bySpecification(props.id);
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

onMounted(async () => {
  if (props.id) {
    await store.getBySpecification(props.id);
  }
});

const hasLink = (id: number): boolean => {
  return linkStore.bySpecification(props.id).some((x) => x.toEntity?.id === id || x.fromEntity?.id === id);
};

const hasTransformation = (id: number): boolean => {
  return transformationStore.byDefinition(parseInt(route.params.id as string)).some((x) => x.fromEntity?.id === id);
};

const save = () => {
  if (Array.isArray(editForm?.value)) {
    editForm.value[0].onSubmit();
  } else {
    editForm.value.onSubmit();
  }
};

const remove = async (id: number) => {
  await store.remove(props.id, id);
  editStore.closeDelete();
};

const getLabel = (item: DeliveryEntityDetailDto): string => {
  return `${item.code} - ${item.label}`;
};
</script>
<style lang="scss" scoped>
:deep(.vl-accordion__panel) {
  padding-right: 0;
}
.vl-grid {
  flex-grow: 1;
}
.section {
  padding-left: 20px;
}
</style>
