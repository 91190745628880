<template>
  <vl-properties-list v-vl-spacer:bottom.small>
    <vl-properties-label>{{ $t('organisations.detail.id') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.id }}</vl-properties-data>
    <vl-properties-label>{{ $t('organisations.detail.name') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.name }}</vl-properties-data>
    <vl-properties-label>{{ $t('organisations.detail.kboNumber') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.kboNumber ? props.detail.kboNumber : `-` }}</vl-properties-data>
    <vl-properties-label>{{ $t('organisations.detail.ovoNumber') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.ovoNumber ? props.detail.ovoNumber : `-` }}</vl-properties-data>
  </vl-properties-list>
</template>
<script lang="ts" setup>
import 'vue-i18n';
import { OrganisationDetailDto } from '@/api/portal-api/clients';

const props = defineProps<{
  detail: OrganisationDetailDto;
}>();
</script>
