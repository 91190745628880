import { RouteRecordRaw } from 'vue-router';
import { AuthenticatedType } from './authentication.guard';
import { AuthorizeType } from './authorizationType';
import Dummy from './dummy.vue';
import Guest from './Guest.vue';
import Unauthorized from './Unauthorized.vue';

export { createAuthenticationGuard } from './authentication.guard';

export type AuthenticateType = {
    login: (state: string) => void;
    logout: () => void;
};

export * as ClaimTypes from './claimTypes';
export * as RoleTypes from './roleTypes';
export * as Policies from './policies';

export type AuthenticationService = AuthenticatedType &
    AuthenticateType &
    AuthorizeType & { fetchUserAsync: () => Promise<void> };

export function createAuthenticationRoutes(authService: () => AuthenticateType): Array<RouteRecordRaw> {
    return [
        {
            name: 'guest',
            path: '/guest',
            meta: {
                isPublic: true,
            },
            component: Guest,
        },
        {
            name: 'unauthorized',
            path: '/unauthorized',
            meta: {
                isPublic: true,
            },
            component: Unauthorized,
        },
        {
            name: 'login',
            path: '/login',
            meta: {
                isPublic: true,
            },
            component: Dummy,
            beforeEnter: (to): void => {
                authService().login(to.query.redirectUri as string);
            },
        },
        {
            name: 'logout',
            path: '/logout',
            meta: {
                isPublic: true,
            },
            component: Dummy,
            beforeEnter: (): void => {
                authService().logout();
            },
        },
    ];
}
