import { RouteRecordRaw } from 'vue-router';
import DeliveryCreate from './DeliveryCreate.vue';
import DeliveryDetail from './DeliveryDetail.vue';
import DeliveryList from './DeliveryList.vue';

export const deliveryRoutes: RouteRecordRaw = {
    path: '/delivery',
    name: 'delivery',
    redirect: '/delivery/list',
    meta: {
        needsOrganisation: true,
    },
    children: [
        {
            path: '/delivery/list',
            name: 'delivery.list',
            component: DeliveryList,
            meta: {
                needsOrganisation: true,
            },
        },
        {
            path: 'create',
            name: 'delivery.create',
            meta: {
                needsOrganisation: true,
            },
            component: DeliveryCreate,
        },
        {
            path: '/delivery/:id',
            name: 'delivery.detail',
            component: DeliveryDetail,
            meta: {
                needsOrganisation: true,
            },
        },
    ],
};
