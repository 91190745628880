import VlDataTableExtended from './vl-data-table-extended.vue'

import type { App, Plugin } from 'vue'

const DataTablePlugin = {
  install(Vue: App) {
    Vue.component('VlDataTableExtended', VlDataTableExtended)
  }
} as Plugin

export default DataTablePlugin

export { VlDataTableExtended }
