import { defineStore } from 'pinia';

import { clients, DeliveryStatus } from '@/api';

export type DeliveryRunStoreActions = {
    fetchStatusses: () => Promise<void>;
};

export type DeliveryRunStoreState = {
    statusses: DeliveryStatus[];
};

export const useDeliveryRunStore = defineStore<string, DeliveryRunStoreState, {}, DeliveryRunStoreActions>(
    'delivery-run-store',
    {
        state: () => ({
            statusses: [] as DeliveryStatus[],
        }),
        actions: {
            async fetchStatusses(): Promise<void> {
                const statusses = await clients.DeliveryRunClient.getDeliveryStatusses();
                this.statusses = statusses;
            },
        },
    },
);

export interface IDeliveryRunStore extends DeliveryRunStoreState, DeliveryRunStoreActions {}
