<template>
  <NotFound v-if="notFound" />
  <vl-region v-if="loading">
    <div v-vl-align:center>
      <vl-loader :message="$t('general.pageLoading')" />
    </div>
  </vl-region>
  <vl-region v-if="product && !loading">
    <vl-layout>
      <vl-grid>
        <vl-column width="9" width-xs="12">
          <vl-title tag-name="h1">{{ $t('product.detail.title') }}</vl-title>
        </vl-column>
        <vl-column>
          <vl-properties>
            <vl-properties-column>
              <vl-properties-list>
                <vl-properties-label>{{ $t('product.detail.specification.label') }}</vl-properties-label>
                <vl-properties-data>{{ product.specification && product.specification.label }}</vl-properties-data>
                <vl-properties-label>{{ $t('product.detail.status.label') }}</vl-properties-label>
                <vl-properties-data>{{ product.status }}</vl-properties-data>
                <vl-properties-label v-if="product.statusId == 2">{{
                  $t('product.detail.reason.label')
                }}</vl-properties-label>
                <vl-properties-data v-if="product.statusId == 2">{{ product.failReason }}</vl-properties-data>
                <vl-properties-label>{{ $t('product.detail.organisation.label') }}</vl-properties-label>
                <vl-properties-data></vl-properties-data>
                <vl-properties-label>{{ $t('product.detail.product.label') }}</vl-properties-label>
                <vl-properties-data></vl-properties-data>
              </vl-properties-list>
            </vl-properties-column>
          </vl-properties>
        </vl-column>
        <vl-column>
          <vl-properties>
            <vl-properties-column mod-full>
              <vl-properties-title>{{ $t('product.detail.metadata.title') }}</vl-properties-title>
              <vl-properties-list>
                <vl-properties-label>{{ $t('product.detail.metadata.uuid.label') }}</vl-properties-label>
                <vl-properties-data
                  ><a :href="product.metadata && product.metadata.metadataCenterURL" class="vl-link" target="_blank">
                    {{ product.metadata && product.metadata.uuid }}
                  </a></vl-properties-data
                >
                <vl-properties-label>{{ $t('product.detail.metadata.versionDate.label') }}</vl-properties-label>
                <vl-properties-data>{{ dateFilter(product.metadata?.versionDate) }}</vl-properties-data>
                <vl-properties-label>{{ $t('product.detail.metadata.temporalLimitation.label') }}</vl-properties-label>
                <vl-properties-data>
                  {{ dateFilter(product.metadata?.temporalLimitationFrom) }} -
                  {{
                    dateFilter(product.metadata?.temporalLimitationTo, $t('product.detail.noEndDate.label'))
                  }}</vl-properties-data
                >
                <vl-properties-label>{{ $t('product.detail.metadata.versionDescription.label') }}</vl-properties-label>
                <vl-properties-data>{{ product.metadata && product.metadata.versionDescription }}</vl-properties-data>
              </vl-properties-list>
            </vl-properties-column>
          </vl-properties>
        </vl-column>
      </vl-grid>
    </vl-layout>
  </vl-region>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted } from 'vue';
import { ProductDetailService, ProductDetailServiceInstance } from './ProductDetail.service';
import { useRoute } from 'vue-router';
import { dateFilter } from '@/filters';
import NotFound from '@/core/security/NotFound.vue';

export default defineComponent({
  components: {
    NotFound,
  },
  setup() {
    const route = useRoute();

    const productService: ProductDetailService = ProductDetailServiceInstance;

    // lifecycle hooks
    onMounted(async () => {
      await productService.init(parseInt(route.params.id.toString(), 10));
    });
    onUnmounted(() => {
      productService.reset();
    });

    const product = computed(() => productService.state.product);
    const notFound = computed(() => productService.state.notFound);
    const loading = computed(() => productService.state.loading);

    return {
      product,
      productService,
      dateFilter,
      notFound,
      loading,
    };
  },
});
</script>
