import { clients, Supplier } from '@/api';
import { defineStore } from 'pinia';

export interface ISupplierStore extends SuppliersStoreState, SupplierStoreActions {}

export type SupplierStoreActions = {
    fetchSuppliers: () => Promise<void>;
};

export type SuppliersStoreState = {
    suppliers: Supplier[];
};

export const useSuppliersStore = defineStore<string, SuppliersStoreState, {}, SupplierStoreActions>('suppliers-store', {
    state: () => ({
        suppliers: [] as Supplier[],
    }),
    actions: {
        async fetchSuppliers(): Promise<void> {
            try {
                const suppliers = await clients.SupplierClient.getAll();
                this.suppliers = suppliers ? suppliers.sort((a, b) => a.name.localeCompare(b.name)) : [];
            } catch {
                this.suppliers = [];
            }
        },
    },
});
