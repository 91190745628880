import { defineStore } from 'pinia';
import { markRaw } from 'vue';

export interface IModalContent {
    props?: Object;
    component: Object;
    title: string;
}

export interface ModalStoreState {
    content: IModalContent | undefined | null;
    open: boolean;
}

export const useModalStore = defineStore('modal-store', {
    state: (): ModalStoreState => ({
        content: null,
        open: false,
    }),
    getters: {},
    actions: {
        showModal(content: IModalContent) {
            if (content) {
                this.content = {
                    component: markRaw(content.component),
                    title: content.title || '',
                    props: content.props,
                };
                this.open = true;
            }
        },
        hideModal() {
            this.open = false;
            this.content = null;
        },
    },
});
