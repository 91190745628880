<template>
  <vl-properties-list v-vl-spacer:bottom.small>
    <vl-properties-label>{{ $t('deliveryspecification.detail.id') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.id }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryspecification.detail.label') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.label }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryspecification.detail.description') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.description }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryspecification.detail.scheme') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.schema }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryspecification.detail.tolerance') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.tolerance }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryspecification.detail.hasExactPrecision') }}</vl-properties-label>
    <vl-properties-data>{{
      props.detail.hasExactPrecision ? $t('general.yes') : $t('general.no')
    }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryspecification.detail.skipImport') }}</vl-properties-label>
    <vl-properties-data>{{
      props.detail.skipImport ? $t('general.yes') : $t('general.no')
    }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryspecification.detail.skipCheck') }}</vl-properties-label>
    <vl-properties-data>{{
      props.detail.skipCheck ? $t('general.yes') : $t('general.no')
    }}</vl-properties-data>
  </vl-properties-list>
</template>

<script lang="ts" setup>
import { DeliverySpecificationDetailDto } from '@/api/portal-api/clients';

const props = defineProps<{
  detail: DeliverySpecificationDetailDto;
}>();
</script>
