<template>
  <vl-properties-list v-vl-spacer:bottom.small>
    <vl-properties-label>{{ $t('deliveryspecification.detail.id') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.id }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryspecification.detail.label') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.label }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryspecification.detail.description') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.description }}</vl-properties-data>
  </vl-properties-list>
</template>

<script lang="ts" setup>
import { OperationSpecificationDetailDto } from '@/api/portal-api/clients';

const props = defineProps<{
  detail: OperationSpecificationDetailDto;
}>();
</script>
