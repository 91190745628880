<template>
  <vl-layout>
    <template v-if="!loading">
      <vl-grid>
        <vl-column>
          <vl-properties-list>
            <vl-properties-label>{{ $t('productprocessdefinition.detail.id') }}</vl-properties-label>
            <vl-properties-data>{{ detail.id }}</vl-properties-data>
          </vl-properties-list>
        </vl-column>
        <form @submit="onSubmit">
          <vl-grid>
            <vl-form-column v-vl-spacer:bottom.small>
              <PbsInputField
                name="label"
                :label="t('productprocessdefinition.detail.label')"
                :placeholder="t('productprocessdefinition.detail.label')"
              />
            </vl-form-column>
            <vl-form-column v-vl-spacer:bottom.small>
              <PbsTextAreaField
                name="description"
                :label="t('productprocessdefinition.detail.description')"
                :placeholder="t('productprocessdefinition.detail.description')"
              />
            </vl-form-column>
            <vl-form-column v-vl-spacer:bottom.small>
              <PbsSelectField
                name="organisation"
                :label="t('productprocessdefinition.detail.organisation')"
                :placeholder="$t('productprocessdefinition.detail.organisation')"
                :options="organisationOptions"
                :custom-label="getOrganisationLabel"
                @search-change="onSearchOrganisations"
              />
            </vl-form-column>
            <vl-form-column v-vl-spacer:bottom.small>
              <PbsSelectField
                name="deliveryprocessdefinition"
                :label="t('productprocessdefinition.detail.deliveryprocessdefinition')"
                :placeholder="$t('productprocessdefinition.detail.deliveryprocessdefinition')"
                :options="deliveryProcessDefinitionOptions"
                :custom-label="(e) => e.label"
                @search-change="onSearchDeliveryProcessDefinitions"
              />
            </vl-form-column>
          </vl-grid></form
      ></vl-grid>
    </template>
  </vl-layout>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted, Ref } from 'vue';
import { useProductProcessDefinitionDetailStore } from '../../store/product-process-definition/product-process-definition-detail.store';
import {
  DeliveryProcessDefinitionSearchResultDto,
  OrganisationSearchResultDto,
  ProductProcessDefinitionDetailDto,
} from '@/api/portal-api/clients';
import { object, string } from 'yup';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useOrganisationStore } from '../../store/organisation/organisation.store';
import { useDeliveryProcessDefinitionListStore } from '../../store/delivery-process-definition/delivery-process-definition-list.store';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';

const store = useProductProcessDefinitionDetailStore();

const { t } = useI18n();
const organisationStore = useOrganisationStore();
const deliveryProcessDefinitionStore = useDeliveryProcessDefinitionListStore();
const organisationOptions: Ref<OrganisationSearchResultDto[]> = ref([]);
const deliveryProcessDefinitionOptions: Ref<DeliveryProcessDefinitionSearchResultDto[]> = ref([]);

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('productprocessdefinition.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('productprocessdefinition.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('productprocessdefinition.detail.description') }),
  ),
  organisation: object().required(
    t('validations.messages.required', { _field_: t('productprocessdefinition.detail.organisation') }),
  ),
});
const { handleSubmit, resetForm } = useForm({
  validationSchema,
});

const loading = computed((): boolean => {
  return store.loading;
});

const detail = computed((): ProductProcessDefinitionDetailDto => {
  return store.detail;
});

onMounted(async () => {
  organisationOptions.value = await organisationStore.getOrganisations();
  deliveryProcessDefinitionOptions.value = await deliveryProcessDefinitionStore.getAll();

  const currentOrg = organisationOptions.value?.find((x) => x.ovoNumber === detail.value.ovoNumber);
  const currentProcessDef = deliveryProcessDefinitionOptions.value.find(
    (x) => x.id === detail.value.deliveryProcessDefinition?.id,
  );

  resetForm({ values: { ...detail.value, organisation: currentOrg, deliveryprocessdefinition: currentProcessDef } });
});

const onSuccess = async (values) => {
  await store.update(detail.value.id, {
    label: values.label,
    description: values.description,
    ovoNumber: values.organisation.ovoNumber,
    deliveryProcessDefinitionId: values.deliveryprocessdefinition?.id,
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const onSearchOrganisations = async (query: string) => {
  organisationOptions.value = organisationStore.filter(query);
};

const onSearchDeliveryProcessDefinitions = async (query: string) => {
  deliveryProcessDefinitionOptions.value = deliveryProcessDefinitionStore.search(query);
};

const getOrganisationLabel = (entry: OrganisationSearchResultDto) => {
  return `${entry?.name} (${entry?.ovoNumber})`;
};

defineExpose({ onSubmit });
</script>
