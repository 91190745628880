import { RouteRecordRaw } from 'vue-router';
import { configuratiebeheerder } from '@/core/security/roleTypes';
import Configuration from './Configuration.vue';
import EntityModelDetail from './entity-model/EntityModelDetail.vue';
import EntityModelCreate from './entity-model/EntityModelCreate.vue';
import DeliveryProcessDefinitionDetail from './delivery-process-definition/DeliveryProcessDefinitionDetail.vue';
import DeliveryProcessDefinitionCreate from './delivery-process-definition/DeliveryProcessDefinitionCreate.vue';
import ProductProcessDefinitionDetail from './product-process-definition/ProductProcessDefinitionDetail.vue';
import ProductProcessDefinitionCreate from './product-process-definition/ProductProcessDefinitionCreate.vue';
import OrganisationDetail from './organisation/OrganisationDetail.vue';
import OrganisationCreate from './organisation/OrganisationCreate.vue';
import ProcessTriggerDetail from './process-trigger/ProcessTriggerDetail.vue';
import ProcessTriggerCreate from './process-trigger/ProcessTriggerCreate.vue';

export const configurationRoutes: RouteRecordRaw = {
    path: '/configuration',
    meta: {
        hasRoles: [configuratiebeheerder],
        feature: 'configuration',
    },
    children: [
        {
            path: '',
            name: 'configuration',
            component: Configuration,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'entitymodel/:id',
            name: 'entitymodel.detail',
            component: EntityModelDetail,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'entitymodel/create',
            name: 'entitymodel.create',
            component: EntityModelCreate,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'deliveryprocessdefinition/:id',
            name: 'deliveryprocessdefinition.detail',
            component: DeliveryProcessDefinitionDetail,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'deliveryprocessdefinition/create',
            name: 'deliveryprocessdefinition.create',
            component: DeliveryProcessDefinitionCreate,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'productprocessdefinition/:id',
            name: 'productprocessdefinition.detail',
            component: ProductProcessDefinitionDetail,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'productprocessdefinition/create',
            name: 'productprocessdefinition.create',
            component: ProductProcessDefinitionCreate,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'organisation/:id',
            name: 'organisation.detail',
            component: OrganisationDetail,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'organisation/create',
            name: 'organisation.create',
            component: OrganisationCreate,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'processtrigger/:id',
            name: 'processtrigger.detail',
            component: ProcessTriggerDetail,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
        {
            path: 'processtrigger/create',
            name: 'processtrigger.create',
            component: ProcessTriggerCreate,
            meta: {
                hasRoles: [configuratiebeheerder],
            },
        },
    ],
};
