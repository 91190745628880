<template>
  <template v-if="!loading">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="7" width-xs="12">
        <vl-title tag-name="h2"> {{ $t('sourcespecification.detail.title') }} </vl-title>
      </vl-column>
      <vl-column v-if="editStore.canEdit" width="5" width-xs="12">
        <vl-action-group mod-align-right mod-space between>
          <vl-button
            v-if="items.length === 0"
            icon="plus"
            mod-icon-before
            :mod-disabled="editStore.isCreate(ProductProcessDefinitionEditType.SourceSpecification)"
            @click="editStore.openCreate(ProductProcessDefinitionEditType.SourceSpecification)"
          >
            {{ $t('general.add') }}
          </vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
    <vl-grid
      v-if="items && items.length === 1"
      :class="{ edit: editStore.isEdit(ProductProcessDefinitionEditType.SourceSpecification, items[0].id) }"
    >
      <vl-column width="8">
        <SourceSpecificationEdit
          v-if="editStore.isEdit(ProductProcessDefinitionEditType.SourceSpecification, items[0].id)"
          ref="editForm"
          :detail="items[0]"
        />
        <SourceSpecificationRead v-else :detail="items[0]" />
      </vl-column>
      <vl-column v-if="editStore.canEdit" width="4">
        <vl-action-group
          v-if="items[0] && !editStore.isEdit(ProductProcessDefinitionEditType.SourceSpecification, items[0].id)"
          mod-align-right
          mod-space
          between
        >
          <vl-button
            icon="pencil"
            mod-icon-before
            mod-secondary
            :mod-disabled="editStore.editing"
            @click="editStore.openEdit(ProductProcessDefinitionEditType.SourceSpecification, items[0].id)"
            >{{ $t('general.edit') }}</vl-button
          >
          <vl-button
            v-if="!hasChildren"
            icon="trash"
            mod-icon-before
            mod-error
            @click="editStore.openDelete(ProductProcessDefinitionEditType.SourceSpecification, items[0].id)"
            >{{ $t('general.delete') }}</vl-button
          >
        </vl-action-group>
        <vl-action-group v-else mod-align-right mod-space between>
          <vl-button icon="save" x mod-icon-before @click="save">{{ $t('general.save') }}</vl-button>
          <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeEdit">
            {{ $t('general.cancel') }}
          </vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
    <vl-accordion-list v-else-if="items && items.length">
      <vl-accordion-list-item v-for="(specification, i) in items" :id="i" :key="i">
        <vl-accordion :id="i.toString()" :title="getLabel(specification)">
          <vl-grid
            :class="{
              edit: editStore.isEdit(ProductProcessDefinitionEditType.SourceSpecification, specification.id),
            }"
          >
            <vl-column width="8">
              <SourceSpecificationEdit
                v-if="editStore.isEdit(ProductProcessDefinitionEditType.SourceSpecification, specification.id)"
                ref="editForm"
                :detail="specification"
              />
              <SourceSpecificationRead v-else :detail="specification" />
            </vl-column>
            <vl-column v-if="editStore.canEdit" width="4">
              <vl-action-group
                v-if="
                  specification &&
                  !editStore.isEdit(ProductProcessDefinitionEditType.SourceSpecification, specification.id)
                "
                mod-align-right
                mod-space
                between
              >
                <vl-button
                  icon="pencil"
                  mod-icon-before
                  mod-secondary
                  :mod-disabled="editStore.editing"
                  @click="editStore.openEdit(ProductProcessDefinitionEditType.SourceSpecification, specification.id)"
                  >{{ $t('general.edit') }}</vl-button
                >
                <vl-button
                  v-if="!hasChildren"
                  icon="trash"
                  mod-icon-before
                  mod-error
                  @click="editStore.openDelete(ProductProcessDefinitionEditType.SourceSpecification, specification.id)"
                  >{{ $t('general.delete') }}</vl-button
                >
              </vl-action-group>
              <vl-action-group v-else mod-align-right mod-space between>
                <vl-button icon="save" x mod-icon-before :mod-loading="reloading" @click="save">{{
                  $t('general.save')
                }}</vl-button>
                <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeEdit">
                  {{ $t('general.cancel') }}
                </vl-button>
              </vl-action-group>
            </vl-column>
          </vl-grid>
        </vl-accordion>
      </vl-accordion-list-item>
    </vl-accordion-list>
    <vl-grid :class="{ edit: editStore.isCreate(ProductProcessDefinitionEditType.SourceSpecification) }">
      <vl-column>
        <SourceSpecificationCreate v-if="editStore.isCreate(ProductProcessDefinitionEditType.SourceSpecification)" />
      </vl-column>
    </vl-grid>
    <vl-grid v-if="items && items.length" v-vl-spacer:bottom.medium>
      <vl-column width="12">
        <SourceEntity :specification-id="items[0].id" />
      </vl-column>
    </vl-grid>
    <vl-grid v-else v-vl-spacer:bottom.medium>
      <vl-column>
        <vl-annotation>{{ $t('sourcespecification.detail.empty') }}</vl-annotation>
      </vl-column>
    </vl-grid>

    <vl-modal
      :id="deleteKey.toString()"
      :title="$t('deliveryspecification.detail.delete.title')"
      :open="editStore.isDelete(ProductProcessDefinitionEditType.SourceSpecification, deleteKey)"
    >
      <vl-action-group>
        <vl-button v-if="!hasChildren" icon="trash" mod-icon-before mod-error @click="remove(deleteKey)">
          {{ $t('general.delete') }}
        </vl-button>
        <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeDelete">
          {{ $t('general.cancel') }}
        </vl-button>
      </vl-action-group>
    </vl-modal>
  </template>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useSourceSpecificationStore } from '@/modules/configuration/store/source-specification/source-specification.store';
import {
  ProductProcessDefinitionEditType,
  useProductProcessDefinitionEditStore,
} from '@/modules/configuration/store/product-process-definition/product-process-definition-edit.store';
import SourceSpecificationCreate from './SourceSpecificationCreate.vue';
import SourceSpecificationRead from './SourceSpecificationRead.vue';
import SourceSpecificationEdit from './SourceSpecificationEdit.vue';
import { SourceSpecificationDetailDto } from '@/api/portal-api/clients';
import SourceEntity from '../source-entity/SourceEntity.vue';
import { useSourceEntityStore } from '@/modules/configuration/store/source-entity/source-entity.store';

const store = useSourceSpecificationStore();
const editStore = useProductProcessDefinitionEditStore();
const sourceEntityStore = useSourceEntityStore();
const route = useRoute();

const editForm: Ref<typeof SourceSpecificationEdit | (typeof SourceSpecificationEdit)[]> = ref(null);

const loading = computed((): boolean => {
  return store.loading;
});

const reloading = computed((): boolean => {
  return store.reloading;
});

const items = computed((): SourceSpecificationDetailDto[] => {
  return store.byDefinition(definitionId.value);
});

const definitionId = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

const hasChildren = computed((): boolean => {
  const specificationId = items.value[0]?.id;

  const sourceEntities = sourceEntityStore.bySpecification(specificationId);

  return sourceEntities.length > 0;
});

onMounted(async () => {
  await store.getByDefinition(definitionId.value);
});

const save = () => {
  if (Array.isArray(editForm?.value)) {
    editForm.value[0].onSubmit();
  } else {
    editForm.value.onSubmit();
  }
};

const remove = async (id: number) => {
  await store.remove(definitionId.value, id);
  editStore.closeDelete();
};

const getLabel = (item: SourceSpecificationDetailDto): string => {
  return `${item.label}`;
};
</script>
<style lang="scss" scoped>
:deep(.vl-accordion__panel) {
  padding-right: 0;
}
</style>
