<template>
  <form class="vl-form" @submit.prevent="confirm()">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-properties-list v-if="data.definition">
          <vl-properties-label>{{ $t('productprocessdefinition.detail.id') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.id }}</vl-properties-data>
          <vl-properties-label>{{ $t('productprocessdefinition.detail.label') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.label }}</vl-properties-data>
          <vl-properties-label>{{ $t('productprocessdefinition.detail.organisation') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.organisation }} ({{ data.definition.ovoNumber }})</vl-properties-data>
        </vl-properties-list>
      </vl-column>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-form-message-label for="statusId">{{
          $t('productprocessdefinition.list.filter.status.label')
        }}</vl-form-message-label>
        <vl-select
          id="statusId"
          v-model="data.statusType"
          name="statusId"
          mod-block
          :mod-disabled="data.isSaving"
          :placeholder-text="$t('vl-select.placeholder')"
        >
          <option v-for="statusType in statusTypes" :key="statusType" :value="statusType">
            {{ statusType }}
          </option>
        </vl-select>
      </vl-column>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-action-group v-vl-spacer:top.medium>
          <vl-button :mod-disabled="data.isSaving" :mod-loading="data.isSaving">{{ $t('general.save') }}</vl-button>
          <vl-button :mod-disabled="data.isSaving" mod-icon-before mod-secondary tag-name="a" @click.prevent="cancel">
            {{ $t('general.cancel') }}
          </vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
  </form>
</template>
<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useProductProcessDefinitionDetailStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-detail.store';
import { useProductProcessDefinitionListStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-list.store';
import { reactive } from 'vue';
import { ENUM_StatusTypes } from '@/modules/configuration/common';
import { useModalStore } from '@/modules/core/store/modal.store';

const detailStore = useProductProcessDefinitionDetailStore();
const listStore = useProductProcessDefinitionListStore();

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const data = reactive({
  definition: null,
  statusType: null,
  isSaving: false,
});

const statusTypes = computed((): string[] => {
  return ENUM_StatusTypes.map((c) => c.name);
});

const getStatusTypeById = (id: number) => {
  return ENUM_StatusTypes.find((c) => c.id === id);
};

onMounted(async () => {
  const productProcessDefinition = await detailStore.get(props.id);
  if (productProcessDefinition) {
    data.definition = productProcessDefinition;
    data.statusType = getStatusTypeById(productProcessDefinition.statusId)?.name;
  }
});

const confirm = async () => {
  const isSuccess = await changeStatus();
  if (isSuccess) {
    updateSearchResults();
  }
  closeModal();
};

const updateSearchResults = () => {
  const item = listStore.getDefinition(props.id);
  if (item) {
    listStore.updateItem({ ...item, statusId: ENUM_StatusTypes.find((c) => c.name === data.statusType)?.id });
  }
};

const changeStatus = async (): Promise<boolean> => {
  let isSuccess = false;
  try {
    data.isSaving = true;
    isSuccess = await detailStore.changeStatus(props.id, data.statusType);
  } catch (e) {
    console.error(e);
  } finally {
    data.isSaving = false;
  }
  return isSuccess;
};

const cancel = () => {
  closeModal();
};

const closeModal = () => {
  useModalStore().hideModal();
};
</script>
<style lang="scss" scoped></style>
