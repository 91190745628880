<template>
  <vl-properties-list>
    <vl-properties-label>{{ $t('deliverytransformation.detail.id') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.id }}
    </vl-properties-data>
    <vl-properties-label>{{ $t('deliverytransformation.detail.transformationType') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.transformationType.name }}
    </vl-properties-data>
    <template v-if="isFeatureSupported('fromEntity')">
      <vl-properties-label>{{ $t('deliverytransformation.detail.fromEntity') }}</vl-properties-label>
      <vl-properties-data>
        {{ getEntityLabel(props.detail.fromEntity) }}
      </vl-properties-data>
    </template>
    <template v-if="isFeatureSupported('toEntity')">
      <vl-properties-label>{{ $t('deliverytransformation.detail.toEntity') }}</vl-properties-label>
      <vl-properties-data>
        {{ getEntityLabel(props.detail.toEntity) }}
      </vl-properties-data>
    </template>
    <template v-if="isFeatureSupported('parameter')">
      <vl-properties-label>{{ $t('deliverytransformation.detail.parameter') }}</vl-properties-label>
      <vl-properties-data>
        {{ props.detail.parameter }}
      </vl-properties-data>
    </template>
  </vl-properties-list>
  <DeliveryTransformationAttributesRead v-if="isFeatureSupported('attributeMappings')" :detail="detail" />
</template>

<script lang="ts" setup>
import {
  DeliveryEntityDetailDto,
  DeliveryTransformationDetailDto,
  OperationEntityDetailDto,
} from '@/api/portal-api/clients';
import DeliveryTransformationAttributesRead from '../delivery-transformation/DeliveryTransformationAttributesRead.vue';
import { TransformationTypeFeature, isTransformationTypeFeatureSupported } from '@/modules/configuration/common';

const props = defineProps<{
  detail: DeliveryTransformationDetailDto;
}>();

const isFeatureSupported = (feature: keyof TransformationTypeFeature) => {
  return isTransformationTypeFeatureSupported(props.detail.transformationType.id, feature);
};

const getEntityLabel = (entity: DeliveryEntityDetailDto | OperationEntityDetailDto): string => {
  return entity ? `[${entity.id}] ${entity.label}` : '-';
};
</script>
