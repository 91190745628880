import { createInstance, dateReviver } from '@/api';
import { SystemTableClient, SystemTablesDto } from '@/api/portal-api/clients';
import { useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { i18n } from '@/i18n';

export interface SystemTableListStoreState {
    loading: boolean;
    systemtables: SystemTablesDto;
}

export const client = new SystemTableClient('', createInstance(), dateReviver);

export const useSystemTableListStore = defineStore('configuration-systemTable-list-store', {
    state: (): SystemTableListStoreState => ({
        loading: false,
        systemtables: null,
    }),
    actions: {
        async getSystemTables() {
            this.loading = true;
            useAlertStore().clearAlert();
            try {
                const response = await client.get();
                this.systemtables = response;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('systemtable.list.error.title'),
                    i18n.global.t('systemtable.list.error.content'),
                );
            } finally {
                this.loading = false;
            }
        },
    },
});
