export const ENUM_StatusTypes = [
    { id: 0, name: 'Draft' },
    { id: 1, name: 'Actief' },
    { id: 2, name: 'Niet-actief' },
    { id: 3, name: 'Gearchiveerd' },
];

export const ENUM_ContextTypes = [
    { id: 0, name: 'Delivery' },
    { id: 1, name: 'Operation' },
    { id: 2, name: 'Source' },
    { id: 3, name: 'Product' },
];

export const ENUM_EntityTypes = [
    { id: 0, name: 'Point' },
    { id: 1, name: 'LineString' },
    { id: 2, name: 'Polygon' },
    { id: 3, name: 'GeometryCollection' },
    { id: 4, name: 'MultiPoint' },
    { id: 5, name: 'MultiLineString' },
    { id: 6, name: 'MultiPolygon' },
    { id: 7, name: 'CodeTable' },
    { id: 8, name: 'Table' },
];

export const ENUM_DataTypes = [
    { id: 1, name: 'Tekst' },
    { id: 2, name: 'Getal' },
    { id: 3, name: 'Vlag' },
    { id: 4, name: 'Datum' },
    { id: 5, name: 'Datum + tijd' },
];

export const ENUM_SridTypes = [
    { id: 4258, name: 'Etrs89' },
    { id: 4326, name: 'Wgs84' },
    { id: 31370, name: 'Lambert72' },
    { id: 3857, name: 'WebMercator' },
    { id: 3812, name: 'Lambert2008' },
];

export const ENUM_TransformationTypes = [
    { id: 1, name: 'Entiteit mapping' },
    { id: 2, name: 'Attribuut mapping' },
    { id: 3, name: 'Attribuut lookup' },
    { id: 4, name: 'Geometrie naar adres' },
    { id: 5, name: 'Adres naar adres' },
    { id: 6, name: 'Adres naar geometrie' },
    { id: 7, name: 'Voorbewerking' },
    { id: 8, name: 'Topologie' },
    { id: 9, name: 'Nabewerking' },
    { id: 10, name: 'Sql uitvoering' },
];

export const ENUM_TriggerTypes = [
    { id: 1, name: 'Levering trigger' },
    { id: 2, name: 'Product trigger' },
    { id: 3, name: 'Tijd trigger' },
];

export const ENUM_TimeTriggerTypes = [
    { id: 1, name: 'Levering' },
    { id: 2, name: 'Product' },
    { id: 3, name: 'SwitchJob' },
];
