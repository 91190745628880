import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { stores } from '@/store';
import { i18n } from '@/i18n';
import { createAuthenticationGuard, createAuthenticationRoutes } from '@/core/security';
import { createOrganisationGuard, createOrganisationRoutes } from '@/core/organisation';
import { deliveryRoutes } from '@/views/delivery';
import { productRoutes } from '@/views/product';
import { configurationRoutes } from '@/modules/configuration/views';
import { createHasRoleGuard } from '@/core/security/hasRole.guard';
import { alertGuard } from '@/router/guards/alert.guard';
import Home from '@/modules/core/views/home/Home.vue';
import { createHasFeatureGuard } from '@/core/security/hasFeature.guard';

const routes: RouteRecordRaw[] = [
    {
        path: '',
        props: {
            title: i18n.global.t('app.title'),
        },
        redirect: '/home',
        children: [
            ...createAuthenticationRoutes(stores.UserStore),
            ...createOrganisationRoutes(),
            {
                path: '/home',
                name: 'home',
                components: {
                    default: Home,
                },
            },
            deliveryRoutes,
            productRoutes,
            configurationRoutes,
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

router.beforeEach(alertGuard());
router.beforeEach(createHasFeatureGuard());
router.beforeEach(createAuthenticationGuard(stores.UserStore));
router.beforeEach(createOrganisationGuard(stores.UserStore, stores.OrganisationStore));
router.beforeEach(createHasRoleGuard());

export default router;
