<template>
  <FieldArray v-slot="{ fields }" name="metadata">
    <vl-accordion-list-item v-for="(field, idx) in fields" :key="field.key">
      <vl-accordion :id="field.key.toString()" :title="getFieldValue(field, `specification.label`)" :open="true">
        <vl-form-column v-vl-spacer:bottom.medium>
          <vl-form-message-label :for="`metadata[${idx}].versionDate`">
            {{ $t('delivery.create.versionDate.label') }}
            <vl-form-message-annotation style="">
              {{ $t('delivery.create.versionDate.help') }}
            </vl-form-message-annotation>
          </vl-form-message-label>
          <vl-grid>
            <vl-column width="6">
              <Field v-slot="{ handleChange, errors, value }" :name="`metadata[${idx}].versionDate`">
                <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                <!-- https://bitbucket.org/vlaamseoverheid/vl-build/src/dev/package/vl-ui-datepicker/src/vue/vl-datepicker.js -->
                <pbs-datepicker
                  :id="`metadata[${idx}].versionDate`"
                  :name="`metadata[${idx}].versionDate`"
                  :mod-error="!!errors.length"
                  :value="value"
                  :max-date="currentDate"
                  @input="onDatepickerInput(handleChange)($event)"
                >
                </pbs-datepicker>
              </Field>
            </vl-column>
          </vl-grid>
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.medium>
          <vl-form-message-label
            >{{ $t('delivery.create.temporalLimitation.label') }}
            <vl-form-message-annotation>{{ $t('delivery.create.temporalLimitation.help') }}</vl-form-message-annotation>
          </vl-form-message-label>
          <vl-grid>
            <vl-form-column width="4" widht-m="6" width-s="12">
              <vl-form-message-label :for="`metadata[${idx}].temporalLimitationFrom`">
                {{ $t('delivery.create.temporalLimitation.from.label') }}
              </vl-form-message-label>
              <Field v-slot="{ handleChange, errors, value }" :name="`metadata[${idx}].temporalLimitationFrom`">
                <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                <pbs-datepicker
                  :name="`metadata[${idx}].temporalLimitationFrom`"
                  :value="value"
                  :mod-error="!!errors.length"
                  @input="onDatepickerInput(handleChange)($event)"
                ></pbs-datepicker>
              </Field>
            </vl-form-column>
            <vl-form-column width="4" widht-m="6" width-s="12">
              <vl-form-message-label :for="`metadata[${idx}].temporalLimitationTo`">
                {{ $t('delivery.create.temporalLimitation.to.label') }}
                <vl-form-message-annotation tag-name="span">({{ $t('general.optional') }})</vl-form-message-annotation>
              </vl-form-message-label>
              <Field v-slot="{ handleChange, errors, value }" :name="`metadata[${idx}].temporalLimitationTo`">
                <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                <pbs-datepicker
                  :name="`metadata[${idx}].temporalLimitationTo`"
                  :value="value"
                  :min-date="getFieldValue(field, `temporalLimitationFrom`)"
                  :mod-error="!!errors.length"
                  @input="onDatepickerInput(handleChange)($event)"
                ></pbs-datepicker>
              </Field>
            </vl-form-column>
          </vl-grid>
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.medium>
          <vl-form-message-label :for="`metadata[${idx}].versionDescription`"
            >{{ $t('delivery.create.description.label') }}
            <vl-form-message-annotation>{{ $t('delivery.create.description.help') }}</vl-form-message-annotation>
          </vl-form-message-label>
          <Field v-slot="{ handleChange, value, errors }" :name="`metadata[${idx}].versionDescription`">
            <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
            <vl-textarea
              :id="`metadata[${idx}].versionDescription`"
              :name="`metadata[${idx}].versionDescription`"
              :placeholder="$t('delivery.create.description.placeholder')"
              rows="6"
              :modelValue="value"
              :mod-error="!!errors.length"
              mod-block
              @input="handleChange"
            ></vl-textarea>
          </Field>
        </vl-form-column>
      </vl-accordion>
    </vl-accordion-list-item>
  </FieldArray>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { format } from 'date-fns';
import PbsDatepicker from '@/components/forms/PbsDatepicker.vue';
import { Field, FieldArray } from 'vee-validate';

const currentDate = computed(() => format(new Date(), 'dd.MM.yyyy'));

const onDatepickerInput = (handleChange) => (value) => {
  handleChange(Array.isArray(value) ? value[0] : value);
};

const getFieldValue = (field: any, propertyPath: string): any => {
  const properties = propertyPath.split('.');
  return properties.reduce((prev, curr) => prev && prev[curr], field.value);
};
</script>
