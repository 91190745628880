<template>
  <router-link :to="to" :target="target ?? `_blank`">
    {{ label }}
  </router-link>
</template>

<script lang="ts" setup>
import {
  EntityModelLinkDto,
  DeliveryProcessDefinitionDetailDto,
  ProductProcessDefinitionDetailDto,
  OrganisationDetailDto,
} from '@/api/portal-api/clients';
import { computed } from 'vue';

const props = defineProps<{
  detail?:
    | EntityModelLinkDto
    | DeliveryProcessDefinitionDetailDto
    | ProductProcessDefinitionDetailDto
    | OrganisationDetailDto;
  type?: 'entityModel' | 'deliveryProcessDefinition' | 'productProcessDefinition' | 'organisation';
  target?: string;
}>();

const to = computed(() => {
  if (!props.detail) {
    return '';
  }

  switch (props.type) {
    case 'entityModel':
      return `/configuration/entitymodel/${props.detail.id}`;
    case 'deliveryProcessDefinition':
      return `/configuration/deliveryprocessdefinition/${props.detail.id}`;
    case 'productProcessDefinition':
      return `/configuration/productprocessdefinition/${props.detail.id}`;
    case 'organisation':
      return `/configuration/organisation/${props.detail.id}`;
    default:
      return '/';
  }
});

const label = computed(() => {
  if (!props.detail) {
    return '';
  }

  let suffix = '';
  switch (props.type) {
    case 'entityModel': {
      const entityModel = props.detail as EntityModelLinkDto;
      suffix = `${entityModel.label} (${entityModel.contextTypeName})`;
      break;
    }
    case 'deliveryProcessDefinition': {
      const deliveryProcessDefinition = props.detail as DeliveryProcessDefinitionDetailDto;
      suffix = deliveryProcessDefinition.label;
      break;
    }

    case 'productProcessDefinition': {
      const productProcessDefinition = props.detail as ProductProcessDefinitionDetailDto;
      suffix = productProcessDefinition.label;
      break;
    }

    case 'organisation': {
      const organisation = props.detail as OrganisationDetailDto;
      suffix = `${organisation.name} (${organisation.ovoNumber})`;
      break;
    }
  }
  return `[${props.detail.id}] ${suffix}`;
});
</script>
