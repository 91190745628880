import { defineStore } from 'pinia';

export enum VerticalPosition {
    Top,
    Bottom,
}

export enum HorizontalPosition {
    Left,
    Right,
}

export interface IAlert {
    isError?: boolean;
    isSuccess?: boolean;
    title?: string;
    content?: string;
    icon?: string;
    isToast?: boolean;
    vertical?: VerticalPosition;
    horizontal?: HorizontalPosition;
}
export interface AlertStoreState {
    alert?: IAlert;
}

export const useAlertStore = defineStore('alert-store', {
    state: (): AlertStoreState => ({
        alert: null,
    }),
    getters: {},
    actions: {
        setError(title: string, content: string, vertical?: VerticalPosition, horizontal?: HorizontalPosition) {
            const isToast = vertical !== null && horizontal != null;
            this.alert = {
                isError: true,
                icon: 'alert-triangle',
                title: title,
                content: content,
                isToast,
                vertical,
                horizontal,
            };
            if (isToast) {
                setTimeout(() => {
                    this.clearAlert(true);
                }, 5000);
            }
        },
        setSuccess(title: string, content: string, vertical?: VerticalPosition, horizontal?: HorizontalPosition) {
            const isToast = vertical !== null && horizontal != null;
            this.alert = {
                isSuccess: true,
                icon: 'check-circle',
                title: title,
                content: content,
                isToast,
                vertical,
                horizontal,
            };
            if (isToast) {
                setTimeout(() => {
                    this.clearAlert(true);
                }, 5000);
            }
        },
        setAlert(alert: IAlert) {
            this.alert = alert;
        },
        clearAlert(force = false) {
            if (!force && this.alert?.isToast) {
                return;
            }

            this.alert = null;
        },
    },
});
