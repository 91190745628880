import { AxiosInstance } from 'axios';
import { usePageStore } from '@/store/page.store';

const waitTimeInMs = 500;
let requests_tracker_count = 0;
let timer;

export function createInterceptors(axiosInstance: AxiosInstance) {
    axiosInstance.interceptors.request.use(
        (config: any) => {
            updateLoadingState({ count: 1 });
            return config;
        },
        (error: any) => {
            updateLoadingState({ count: -1 });
            return Promise.reject(error);
        },
    );

    axiosInstance.interceptors.response.use(
        (response: any) => {
            updateLoadingState({ count: -1 });
            return response;
        },
        (error: any) => {
            updateLoadingState({ count: -1 });
            return Promise.reject(error);
        },
    );

    const updateLoadingState = ({ count }: { count: number }) => {
        if (count >= 0) {
            requests_tracker_count = requests_tracker_count + count;
        } else {
            requests_tracker_count = Math.max(0, requests_tracker_count - Math.abs(count));
        }

        if (timer) {
            clearTimeout(timer);
        }

        if (requests_tracker_count == 0) {
            usePageStore().setIsLoading(false);
        }

        timer = setTimeout(() => {
            usePageStore().setIsLoading(requests_tracker_count > 0);
        }, waitTimeInMs);
    };
}
