import { NavigationGuard, NavigationGuardNext, RouteLocation } from 'vue-router';

import { Policies } from '../security';
import { OrganisationType } from './OrganisationType';
import { AuthorizeType } from '../security/authorizationType';

export interface MetadataOrganisation {
    needsOrganisation?: boolean;
}

export function createOrganisationGuard(
    authorizationService: () => AuthorizeType,
    organisationService: () => OrganisationType,
): NavigationGuard {
    return async function (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): Promise<void> {
        if (to.matched.some((m) => (m.meta as MetadataOrganisation).needsOrganisation)) {
            if (!(await organisationService().hasOrganisationAsync())) {
                const hasOrganisationImpersonationPolicy = await authorizationService().hasPolicyAsync(
                    Policies.OrganisationImpersonationPolicy,
                );
                if (hasOrganisationImpersonationPolicy) {
                    next({
                        name: 'organisation.switch',
                        query: { redirectUri: to.fullPath },
                    });
                    return;
                } else {
                    await organisationService().signin();
                }
            }
        }
        /* else {
            if (organisationService.hasOrganisation) {
                await organisationService.signout();
            }
        }*/
        next();
        return;
    };
}
