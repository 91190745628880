<template>
  <template v-if="!loading">
    <vl-grid v-vl-spacer:bottom.small>
      <vl-column width="8" width-xs="12">
        <vl-title tag-name="h2"> {{ $t('productTransformationLookupLink.detail.title') }} </vl-title>
      </vl-column>
      <vl-column v-if="editStore.canEdit" width="4" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button
            icon="plus"
            :mod-disabled="isAddDisabled"
            mod-icon-before
            @click="editStore.openCreate(ProductProcessDefinitionEditType.ProductTransformationLookupLink)"
            >{{ $t('general.add') }}</vl-button
          >
        </vl-action-group>
      </vl-column>
    </vl-grid>
    <vl-grid :class="{ edit: editStore.isCreate(ProductProcessDefinitionEditType.ProductTransformationLookupLink) }">
      <vl-column>
        <ProductTransformationLookupLinkCreate
          v-if="editStore.isCreate(ProductProcessDefinitionEditType.ProductTransformationLookupLink)"
          :source-entity="sourceEntity"
        ></ProductTransformationLookupLinkCreate>
      </vl-column>
    </vl-grid>
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <vl-accordion-list v-if="items && items.length">
          <vl-accordion-list-item v-for="(link, i) in items" :id="i" :key="i">
            <vl-accordion :id="i.toString()" :title="getLabel(link)">
              <vl-grid>
                <vl-column width="8">
                  <ProductTransformationLookupLinkEdit
                    v-if="editStore.isEdit(ProductProcessDefinitionEditType.ProductTransformationLookupLink, link.id)"
                    ref="editForm"
                    :source-entity="sourceEntity"
                    :link="link"
                  />
                  <ProductTransformationLookupLinkRead v-else :detail="link" />
                </vl-column>
                <vl-column v-if="editStore.canEdit" width="4">
                  <vl-action-group
                    v-if="
                      items &&
                      items.length &&
                      !editStore.isEdit(ProductProcessDefinitionEditType.ProductTransformationLookupLink, link.id)
                    "
                    mod-align-right
                    mod-space
                    between
                  >
                    <vl-button
                      icon="pencil"
                      mod-icon-before
                      mod-secondary
                      @click="
                        editStore.openEdit(ProductProcessDefinitionEditType.ProductTransformationLookupLink, link.id)
                      "
                      >{{ $t('general.edit') }}</vl-button
                    >
                    <vl-button
                      icon="trash"
                      mod-icon-before
                      mod-error
                      @click="
                        editStore.openDelete(ProductProcessDefinitionEditType.ProductTransformationLookupLink, link.id)
                      "
                      >{{ $t('general.delete') }}</vl-button
                    >
                  </vl-action-group>
                  <vl-action-group v-else mod-align-right mod-space between>
                    <vl-button icon="save" x mod-icon-before @click="save">{{ $t('general.save') }}</vl-button>
                    <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeEdit">
                      {{ $t('general.cancel') }}
                    </vl-button>
                  </vl-action-group>
                </vl-column>
              </vl-grid>
            </vl-accordion>
          </vl-accordion-list-item>
        </vl-accordion-list>
        <template v-else>
          <vl-annotation>{{ $t('productTransformationLookupLink.detail.empty') }}</vl-annotation>
        </template>
      </vl-column>
    </vl-grid>
  </template>
  <vl-modal
    :id="deleteKey.toString()"
    :title="$t('productTransformationLookupLink.delete.title')"
    :open="editStore.isDelete(ProductProcessDefinitionEditType.ProductTransformationLookupLink, deleteKey)"
  >
    <vl-action-group>
      <vl-button icon="trash" mod-icon-before mod-error @click="remove(deleteKey)">
        {{ $t('general.delete') }}
      </vl-button>
      <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeDelete">
        {{ $t('general.cancel') }}
      </vl-button>
    </vl-action-group>
  </vl-modal>
</template>
<script lang="ts" setup>
import { ProductTransformationLookupLinkDetailDto, SourceEntityDetailDto } from '@/api/portal-api/clients';
import {
  ProductProcessDefinitionEditType,
  useProductProcessDefinitionEditStore,
} from '@/modules/configuration/store/product-process-definition/product-process-definition-edit.store';
import { useProductTransformationLookupLinkStore } from '@/modules/configuration/store/product-transformation-lookup-link/product-transformation-lookup-link.store';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ProductTransformationLookupLinkRead from './ProductTransformationLookupLinkRead.vue';
import ProductTransformationLookupLinkCreate from './ProductTransformationLookupLinkCreate.vue';
import ProductTransformationLookupLinkEdit from './ProductTransformationLookupLinkEdit.vue';

const { t } = useI18n();
const linkStore = useProductTransformationLookupLinkStore();
const editStore = useProductProcessDefinitionEditStore();

const props = defineProps<{
  sourceEntity: SourceEntityDetailDto;
}>();

const editForm = ref(null);

const loading = computed((): boolean => {
  return linkStore.loading;
});

const items = computed((): ProductTransformationLookupLinkDetailDto[] => {
  return linkStore.bySourceEntity(props.sourceEntity?.id);
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

const isAddDisabled = computed(() => {
  return (
    editStore.isEdit(ProductProcessDefinitionEditType.SourceEntity, props.sourceEntity.id) ||
    editStore.isCreate(ProductProcessDefinitionEditType.ProductTransformationLookupLink)
  );
});

onMounted(async () => {
  if (props.sourceEntity.id) {
    await linkStore.getBySourceEntity(props.sourceEntity.id);
  }
});

const remove = async (id: number) => {
  await linkStore.remove(props.sourceEntity.id, id);
  editStore.closeDelete();
};

const getLabel = (item: ProductTransformationLookupLinkDetailDto): string => {
  const unknown = '(?)';
  const from = `${item.keyAttribute?.code ?? unknown}`;
  const to = `${item.valueAttribute?.code ?? unknown}`;
  return t('productTransformationLookupLink.detail.linkTo', { from, to });
};

const save = () => {
  editForm.value[0].onSubmit();
};
</script>
<style lang="scss" scoped>
:deep(.vl-accordion__panel) {
  padding-right: 0;
}
.vl-grid {
  flex-grow: 1;
}
</style>
