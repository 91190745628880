<template>
  <vl-properties-list>
    <vl-properties-label>{{ $t('productTransformationLookupLink.detail.id') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.id }}
    </vl-properties-data>
    <vl-properties-label>{{ $t('productTransformationLookupLink.detail.keyAttribute') }}</vl-properties-label>
    <vl-properties-data>
      {{ getEntityAttributeLabel(props.detail.keyAttribute) }}
    </vl-properties-data>
    <vl-properties-label>{{ $t('productTransformationLookupLink.detail.valueAttribute') }}</vl-properties-label>
    <vl-properties-data>
      {{ getEntityAttributeLabel(props.detail.valueAttribute) }}
    </vl-properties-data>
  </vl-properties-list>
</template>

<script lang="ts" setup>
import { EntityAttributeDetailDto, ProductTransformationLookupLinkDetailDto } from '@/api/portal-api/clients';

const props = defineProps<{
  detail: ProductTransformationLookupLinkDetailDto;
}>();

const getEntityAttributeLabel = (entity: EntityAttributeDetailDto): string => {
  return entity ? `[${entity.id}] ${entity.label}` : '-';
};
</script>
