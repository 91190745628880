<template>
  <vl-region>
    <vl-layout>
      <vl-grid>
        <vl-column>
          <vl-title tag-name="h1">{{ $t('product.metadata.title') }}</vl-title>
        </vl-column>
        <vl-column width="7" width-m="9" with-s="12">
          <vl-alert
            v-if="data.alert.visible"
            :icon="data.alert.icon"
            :mod-error="data.alert.isError"
            :mod-success="data.alert.isSuccess"
            closable
            :close-text="$t('delivery.detail.alert.closeText')"
            :title="data.alert.title"
            :content="data.alert.content"
            @close="closeAlert"
          />
        </vl-column>
        <vl-column width="7" width-m="9" with-s="12">
          <form @submit.prevent="onSubmit">
            <vl-grid mod-stacked>
              <vl-column>
                <vl-form-grid mod-stacked>
                  <vl-form-column>
                    <vl-form-message-label for="MetadataUuid"
                      >{{ $t('product.metadata.uuid.label') }}
                    </vl-form-message-label>
                    <Field v-slot="{ handleChange, value, errors }" name="uuid">
                      <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                      <vl-input-field
                        id="MetadataUuid"
                        :placeholder="$t('product.metadata.uuid.placeholder')"
                        :modelValue="value"
                        :mod-error="errors.length > 0"
                        :mod-disabled="readonly"
                        mod-block
                        @input="handleChange"
                      ></vl-input-field>
                    </Field>
                  </vl-form-column>
                  <vl-form-column>
                    <vl-form-message-label for="versionDate">
                      {{ $t('product.metadata.versionDate.label') }}
                    </vl-form-message-label>
                    <vl-form-grid>
                      <vl-column width="6">
                        <Field v-slot="{ handleChange, errors, value }" name="versionDate">
                          <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                          <pbs-datepicker
                            name="versionDate"
                            :value="value"
                            :mod-error="errors.length > 0"
                            :mod-disabled="readonly"
                            :max-date="currentDate"
                            @input="onDatepickerInput(handleChange)($event)"
                          ></pbs-datepicker>
                        </Field>
                      </vl-column>
                    </vl-form-grid>
                  </vl-form-column>
                  <vl-form-column>
                    <vl-form-message-label
                      >{{ $t('product.metadata.temporalLimitation.label') }}
                    </vl-form-message-label>
                    <vl-form-grid>
                      <vl-form-column width="6" widht-m="6" width-s="12">
                        <vl-form-message-label for="temporalLimitationFrom">
                          {{ $t('product.metadata.temporalLimitation.from.label') }}
                        </vl-form-message-label>
                        <Field v-slot="{ handleChange, errors, value }" name="temporalLimitationFrom">
                          <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                          <pbs-datepicker
                            name="temporalLimitationFrom"
                            :value="value"
                            :mod-error="errors.length > 0"
                            :mod-disabled="readonly"
                            @input="onDatepickerInput(handleChange)($event)"
                          ></pbs-datepicker>
                        </Field>
                      </vl-form-column>
                      <vl-form-column width="6" widht-m="6" width-s="12">
                        <vl-form-message-label for="temporalLimitationTo">
                          {{ $t('product.metadata.temporalLimitation.to.label') }}
                          <vl-form-message-annotation tag-name="span"
                            >({{ $t('general.optional') }})</vl-form-message-annotation
                          >
                        </vl-form-message-label>
                        <Field v-slot="{ handleChange, errors, value }" name="temporalLimitationTo">
                          <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                          <pbs-datepicker
                            name="temporalLimitationTo"
                            :value="value"
                            :mod-error="errors.length > 0"
                            :mod-disabled="readonly"
                            :min-date="values.temporalLimitationFrom"
                            @input="onDatepickerInput(handleChange)($event)"
                          ></pbs-datepicker>
                        </Field>
                      </vl-form-column>
                    </vl-form-grid>
                  </vl-form-column>
                  <vl-form-column>
                    <vl-form-message-label for="VersionDescription"
                      >{{ $t('product.metadata.description.label') }}
                    </vl-form-message-label>
                    <Field v-slot="{ handleChange, value, errors }" name="versionDescription">
                      <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                      <vl-textarea
                        id="VersionDescription"
                        :placeholder="$t('product.metadata.description.placeholder')"
                        rows="6"
                        :modelValue="value"
                        :mod-error="errors.length > 0"
                        :mod-disabled="readonly"
                        mod-block
                        @input="handleChange"
                      ></vl-textarea>
                    </Field>
                  </vl-form-column>
                </vl-form-grid>
              </vl-column>
              <vl-column>
                <div class="vl-u-spacer"></div>
                <vl-action-group>
                  <vl-button type="submit" :mod-disabled="readonly" :mod-loading="loading">{{
                    $t('product.metadata.confirm.label')
                  }}</vl-button>
                  <vl-button type="button" mod-secondary @click="cancel">{{
                    $t('product.metadata.cancel.label')
                  }}</vl-button>
                </vl-action-group>
              </vl-column>
            </vl-grid>
          </form>
        </vl-column>
      </vl-grid>
    </vl-layout>
  </vl-region>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref } from 'vue';
import { format } from 'date-fns';
import { useRoute, useRouter } from 'vue-router';
import { Form, Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import { parseFormattedDateString, yupTransformDate } from '@/helpers';
import { DEFAULT_DATE_FORMAT } from '@/common';
import { ProductMetadataServiceInstance } from './ProductMetadata.service';
import PbsDatepicker from '@/components/forms/PbsDatepicker.vue';
import { getDates } from './ProductList.utils';

const productService = ref(ProductMetadataServiceInstance); // Make the service available for Vue Change detection

const form = ref(null);
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const translations = {
  uuid: {
    required: t('validations.messages.required', {
      _field_: t('product.metadata.uuid.label'),
    }),
    uuid: t('validations.messages.uuid', {
      _field_: t('product.metadata.uuid.label'),
    }),
  },
  versionDate: {
    required: t('validations.messages.required', {
      _field_: t('product.metadata.versionDate.label'),
    }),
  },
  temporalLimitationFrom: {
    required: t('validations.messages.required', {
      _field_: t('product.metadata.temporalLimitation.from.label'),
    }),
  },
  versionDescription: {
    required: t('validations.messages.required', {
      _field_: t('product.metadata.description.label'),
    }),
  },
};

const validationSchema = yup.object({
  uuid: yup.string().required(translations.uuid.required).uuid(translations.uuid.uuid),
  versionDate: yup.date().transform(yupTransformDate).required(translations.versionDate.required),
  temporalLimitationFrom: yup.date().transform(yupTransformDate).required(translations.temporalLimitationFrom.required),
  temporalLimitationTo: yup.date().nullable().transform(yupTransformDate),
  versionDescription: yup.string().required(translations.versionDescription.required),
});

const { resetForm, handleSubmit, values } = useForm({
  validationSchema,
});

const data = reactive({
  alert: {
    visible: false,
    isError: false,
    isSuccess: false,
    title: '',
    content: '',
    icon: '',
  },
});

// lifecycle hooks
onMounted(async () => {
  await productService.value.init(parseInt(route.params.id.toString(), 10));
  setInitialFormValues();
});

// computed values
const product = computed(() => productService.value.product);
const readonly = computed(() => productService.value.readonly);
const loading = computed(() => productService.value.loading);
const currentDate = computed(() => format(new Date(), DEFAULT_DATE_FORMAT));

// methods
const onDatepickerInput = (handleChange) => (value) => {
  handleChange(Array.isArray(value) ? value[0] : value);
};

const setInitialFormValues = () => {
  const metadata = product.value.metadata;
  if (metadata) {
    const initialValues = {
      uuid: metadata.uuid,
      versionDescription: metadata.versionDescription,
      versionDate: getDates(metadata.versionDate)[0],
      temporalLimitationFrom: getDates(metadata.temporalLimitationFrom)[0],
      temporalLimitationTo: getDates(metadata.temporalLimitationTo)[0],
    };
    resetForm({ values: initialValues });
  }
};

const cancel = async () => {
  // Go back in history
  router.go(-1);
};

const update = async (payload) => {
  try {
    await productService.value.update(payload);
    alertSuccess(
      t('product.metadata.alertSucces.content').toString(),
      t('product.metadata.alertSucces.title').toString(),
    );
  } catch (e) {
    if ((e as any).status === 400) {
      (form as any).setErrors((e as any).errors);
    } else {
      alertError(e.toString(), t('product.metadata.alertError.title').toString());
    }
  }
};

const onSubmit = handleSubmit((values) => {
  const payload = {
    uuid: values.uuid,
    versionDescription: values.versionDescription,
    versionDate: parseFormattedDateString(values.versionDate),
    temporalLimitationFrom: parseFormattedDateString(values.temporalLimitationFrom),
    temporalLimitationTo: parseFormattedDateString(values.temporalLimitationTo),
  };

  update(payload);
});

const alertSuccess = (message: string, title: string) => {
  data.alert = {
    visible: true,
    isError: false,
    isSuccess: true,
    icon: 'check',
    title: title,
    content: message,
  };
};

const alertError = (message: string, title: string) => {
  data.alert = {
    visible: true,
    isError: true,
    isSuccess: false,
    icon: 'alert-circle',
    title: title,
    content: message,
  };
};

const closeAlert = () => {
  data.alert = {
    visible: false,
    isError: false,
    isSuccess: false,
    icon: '',
    title: '',
    content: '',
  };
};
</script>
