<template>
  <vl-layout>
    <vl-grid v-vl-spacer:bottom.medium class="pbs-form-group" mod-stacked-large>
      <vl-column width="2" width-s="6" width-xs="12">
        <vl-form-message-label for="id">{{ $t('organisations.list.filter.id.label') }}</vl-form-message-label>
        <vl-input-field
          id="id"
          v-model="filter.id"
          name="id"
          type="number"
          :placeholder="$t('organisations.list.filter.id.label')"
          mod-block
        />
      </vl-column>
      <vl-column width="2" width-s="6" width-xs="12">
        <vl-form-message-label for="label">{{ $t('organisations.list.filter.ovoNumber.label') }}</vl-form-message-label>
        <vl-input-field
          id="ovoNumber"
          v-model="filter.ovoNumber"
          name="ovoNumber"
          type="search"
          :placeholder="$t('organisations.list.filter.ovoNumber.label')"
          mod-block
        />
      </vl-column>
      <vl-column width="2" width-s="6" width-xs="12">
        <vl-form-message-label for="label">{{ $t('organisations.list.filter.kboNumber.label') }}</vl-form-message-label>
        <vl-input-field
          id="kboNumber"
          v-model="filter.kboNumber"
          name="kboNumber"
          type="search"
          :placeholder="$t('organisations.list.filter.kboNumber.label')"
          mod-block
        />
      </vl-column>
      <vl-column width="6" width-s="6" width-xs="12">
        <vl-form-message-label for="name">{{ $t('organisations.list.filter.name.label') }}</vl-form-message-label>
        <vl-input-field
          id="name"
          v-model="filter.name"
          name="name"
          type="search"
          :placeholder="$t('organisations.list.filter.name.label')"
          mod-block
        />
      </vl-column>

      <vl-column width="9" width-s="9" width-xs="12">
        <vl-action-group>
          <vl-button :mod-loading="loading" @click="search({ resetPager: true })">{{
            $t('organisations.list.filter.search')
          }}</vl-button>
          <vl-button mod-secondary :mod-loading="loading" @click="clear">{{
            $t('organisations.list.filter.clear')
          }}</vl-button>
        </vl-action-group>
      </vl-column>
      <vl-column width="3" width-s="3" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button mod-secondary @click="create">{{ $t('organisations.list.filter.create') }}</vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
    <vl-grid>
      <vl-data-table-extended
        v-if="items"
        :data="items"
        :columns="columnMap"
        :fetching="loading"
        :meta="metaData"
        mod-clickable-rows
        mod-zebra
        @column-clicked="columnClicked"
        @pager-clicked="pagerClicked"
      >
        <template v-if="!loading" v-slot:noresults>
          <div v-vl-align:left>
            <div class="vl-u-spacer--large">
              <vl-title tag-name="h2">{{ $t('organisations.list.table.noResults.title') }}</vl-title>
              <p>{{ $t('organisations.list.table.noResults.subtitle') }}</p>
            </div>
          </div>
        </template>
      </vl-data-table-extended>
    </vl-grid>
  </vl-layout>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { OrganisationListFilter, useOrganisationListStore } from '../../store/organisation/organisation-list.store';
import { onMounted, onUnmounted } from 'vue';
import { ColumnDefExtended } from '@/components/vl-data-table-extended/types';
import { RowData } from '@tanstack/vue-table';
import { OrganisationSearchResultDto } from '@/api/portal-api/clients';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useAlertStore, IAlert } from '@/modules/core/store/alert.store';
import { VlActionGroup } from '@govflanders/vl-ui-design-system-vue3';

const store = useOrganisationListStore();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const filter = computed((): OrganisationListFilter => {
  return store.filter ?? {};
});

const loading = computed((): boolean => {
  return store.loading;
});

const items = computed((): OrganisationSearchResultDto & { routerLink?: string }[] => {
  return store.items?.map((i) => {
    return {
      ...i,
      routerLink: `/configuration/organisation/${i.id}`,
    };
  });
});

const metaData = computed(() => {
  return store.metaData;
});

const columnMap = computed((): ColumnDefExtended<RowData>[] => {
  return [
    {
      accessorKey: 'id',
      header: t('organisations.list.table.header.id'),
      enableSorting: true,
      size: 5,
      link: true,
    },
    {
      accessorKey: 'name',
      header: t('organisations.list.table.header.name'),
      enableSorting: true,
      size: 40,
      link: true,
    },
    {
      accessorKey: 'kboNumber',
      header: t('organisations.list.table.header.kboNumber'),
      enableSorting: true,
      sortDescFirst: false,
      size: 20,
      link: true,
    },
    {
      accessorKey: 'ovoNumber',
      header: t('organisations.list.table.header.ovoNumber'),
      enableSorting: true,
      sortDescFirst: false,
      size: 20,
      link: true,
    },
  ];
});

const alert = computed((): IAlert => {
  return useAlertStore().alert;
});

onMounted(() => {
  setTimeout(async () => {
    store.init(createFilterFromRoute());
    await search();
  }, 0);
});

onUnmounted(() => {
  store.clearFilter();
});

const clear = async () => {
  store.clearFilter();
  await search();
};

const search = async ({ resetPager }: { resetPager: boolean } = { resetPager: false }) => {
  if (resetPager) {
    store.resetPager();
  }

  await store.getOrganisations();
  if (!alert.value) {
    updateRoute();
  }
};

const create = () => {
  router.push({ name: 'organisation.create' });
};

const createFilterFromRoute = (): OrganisationListFilter => {
  const filter: OrganisationListFilter = {};

  Object.keys(route.query).forEach((k) => {
    filter[k] = encodeURIComponent(route.query[k] as string | number | boolean);
  });

  return filter;
};

const updateRoute = () => {
  const query = {};
  Object.keys(store.filter).forEach((k) => {
    query[k] = encodeURIComponent(store.filter[k]);
  });

  router.push({
    ...route,
    path: route.path,
    query,
  });
};

const pagerClicked = (pageNumber: number) => {
  store.updatePage(pageNumber);
  search();
};

const columnClicked = (evt: { key: string; direction: string }) => {
  store.updateSorting(evt.key, evt.direction);
  search({ resetPager: true });
};
</script>
<style lang="scss" scoped>
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colorsRaw.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-form-group {
  background: $vl-alt-bg;
  padding: 3rem;
}
.vl-grid {
  flex-grow: 1;
}
</style>
