import ProductList from './ProductList.vue';
import ProductDetail from './ProductDetail.vue';
import ProductMetadata from './ProductMetadata.vue';
import { RouteRecordRaw } from 'vue-router';

export const productRoutes: RouteRecordRaw = {
    path: '/product',
    name: 'product',
    redirect: '/product/list',
    children: [
        { path: '/product/list', name: 'product.list', component: ProductList },
        { path: '/product/:id', name: 'product.detail', component: ProductDetail },
        { path: '/product/:id/metadata', name: 'product.metadata', component: ProductMetadata },
    ],
};
