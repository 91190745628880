<template>
  <vl-grid v-vl-spacer:top.medium>
    <vl-column width="12" width-xs="12">
      <div class="vl-u-spacer--small">
        <vl-title tag-name="h4"> {{ $t('systemtable.sridType.title') }} </vl-title>
      </div>
    </vl-column>
    <vl-column width="12" width-xs="12">
      <vl-data-table-extended
        v-if="items"
        :data="items"
        :columns="columnMap"
        :meta="metaData"
        mod-zebra
        @column-clicked="columnClicked"
      >
      </vl-data-table-extended>
    </vl-column>
  </vl-grid>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { ColumnDefExtended } from '@/components/vl-data-table-extended/types';
import { RowData } from '@tanstack/vue-table';
import { CrsDto } from '@/api/portal-api/clients';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const metaData = computed(() => {
  return {
    serverside: false,
  };
});

const props = defineProps<{
  items: CrsDto[];
}>();

const columnMap = computed((): ColumnDefExtended<RowData>[] => {
  return [
    {
      accessorKey: 'id',
      header: t('systemtable.sridType.list.table.header.id'),
      enableSorting: true,
      size: 5,
      link: true,
    },
    {
      accessorKey: 'name',
      header: t('systemtable.sridType.list.table.header.name'),
      enableSorting: true,
      size: 40,
      link: true,
    },
  ];
});

const columnClicked = (evt: { key: string; direction: string }) => {
  // TODO
};
</script>
<style lang="scss" scoped>
.vl-grid {
  flex-grow: 1;
}
</style>
