import { defineStore } from 'pinia';

import { clients, ProductStatusType } from '@/api';

export type ProductRunStoreActions = {
    fetchStatusses: () => Promise<void>;
};

export type ProductRunStoreState = {
    statusses: ProductStatusType[];
};

export const useProductRunStore = defineStore<string, ProductRunStoreState, {}, ProductRunStoreActions>(
    'product-run-store',
    {
        state: () => ({
            statusses: [] as ProductStatusType[],
        }),
        actions: {
            async fetchStatusses(): Promise<void> {
                const statusses = await clients.ProductRunClient.getProductStatusses();
                this.statusses = statusses;
            },
        },
    },
);

export interface IProductRunStore extends ProductRunStoreState, ProductRunStoreActions {}
