import { createInstance, dateReviver } from '@/api';
import {
    CreateProductEntityDto,
    ProductEntityClient,
    ProductEntityDetailDto,
    UpdateProductEntityDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useProductProcessDefinitionEditStore } from '../product-process-definition/product-process-definition-edit.store';

export interface ProductEntityStoreState {
    items?: { key: string; value: ProductEntityDetailDto[] }[];
    loading: boolean;
    reloading: boolean;
}

export const client = new ProductEntityClient('', createInstance(), dateReviver);

export const useProductEntityStore = defineStore('configuration-product-entity-store', {
    state: (): ProductEntityStoreState => ({
        items: [],
        loading: false,
        reloading: false,
    }),
    getters: {
        byDefinition: (state) => {
            return (definitionId: number): ProductEntityDetailDto[] => {
                const key = `definition-${definitionId}`;

                return state.items.find((x) => x.key === key)?.value ?? [];
            };
        },
        bySpecification: (state) => {
            return (specificationId: number): ProductEntityDetailDto[] => {
                const key = `specification-${specificationId}`;

                return state.items.find((x) => x.key === key)?.value ?? [];
            };
        },
    },
    actions: {
        async getByDefinition(definitionId: number, reloading: boolean = false) {
            const key = `definition-${definitionId}`;
            reloading ? (this.reloading = true) : (this.loading = true);
            try {
                const response = await client.getByDefinition(definitionId);
                const items = this.items.filter((x) => x.key !== key);
                if (response?.length) {
                    this.items = [...items, { key, value: response }];
                } else {
                    this.items = [...items];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productentity.detail.error.get.title'),
                    i18n.global.t('productentity.detail.error.get.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async getBySpecification(specificationId: number, reloading: boolean = false) {
            const key = `specification-${specificationId}`;
            reloading ? (this.reloading = true) : (this.loading = true);
            try {
                const response = await client.getBySpecification(specificationId);
                const items = this.items.filter((x) => x.key !== key);
                if (response?.length) {
                    this.items = [...items, { key, value: response }];
                } else {
                    this.items = [...items];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productentity.detail.error.get.title'),
                    i18n.global.t('productentity.detail.error.get.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async create(specificationId: number, dto: CreateProductEntityDto) {
            this.reloading = true;
            try {
                const response = await client.create(dto);
                useAlertStore().setSuccess(
                    i18n.global.t('productentity.detail.success.create.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                this.getBySpecification(specificationId, true);
                return response.id;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productentity.detail.create.error.title'),
                    i18n.global.t('productentity.detail.create.error.content'),
                );
            } finally {
                this.reloading = false;
                useProductProcessDefinitionEditStore().closeCreate();
            }
        },
        async update(specificationId: number, entityId: number, dto: UpdateProductEntityDto) {
            this.reloading = true;
            try {
                const response = await client.update(entityId, dto);
                useAlertStore().setSuccess(
                    i18n.global.t('productentity.detail.success.update.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                this.getBySpecification(specificationId, true);
                return response;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productentity.detail.error.update.title'),
                    i18n.global.t('productentity.detail.error.update.content'),
                );
            } finally {
                this.reloading = false;
                useProductProcessDefinitionEditStore().closeEdit();
            }
        },
        async remove(specificationId: number, entityId: number) {
            this.reloading = true;
            try {
                await client.delete(entityId);
                useAlertStore().setSuccess(
                    i18n.global.t('productentity.detail.success.delete.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                this.getBySpecification(specificationId, true);
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productentity.detail.error.delete.title'),
                    i18n.global.t('productentity.detail.error.delete.content'),
                );
            } finally {
                this.reloading = false;
                useProductProcessDefinitionEditStore().closeDelete();
            }
        },
        clear(specificationId: number) {
            const key = `specification-${specificationId}`;
            this.items = this.items.filter((x) => x.key !== key);
        },
    },
});
