<template>
  <vl-properties-list>
    <vl-properties-label>{{ $t('entitylink.detail.fromEntity') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.fromEntity ? props.detail.fromEntity.label : '-' }}
    </vl-properties-data>
    <vl-properties-label>{{ $t('entitylink.detail.toEntity') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.toEntity ? props.detail.toEntity.label : '-' }}
    </vl-properties-data>
  </vl-properties-list>
</template>

<script lang="ts" setup>
import { DeliveryEntityLinkDetailDto } from '@/api/portal-api/clients';

const props = defineProps<{
  detail: DeliveryEntityLinkDetailDto;
}>();
</script>
