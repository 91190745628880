import { createInstance, dateReviver } from '@/api';
import {
    OrganisationClient,
    OrganisationDetailDto,
    UpdateOrganisationDto,
    CreateOrganisationDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useOrganisationEditStore } from './organisation-edit.store';

export interface OrganisationDetailStoreState {
    detail?: OrganisationDetailDto;
    loading: boolean;
    reloading: boolean;
}

export const client = new OrganisationClient('', createInstance(), dateReviver);

export const useOrganisationDetailStore = defineStore('configuration-organisation-detail-store', {
    state: (): OrganisationDetailStoreState => ({
        detail: null,
        loading: false,
        reloading: false,
    }),
    getters: {},
    actions: {
        async get(id: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);
            try {
                this.detail = await client.getDetail(id);
                return this.detail;
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('organisations.detail.error.get.title'),
                    i18n.global.t('organisations.detail.error.get.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async create(dto: CreateOrganisationDto): Promise<number> {
            this.loading = true;
            let response = null;
            try {
                response = await client.create(dto);
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('organisations.detail.error.create.title'),
                    i18n.global.t('organisations.detail.error.create.content'),
                );
            } finally {
                useOrganisationEditStore().closeCreate();
                this.loading = false;
            }
            return response?.id;
        },
        async update(id: number, dto: UpdateOrganisationDto) {
            try {
                this.reloading = true;
                await client.update(id, dto);
                useAlertStore().setSuccess(
                    i18n.global.t('organisations.detail.success.update.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );

                await this.get(id, true);
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('organisations.detail.error.update.title'),
                    i18n.global.t('organisations.detail.error.update.content'),
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } finally {
                this.reloading = false;
                useOrganisationEditStore().closeEdit();
            }
        },
        async delete(id: number) {
            this.reloading = true;
            try {
                await client.delete(id);
                useAlertStore().setSuccess(
                    i18n.global.t('organisations.detail.success.delete.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                return true;
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('organisations.detail.error.delete.title'),
                    i18n.global.t('organisations.detail.error.delete.content'),
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } finally {
                useOrganisationEditStore().closeDelete();
                this.reloading = false;
            }
        },
        clear(id: number) {
            this.detail = null;
        },
    },
});
