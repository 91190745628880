<template>
  <vl-form-message-label v-if="label" :for="name">
    {{ label }}
  </vl-form-message-label>
  <Field v-slot="{ handleChange, value, errors }" :name="name">
    <vl-multiselect
      v-bind="$attrs"
      :id="name"
      ref="multiselect"
      :model-value="value"
      :v-model="value"
      :mod-error="!!errors.length"
      :mod-multiple="false"
      :allowEmpty="true"
      :options="options"
      type="search"
      track-by="id"
      mod-block
      @update:model-value="handleChange"
      @select="handleChange"
      @remove="handleChange(null)"
      @search-change="handleSearchChange"
    />
    <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
  </Field>
</template>

<script lang="ts" setup>
import { Field } from 'vee-validate';
import { useAttrs } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(['search-change']);

const handleSearchChange = (search: string) => {
  emit('search-change', search);
};

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  options: {
    type: Array,
    required: true,
  },
});

const attrs = useAttrs();
</script>
