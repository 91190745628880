import { VlPagerPage } from '@govflanders/vl-ui-design-system-vue3';
import { defineComponent } from 'vue';

export default defineComponent({
    extends: VlPagerPage,
    props: {
        number: {
            type: [Number, String], // in webcomponents the type is Number
            required: true,
        },
    },
    setup(props, context) {
        return {
            ...VlPagerPage.setup?.(props, context),
        };
    },
});

