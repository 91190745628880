import { EntityAttributeDetailDto } from '@/api/portal-api/clients';
import { object, string, array, number, mixed } from 'yup';
import {
    isTransformationTypeFeatureSupported,
    hasSameEntityModel,
    isEntityMappingType,
} from '@/modules/configuration/common';

export const useDeliveryTransformationValidations = (t) => {
    const validationSchema = object({
        transformationType: object().required(
            t('validations.messages.required', { _field_: t('deliverytransformation.detail.transformationType') }),
        ),
        fromEntity: object()
            .nullable()
            .when('transformationType', {
                is: (transformationType) => isTransformationTypeFeatureSupported(transformationType?.id, 'fromEntity'),
                then: (s) =>
                    s.required(
                        t('validations.messages.required', { _field_: t('deliverytransformation.detail.fromEntity') }),
                    ),
                otherwise: (s) => s.optional(),
            }),
        toEntity: object()
            .nullable()
            .when('transformationType', {
                is: (transformationType) => isTransformationTypeFeatureSupported(transformationType?.id, 'toEntity'),
                then: (s) =>
                    s.required(
                        t('validations.messages.required', { _field_: t('deliverytransformation.detail.toEntity') }),
                    ),
                otherwise: (s) => s.optional(),
            }),
        parameter: string()
            .nullable()
            .when('transformationType', {
                is: (transformationType) => isTransformationTypeFeatureSupported(transformationType?.id, 'parameter'),
                then: (s) =>
                    s.required(
                        t('validations.messages.required', { _field_: t('deliverytransformation.detail.parameter') }),
                    ),
                otherwise: (s) => s.optional(),
            }),
        attributeMappings: array()
            .nullable()
            .of(
                object().shape({
                    id: number().optional().nullable(),
                    from: object()
                        .required(
                            t('validations.messages.required', {
                                _field_: t('deliverytransformation.detail.fromAttribute'),
                            }),
                        )
                        .test(
                            'dataType-match',
                            t('validations.messages.matchPrerequisites', {
                                _field_: t('deliverytransformation.detail.attributeMapping'),
                            }),
                            checkEntityAttributesDataType({ testTo: true }),
                        ),
                    to: object()
                        .required(
                            t('validations.messages.required', {
                                _field_: t('deliverytransformation.detail.toAttribute'),
                            }),
                        )
                        .test(
                            'dataType-match',
                            t('validations.messages.matchPrerequisites', {
                                _field_: t('deliverytransformation.detail.attributeMapping'),
                            }),
                            checkEntityAttributesDataType({ testFrom: true }),
                        ),
                }),
            )
            .when('transformationType', {
                is: (transformationType) =>
                    isTransformationTypeFeatureSupported(transformationType?.id, 'attributeMappings'),
                then: (s) =>
                    s
                        .strict()
                        .required(t('deliverytransformation.detail.attributeMappingsRequired'))
                        .min(1, t('deliverytransformation.detail.attributeMappingsRequired')),
                otherwise: (s) => s.optional(),
            }),
        custom: mixed().test(
            'custom-validation',
            'The properties do not meet the validation criteria',
            function (_, context) {
                return customValidation(t, _, context);
            },
        ),
    });

    return {
        validationSchema,
    };
};

function customValidation(t: any, value: any, context: any) {
    const { fromEntity, toEntity, transformationType } = context.parent;

    if (!validateEntityMappingHasSameModel(transformationType, fromEntity, toEntity)) {
        return context.createError({
            message: t('deliverytransformation.detail.entityModelsNotEqual'),
            path: 'fromEntity',
        });
    }

    return true;
}

function validateEntityMappingHasSameModel(transformationType, fromEntity, toEntity) {
    if (fromEntity && toEntity && isEntityMappingType(transformationType)) {
        return hasSameEntityModel(fromEntity, toEntity);
    }
    return true;
}

function checkEntityAttributesDataType({ testFrom, testTo }: { testFrom?: boolean; testTo?: boolean }) {
    const testFunction = (value, ctx) => {
        if (testFrom) {
            const { from } = ctx.parent;
            const to = value as EntityAttributeDetailDto;
            return !from || !to || (from.dataType && to.dataType && from.dataType.id === to.dataType.id);
        }
        if (testTo) {
            const { to } = ctx.parent;
            const from = value as EntityAttributeDetailDto;
            return !to || !from || (to.dataType && from.dataType && to.dataType.id === from.dataType.id);
        }
        return true;
    };
    return testFunction;
}
