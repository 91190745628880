<template>
  <vl-layout>
    <PbsGoBackLink />
    <template v-if="!loading">
      <vl-grid>
        <vl-column width="7" width-xs="12">
          <vl-title tag-name="h1">
            {{ $t('entitymodel.detail.title') }} <span><PbsStatusIcon :id="detail?.statusId" /></span>
          </vl-title>
        </vl-column>
        <vl-column width="5" width-xs="12">
          <vl-action-group
            v-if="detail && !editStore.isEdit(EntityModelEditType.EntityModel, detail.id)"
            mod-align-right
            mod-space
            between
          >
            <vl-button icon="pencil" mod-icon-before mod-primary @click="store.export(id)">
              {{ $t('general.export') }}
            </vl-button>

            <vl-button
              v-if="editStore.canEdit"
              icon="pencil"
              mod-icon-before
              mod-secondary
              :mod-disabled="editStore.editing"
              @click="editStore.openEdit(EntityModelEditType.EntityModel, detail.id)"
            >
              {{ $t('general.edit') }}
            </vl-button>
            <vl-button
              v-if="editStore.canEdit && !hasChildren"
              icon="trash"
              mod-icon-before
              mod-error
              @click="editStore.openDelete(EntityModelEditType.EntityModel, id)"
              >{{ $t('general.delete') }}</vl-button
            >
          </vl-action-group>
          <vl-action-group v-else mod-align-right mod-space between>
            <vl-button icon="save" :mod-loading="reloading" mod-icon-before @click="save">{{
              $t('general.save')
            }}</vl-button>
            <vl-button
              icon="cross"
              :mod-loading="loading"
              mod-icon-before
              mod-secondary
              mod-error
              @click="editStore.closeEdit"
            >
              {{ $t('general.cancel') }}
            </vl-button>
          </vl-action-group>
        </vl-column>
      </vl-grid>
      <vl-grid
        v-if="detail"
        v-vl-spacer:bottom.medium
        :class="{ edit: editStore.isEdit(EntityModelEditType.EntityModel, detail.id) }"
      >
        <vl-column>
          <EntityModelEdit v-if="editStore.isEdit(EntityModelEditType.EntityModel, detail.id)" ref="editForm" />
          <EntityModelRead v-else />
        </vl-column>
      </vl-grid>
    </template>
    <template v-if="detail && !loading">
      <EntityAttribute :model="detail" :modelId="id" />
    </template>
    <template v-if="loading">
      <vl-region>
        <div v-vl-align:center>
          <vl-loader :message="$t('entitymodel.detail.loading')" />
        </div>
      </vl-region>
    </template>
    <vl-modal
      :id="deleteKey.toString()"
      :title="$t('entitymodel.detail.delete.title')"
      :open="editStore.isDelete(EntityModelEditType.EntityModel, deleteKey)"
    >
      <vl-action-group>
        <vl-button v-if="!hasChildren" icon="trash" mod-icon-before mod-error @click="remove(deleteKey)">
          {{ $t('general.delete') }}
        </vl-button>
        <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeDelete">
          {{ $t('general.cancel') }}
        </vl-button>
      </vl-action-group>
    </vl-modal>
  </vl-layout>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue';
import { useRoute, onBeforeRouteLeave, useRouter } from 'vue-router';
import { useEntityModelDetailStore } from '../../store/entity-model/entity-model-detail.store';
import { useEntityModelEditStore, EntityModelEditType } from '../../store/entity-model/entity-model-edit.store';
import { EntityModelDetailDto } from '@/api/portal-api/clients';
import EntityAttribute from './subcomponents/EntityAttribute.vue';
import EntityModelRead from './EntityModelRead.vue';
import EntityModelEdit from './EntityModelEdit.vue';
import { useEntityAttributeStore } from '../../store/entity-attribute/entity-attribute.store';
import PbsGoBackLink from '@/components/PbsGoBackLink.vue';
import PbsStatusIcon from '@/components/PbsStatusIcon.vue';

const store = useEntityModelDetailStore();
const editStore = useEntityModelEditStore();

const route = useRoute();
const router = useRouter();

const editForm = ref();

const detail = computed((): EntityModelDetailDto => {
  return store.detail;
});

const loading = computed((): boolean => {
  return store.loading;
});

const reloading = computed((): boolean => {
  return store.reloading;
});

const hasChildren = computed((): boolean => {
  const hasAttributes = useEntityAttributeStore().byModel(id.value).length > 0;
  return hasAttributes;
});

const id = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

onMounted(async () => {
  await store.get(id.value);
});

const save = () => {
  editForm.value.onSubmit();
};

const remove = async (id: number) => {
  const isSuccess = await store.delete(id);
  editStore.closeDelete();
  if (isSuccess) {
    router.push({ name: 'configuration', hash: '#entitymodel' });
  }
};

onBeforeRouteLeave((to, from, next) => {
  const id = parseInt(from.params.id as string, 10);
  if (id && store) {
    store.clear(id);
  }
  next();
});
</script>
<style lang="scss" scoped>
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colorsRaw.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-form-group {
  background: $vl-alt-bg;
  padding: 3rem;
}
</style>
