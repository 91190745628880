import { defineStore } from 'pinia';
import { clients, UserIdentity } from '@/api';

export type UserStoreState = {
    user: UserIdentity;
};

export type UserStoreGetters = {
    fullName: () => string;
    isAuthenticated: () => boolean;
};

export type UserStoreActions = {
    isAuthenticatedAsync: () => Promise<boolean>;
    login: (state: string) => void;
    logout: () => void;
    hasPolicyAsync: (policy: string) => Promise<boolean>;
    isInRoleAsync: (role: string) => Promise<boolean>;
    fetchUserAsync: () => Promise<void>;
    hasRolesAsync: (roles: string[]) => Promise<boolean>;
};

export const useUserStore = defineStore<string, UserStoreState, UserStoreGetters, UserStoreActions>('user-store', {
    state: (): UserStoreState => ({
        user: {},
    }),
    getters: {
        fullName(): string {
            if (!this.isAuthenticated) {
                return 'Gast';
            }
            return `${this.user.surname} ${this.user.givenname}`;
        },
        isAuthenticated(): boolean {
            return this.user.isAuthenticated || false;
        },
    },
    actions: {
        async fetchUserAsync(): Promise<void> {
            try {
                const identity = await clients.IdentityClient.current();
                this.user = identity;
            } catch {
                this.user = {};
            }
        },
        async login(state: string): Promise<void> {
            document.location.assign(`/auth/login?redirectState=${encodeURIComponent(state)}`);
            return Promise.resolve();
        },
        async logout(): Promise<void> {
            document.location.assign('/auth/logout');
            return Promise.resolve();
        },
        // TO Check if some one is othenticated, we call the the CurrentIdentity endpoint, if it returs OK with a user, with the isAuthenticated flag, we are authenticated/
        async isAuthenticatedAsync(): Promise<boolean> {
            if (!this.isAuthenticated) {
                await this.fetchUserAsync();
            }
            return this.isAuthenticated;
        },
        async hasPolicyAsync(policy: string): Promise<boolean> {
            return (await this.isAuthenticatedAsync()) && (this.user.myPolicies || []).some((p) => p === policy);
        },
        async isInRoleAsync(role: string): Promise<boolean> {
            return (await this.isAuthenticatedAsync()) && (this.user.roles || []).some((r) => r === role);
        },
        async hasRolesAsync(roles: string[]): Promise<boolean> {
            return (
                (await this.isAuthenticatedAsync()) && (this.user.roles || []).some((r) => roles.some((x) => x === r))
            );
        },
    },
});
