import { createInstance, dateReviver } from '@/api';
import { OrganisationClient, OrderDirection, OrganisationSearchResultDto } from '@/api/portal-api/clients';
import { useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { i18n } from '@/i18n';

export interface OrganisationListFilter {
    id?: number | null;
    name?: string;
    ovoNumber?: string;
    kboNumber?: string;
    order?: string;
    orderDirection?: OrderDirection;
    pageNumber?: number;
    pageSize?: number;
}

export interface OrganisationListStoreState {
    filter: OrganisationListFilter;
    loading: boolean;
    items?: OrganisationSearchResultDto[];
    totalCount?: number;
}

export const client = new OrganisationClient('', createInstance(), dateReviver);

export const useOrganisationListStore = defineStore('configuration-organisation-list-store', {
    state: (): OrganisationListStoreState => ({
        filter: {
            orderDirection: OrderDirection.Ascending,
            pageNumber: 1,
            pageSize: 25,
        },
        totalCount: 0,
        loading: false,
        items: [],
    }),
    getters: {
        metaData(): {
            totalRows: number;
            resultsPerPage: number;
            currentPage: number | undefined;
            serverside: boolean;
        } {
            return {
                totalRows: this.totalCount,
                resultsPerPage: this.filter.pageSize,
                currentPage: this.filter.pageNumber,
                serverside: true,
            };
        },
        getOrganisation(): (id: number) => OrganisationSearchResultDto | undefined {
            return (id: number) => this.items?.find((item) => item.id === id);
        },
    },
    actions: {
        init(filter: OrganisationListFilter) {
            this.filter = { ...this.filter, ...filter };
            this.items = [];
        },
        clearFilter() {
            this.filter = {
                orderDirection: OrderDirection.Ascending,
                pageNumber: 1,
                pageSize: 25,
            };

            useAlertStore().clearAlert();

            this.items = [];
        },
        resetPager() {
            this.filter.pageNumber = 1;
        },
        updateItem(item: OrganisationSearchResultDto) {
            const index = this.items?.findIndex((i) => i.id === item.id);
            if (index !== undefined && index !== -1) {
                this.items?.splice(index, 1, item);
            }
        },
        async getOrganisations() {
            this.loading = true;
            useAlertStore().clearAlert();

            const order = mapToQueryableOrder(this.filter.order);

            try {
                const response = await client.search(
                    this.filter.id,
                    this.filter.name,
                    this.filter.kboNumber,
                    this.filter.ovoNumber,
                    order,
                    this.filter.orderDirection,
                    this.filter.pageNumber,
                    this.filter.pageSize,
                );

                this.items = response.items;
                this.filter.pageNumber = response.page;
                this.filter.pageSize = response.pageSize;
                this.totalCount = response.totalCount;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('organisations.list.error.title'),
                    i18n.global.t('organisations.list.error.content'),
                );
            } finally {
                this.loading = false;
            }
        },
        updatePage(pageNumber: number) {
            this.filter.pageNumber = pageNumber;
        },
        updateSorting(column: string, order: string) {
            this.filter.order = column;
            this.filter.orderDirection = order === 'Asc' ? OrderDirection.Ascending : OrderDirection.Descending;
        },
    },
});

function mapToQueryableOrder(order: string) {
    switch (order) {
        case 'id':
            return 'organisationId';
        default:
            return order;
    }
}
