<template>
  <router-link v-if="link" :to="link" class="vl-link">
    <vl-icon icon="arrow-left-fat" />
    <span>{{ data.label }}</span>
  </router-link>
  <a v-else-if="data.canGoBack" class="vl-link router-link-active" @click="goBack">
    <vl-icon icon="arrow-left-fat" />
    <span>{{ data.label }}</span>
  </a>
</template>

<script lang="ts" setup>
import { reactive, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const defaultLabel = t('general.goBack');

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  link: {
    type: String,
    required: false,
    default: '',
  },
});

const data = reactive({
  canGoBack: false,
  label: props.label ? props.label : defaultLabel,
});

const goBack = () => {
  window.history.back();
};

onMounted(() => {
  data.canGoBack = window.history.length > 1 && window.history.state?.back;
});
</script>

<style scoped lang="scss"></style>
