<template>
  <vl-layout>
    <vl-grid v-vl-spacer:bottom.small>
      <template v-for="(domain, i) in data.configurations" :key="i">
        <vl-column width="4">
          <vl-doormat
            v-vl-spacer:bottom.small
            :title="domain.title"
            style="cursor: pointer"
            @click="goTo(domain.route)"
          >
            <vl-typography>
              <vl-column>
                <span class="content">{{ domain.content }}</span>
              </vl-column>
              <PbsIcon :icon="domain.icon" />
            </vl-typography>
          </vl-doormat>
        </vl-column>
      </template>
    </vl-grid>
  </vl-layout>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue';
import { reactive } from 'vue';
import { useUserStore } from '@/store/user.store';
import { IDomainConfiguration, getDomainConfigurations } from '@/core/security/domain.config.ts';
import { useRouter } from 'vue-router';
import PbsIcon from '@/components/icons/PbsIcon.vue';

const { hasRolesAsync } = useUserStore();
const router = useRouter();
const data = reactive({
  configurations: [] as IDomainConfiguration[],
});

const goTo = (route: string) => {
  router.push(route);
};

onMounted(async () => {
  const domainConfigurations = getDomainConfigurations();
  for (const domain of domainConfigurations) {
    if (await hasRolesAsync(domain.roles)) {
      data.configurations.push(domain);
    }
  }
});
</script>
<style lang="scss" scoped>
.content {
  font-size: 1.5rem;
}
</style>
