import { NavigationGuard, NavigationGuardNext, RouteLocation } from 'vue-router';

export interface MetadataPublic {
    isPublic?: boolean;
}

export type AuthenticatedType = {
    isAuthenticatedAsync: () => Promise<boolean>;
};

export function createAuthenticationGuard(authService: () => AuthenticatedType, autoRedirect = false): NavigationGuard {
    return async function (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): Promise<void> {
        // Gets metadata from the route definition to determine if it is a public route.
        const { isPublic } = to.meta as MetadataPublic;
        if (!isPublic) {
            const authenticated = await authService().isAuthenticatedAsync();
            if (!authenticated) {
                next({
                    name: 'guest',
                    query: { redirectUri: to.fullPath, autoRedirect: `${autoRedirect}` },
                });
                return;
            }
        }
        next();
        return;
    };
}
