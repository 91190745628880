import { defineStore } from 'pinia';

export interface PageStoreState {
    isLoading: boolean;
}

export const usePageStore = defineStore('page-store', {
    state: (): PageStoreState => ({
        isLoading: false,
    }),
    getters: {},
    actions: {
        async setIsLoading(loading: boolean) {
            this.isLoading = loading;
        },
    },
});
