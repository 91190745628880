<template>
  <div class="loader"></div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.loader {
  height: 2px;
  background: linear-gradient(#05c 0 0), linear-gradient(#05c 0 0), #e4e4ed;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: p6 3s infinite;
  position: sticky;
  z-index: 100000;
  top: 42px;
}

@keyframes p6 {
  0% {
    background-position:
      -150% 0,
      -150% 0;
  }
  66% {
    background-position:
      250% 0,
      -150% 0;
  }
  100% {
    background-position:
      250% 0,
      250% 0;
  }
}
</style>
