import {
    DeliveryEntityDetailDto,
    OperationEntityDetailDto,
    ProductTransformationLookupLinkDetailDto,
    SourceEntityDetailDto,
    TransformationTypeDto,
} from '@/api/portal-api/clients';
import { Dictionary } from '@/types';

export const isEntityMappingType = (transformationType?: TransformationTypeDto): boolean => {
    return transformationType && transformationType.id === 1;
};

export const isLookupLinkType = (transformationType?: TransformationTypeDto): boolean => {
    return transformationType && transformationType.id === 3;
};

export function hasSameEntityModel(fromEntity: DeliveryEntityDetailDto, toEntity: OperationEntityDetailDto) {
    return fromEntity?.model?.id === toEntity?.model?.id;
}

export function getLookupOptions(
    fromEntity: SourceEntityDetailDto,
    links: ProductTransformationLookupLinkDetailDto[],
): ProductTransformationLookupLinkDetailDto[] {
    if (!fromEntity) return links;
    const attributes = fromEntity?.model?.attributes;
    const options = links?.filter((x) => attributes?.some((a) => a.code === x.keyAttribute.code));

    return options;
}

export const isTransformationTypeFeatureSupported = (
    transformationTypeId: number,
    feature: keyof TransformationTypeFeature,
) => {
    if (!transformationTypeId) {
        return false;
    }

    return TRANSFORMATION_TYPE_FEATURES[transformationTypeId]?.[feature];
};

export type TransformationTypeFeature = {
    fromEntity: boolean;
    toEntity: boolean;
    attributeMappings: boolean;
    parameter: boolean;
};

function createTransformationTypeFeatures(): Dictionary<TransformationTypeFeature> {
    const attributeMappingTypeFeature: TransformationTypeFeature = {
        fromEntity: true,
        toEntity: true,
        attributeMappings: true,
        parameter: false,
    };

    const entityMappingTypeFeature: TransformationTypeFeature = {
        fromEntity: true,
        toEntity: true,
        attributeMappings: false,
        parameter: false,
    };

    const attributeLookupTypeFeature: TransformationTypeFeature = {
        fromEntity: true,
        toEntity: true,
        attributeMappings: true,
        parameter: false,
    };

    const geometryToAddressTypeFeature: TransformationTypeFeature = {
        fromEntity: true,
        toEntity: true,
        attributeMappings: false,
        parameter: true,
    };

    const addressToAddressTypeFeature: TransformationTypeFeature = {
        fromEntity: true,
        toEntity: true,
        attributeMappings: false,
        parameter: true,
    };

    const addressToGeometryTypeFeature: TransformationTypeFeature = {
        fromEntity: true,
        toEntity: true,
        attributeMappings: false,
        parameter: true,
    };

    const preProcessingTypeFeature: TransformationTypeFeature = {
        fromEntity: false,
        toEntity: false,
        attributeMappings: false,
        parameter: true,
    };

    const topologyTypeFeature: TransformationTypeFeature = {
        fromEntity: true,
        toEntity: true,
        attributeMappings: false,
        parameter: false,
    };

    const postProcessingTypeFeature: TransformationTypeFeature = {
        fromEntity: false,
        toEntity: false,
        attributeMappings: false,
        parameter: true,
    };

    const sqlExecutionTypeFeature: TransformationTypeFeature = {
        fromEntity: false,
        toEntity: false,
        attributeMappings: false,
        parameter: true,
    };

    return {
        1: entityMappingTypeFeature,
        2: attributeMappingTypeFeature,
        3: attributeLookupTypeFeature,
        4: geometryToAddressTypeFeature,
        5: addressToAddressTypeFeature,
        6: addressToGeometryTypeFeature,
        7: preProcessingTypeFeature,
        8: topologyTypeFeature,
        9: postProcessingTypeFeature,
        10: sqlExecutionTypeFeature,
    };
}

const TRANSFORMATION_TYPE_FEATURES = createTransformationTypeFeatures();
