import { useAlertStore } from '@/modules/core/store/alert.store';
import { NavigationGuard, NavigationGuardNext, RouteLocation } from 'vue-router';

export function alertGuard(): NavigationGuard {
    return async function (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): Promise<void> {
        useAlertStore().clearAlert();
        next();
        return;
    };
}
