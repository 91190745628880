<template>
  <form>
    <vl-properties-list v-vl-spacer:bottom.small>
      <vl-properties-label>{{ $t('entityAttribute.detail.id') }}</vl-properties-label>
      <vl-properties-data>{{ props.detail.id }}</vl-properties-data>
    </vl-properties-list>

    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="code"
        :label="t('entityAttribute.detail.code')"
        :placeholder="t('entityAttribute.detail.code')"
      />
      <vl-alert
        v-if="showWarningForCode"
        v-vl-spacer:top.small
        icon="warning"
        :content="t('entityAttribute.detail.codeLength')"
        mod-warning
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="label"
        :label="t('entityAttribute.detail.label')"
        :placeholder="t('entityAttribute.detail.label')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('entityAttribute.detail.description')"
        :placeholder="t('entityAttribute.detail.description')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="order"
        :label="t('entityAttribute.detail.order')"
        :placeholder="t('entityAttribute.detail.order')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="dataType"
        :label="t('entityAttribute.detail.dataType')"
        :placeholder="$t('entityAttribute.detail.dataType')"
        :options="dataTypes"
        :custom-label="enumerationLabel"
      />
    </vl-form-column>
    <vl-form-column v-if="isTextOrNumber" v-vl-spacer:bottom.small>
      <PbsInputField
        name="length"
        :label="t('entityAttribute.detail.length')"
        :placeholder="t('entityAttribute.detail.length')"
      />
    </vl-form-column>
    <vl-form-column v-if="isNumber" v-vl-spacer:bottom.small>
      <PbsInputField
        name="decimalCount"
        :label="t('entityAttribute.detail.decimalCount')"
        :placeholder="t('entityAttribute.detail.decimalCount')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="isIdentifying" :label="$t('entityAttribute.detail.isIdentifying')" />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="isMandatory" :label="$t('entityAttribute.detail.isMandatory')" />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="isCalculated" :label="$t('entityAttribute.detail.isCalculated')" />
    </vl-form-column>
  </form>
</template>

<script lang="ts" setup>
import { EntityAttributeDetailDto, EntityModelDetailDto, EnumerationDto } from '@/api/portal-api/clients';
import { ENUM_DataTypes } from '@/modules/configuration/common';
import { useEntityAttributeStore } from '@/modules/configuration/store/entity-attribute/entity-attribute.store';
import { useForm } from 'vee-validate';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { boolean, mixed, number, object, string } from 'yup';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';

const store = useEntityAttributeStore();

const { t } = useI18n();
const route = useRoute();

const props = defineProps<{
  detail: EntityAttributeDetailDto;
  model: EntityModelDetailDto;
}>();

const dataTypes = computed((): EnumerationDto[] => {
  return ENUM_DataTypes;
});

const validationSchema = object({
  code: string()
    .required(t('validations.messages.required', { _field_: t('entityAttribute.detail.code') }))
    .max(64, t('validations.messages.maxChar', { _field_: t('entityAttribute.detail.code'), length: 64 })),
  label: string()
    .required(t('validations.messages.required', { _field_: t('entityAttribute.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('entityAttribute.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('entityAttribute.detail.description') }),
  ),
  order: number()
    .required(t('validations.messages.required', { _field_: t('entityAttribute.detail.order') }))
    .integer(t('validations.messages.mustBeInteger', { _field_: t('entityAttribute.detail.order') }))
    .min(0, t('validations.messages.minValue', { _field_: t('entityAttribute.detail.order'), min: 0 }))
    .typeError(t('validations.messages.mustBeNumber', { _field_: t('entityAttribute.detail.order') })),
  dataType: object().required(t('validations.messages.required', { _field_: t('entityAttribute.detail.dataType') })),
  length: number().when('dataType', {
    is: () => isTextOrNumber.value,
    then: (s) =>
      s
        .required(t('validations.messages.required', { _field_: t('entityAttribute.detail.length') }))
        .integer(t('validations.messages.mustBeInteger', { _field_: t('entityAttribute.detail.length') }))
        .min(1, t('validations.messages.minValue', { _field_: t('entityAttribute.detail.length'), min: 1 }))
        .typeError(t('validations.messages.mustBeNumber', { _field_: t('entityAttribute.detail.length') })),
    otherwise: (s) => s.optional(),
  }),
  decimalCount: number().when('dataType', {
    is: () => isNumber.value,
    then: (s) =>
      s
        .integer(t('validations.messages.mustBeInteger', { _field_: t('entityAttribute.detail.decimalCount') }))
        .min(0, t('validations.messages.minValue', { _field_: t('entityAttribute.detail.decimalCount'), min: 0 }))
        .typeError(t('validations.messages.mustBeNumber', { _field_: t('entityAttribute.detail.decimalCount') })),
    otherwise: (s) => s.optional(),
  }),
  isIdentifying: boolean(),
  isMandatory: boolean(),
  isCalculated: boolean(),
  custom: mixed().test(
    'custom-validation',
    'The properties do not meet the validation criteria',
    function (_, context) {
      const { decimalCount, length } = context.parent;
      if (decimalCount >= length) {
        return context.createError({
          path: 'decimalCount',
          message: t('validations.messages.minValueAndMaxLength', {
            _field_: t('entityAttribute.detail.decimalCount'),
            length,
            min: 0,
          }),
        });
      }
      return true;
    },
  ),
});

const { resetForm, handleSubmit, values } = useForm({
  validationSchema,
});

const dataType = computed((): EnumerationDto => {
  return values.dataType;
});

const modelId = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

const isTextOrNumber = computed((): boolean => {
  return dataType.value && ['Tekst', 'Getal'].some((x) => x === dataType.value.name);
});

const isNumber = computed((): boolean => {
  return dataType.value && ['Getal'].some((x) => x === dataType.value.name);
});

const showWarningForCode = computed((): boolean => {
  if (!props.model || props.model.contextType?.name !== 'Product') {
    return false;
  }

  const val = values.code?.length;
  return val > 10 && val <= 64;
});

onMounted(() => {
  resetForm({ values: props.detail });
});

const onSuccess = async (values) => {
  await store.update(modelId.value, props.detail.id, {
    code: values.code,
    label: values.label,
    description: values.description,
    order: values.order,
    dataType: values.dataType.name,
    length: isTextOrNumber.value ? values.length : 0,
    decimalCount: isNumber.value ? values.decimalCount : null,
    isIdentifying: values.isIdentifying,
    isMandatory: values.isMandatory,
    isCalculated: values.isCalculated,
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const enumerationLabel = (enumerationDto: EnumerationDto) => {
  return `${enumerationDto.name}`;
};

defineExpose({ onSubmit });
</script>
