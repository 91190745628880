import '@/web-components/web-core.scss';
import 'reflect-metadata';
import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';
import App from './App.vue';
import pinia from './store';
import router from './router';
import i18n from './i18n';
import VlUiVueComponents from '@govflanders/vl-ui-design-system-vue3';
import { loadEnvironmentConfig } from '@/common/config/config';
import PbsDirectives from './directives';
import DataTablePlugin from './components/vl-data-table-extended';

const app = createApp(App);

app.use(createMetaManager());
app.use(i18n);
app.use(router);
app.use(pinia);
app.use(VlUiVueComponents);
app.use(PbsDirectives);
app.use(DataTablePlugin);

loadEnvironmentConfig().then(() => {
    app.mount('#app');
});

export default app;
