<template>
  <vl-layout>
    <template v-if="loading">
      <vl-region>
        <div v-vl-align:center>
          <vl-loader :message="$t('systemtable.loading')" />
        </div>
      </vl-region>
    </template>
    <template v-else>
      <vl-grid>
        <vl-column v-if="contextTypeItems.length" width="12" width-xs="12">
          <ContextTypeTable :items="contextTypeItems" />
        </vl-column>
        <vl-column v-if="dataConnectionItems.length" width="12" width-xs="12">
          <DataConnectionTable :items="dataConnectionItems" />
        </vl-column>
        <vl-column v-if="dataConnectionItems.length" width="12" width-xs="12">
          <DataTypeTable :items="dataTypeItems" />
        </vl-column>
        <vl-column v-if="entityTypeItems.length" width="12" width-xs="12">
          <EntityTypeTable :items="entityTypeItems" />
        </vl-column>
        <vl-column v-if="formatTypeItems.length" width="12" width-xs="12">
          <FormatTypeTable :items="formatTypeItems" />
        </vl-column>
        <vl-column v-if="sridTypesItems.length" width="12" width-xs="12">
          <SridTypeTable :items="sridTypesItems" />
        </vl-column>
        <vl-column v-if="transformationTypeItems.length" width="12" width-xs="12">
          <TransformationTypeTable :items="transformationTypeItems" />
        </vl-column>
        <vl-column v-if="triggerTypeItems.length" width="12" width-xs="12">
          <TriggerTypeTable :items="triggerTypeItems" />
        </vl-column>
      </vl-grid>
    </template>
  </vl-layout>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useSystemTableListStore } from '../../store/systemtable/systemtable-list.store';
import { onMounted } from 'vue';
import {
  ContextTypeDto,
  CrsDto,
  DataConnectionDto,
  DataTypeDto,
  EntityTypeDto,
  FormatTypeDto,
  TransformationTypeDto,
  TriggerTypeDto,
} from '@/api/portal-api/clients';
import ContextTypeTable from './subcomponents/ContextTypeTable.vue';
import DataConnectionTable from './subcomponents/DataConnectionTable.vue';
import DataTypeTable from './subcomponents/DataTypeTable.vue';
import EntityTypeTable from './subcomponents/EntityTypeTable.vue';
import FormatTypeTable from './subcomponents/FormatTypeTable.vue';
import SridTypeTable from './subcomponents/SridTypeTable.vue';
import TransformationTypeTable from './subcomponents/TransformationTypeTable.vue';
import TriggerTypeTable from './subcomponents/TriggerTypeTable.vue';

const store = useSystemTableListStore();

const loading = computed((): boolean => {
  return store.loading;
});

const contextTypeItems = computed((): ContextTypeDto[] => {
  return store.systemtables?.contextTypes || [];
});

const dataConnectionItems = computed((): DataConnectionDto[] => {
  return store.systemtables?.dataConnections || [];
});

const dataTypeItems = computed((): DataTypeDto[] => {
  return store.systemtables?.dataTypes || [];
});

const entityTypeItems = computed((): EntityTypeDto[] => {
  return store.systemtables?.entityTypes || [];
});

const formatTypeItems = computed((): FormatTypeDto[] => {
  return store.systemtables?.formatTypes || [];
});

const sridTypesItems = computed((): CrsDto[] => {
  return store.systemtables?.sridTypes || [];
});

const transformationTypeItems = computed((): TransformationTypeDto[] => {
  return store.systemtables?.transformationTypes || [];
});

const triggerTypeItems = computed((): TriggerTypeDto[] => {
  return store.systemtables?.triggerTypes || [];
});

onMounted(() => {
  search();
});

const search = async () => {
  await store.getSystemTables();
};
</script>
<style lang="scss" scoped></style>
