<template>
  <div class="login">
    <vl-region mod-stacked-large>
      <vl-layout>
        <vl-grid mod-stacked>
          <vl-column width="7" width-m="9" width-s="12">
            <vl-title tag-name="h1">{{ $t('login.title') }}</vl-title>
          </vl-column>
          <vl-column>
            <p>{{ $t('login.loginRequiredText') }}</p>
          </vl-column>
          <vl-column>
            <vl-button @click="login">{{ $t('login.login.label') }}</vl-button>
          </vl-column>
        </vl-grid>
      </vl-layout>
    </vl-region>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  mounted() {
    if (this.$route.query.autoRedirect === `${true}`) {
      this.login();
    }
  },
  methods: {
    login() {
      this.$router.push({ name: 'login', query: { redirectUri: this.$route.query.redirectUri } });
    },
  },
});
</script>
