<template>
  <div class="radio-button-field">
    <vl-form-message-label v-if="label" :for="name">
      {{ label }}
    </vl-form-message-label>
    <Field v-slot="{ handleChange, value, errors }" :name="name">
      <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
      <vl-radio
        :id="yesId"
        :name="yesId"
        :modelValue="value"
        :value="true"
        :mod-error="!!errors.length"
        mod-block
        @input="handleChange"
      >
        {{ $t('general.yes') }}
      </vl-radio>
      <vl-radio
        :id="noId"
        :name="noId"
        :modelValue="value"
        :value="false"
        :mod-error="!!errors.length"
        mod-block
        @input="handleChange"
      >
        {{ $t('general.no') }}
      </vl-radio>
    </Field>
  </div>
</template>

<script lang="ts" setup>
import { Field } from 'vee-validate';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
});

const yesId = computed(() => `${props.name}_0`);
const noId = computed(() => `${props.name}_1`);
</script>

<style scoped lang="scss">
.radio-button-field {
  display: inline-block;
}
</style>
