<template>
  <vl-grid>
    <vl-column v-if="data && data.length && !modHidePagination && modPaginationOnTop">
      <vl-data-table-extended-pager
        :can-next-page="table.getCanNextPage()"
        :can-previous-page="table.getCanPreviousPage()"
        :current-page-index="table.getState().pagination.pageIndex"
        :page-size="table.getState().pagination.pageSize"
        :total-rows="totalItems"
        :visible-rows="table.getPaginationRowModel().rows.length"
        @pager-clicked="goPage"
      />
    </vl-column>
    <vl-column v-if="data && data.length">
      <vl-data-table v-bind="$attrs" :mod-sticky="modSticky">
        <thead v-if="!modHideHeader">
          <tr v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
            <!--            <th v-if="table.options.enableRowSelection || table.options.enableMultiRowSelection">-->
            <!--              <vl-checkbox v-if="table.options.enableMultiRowSelection" :checked="table.getIsAllRowsSelected()" />-->
            <!--            </th>-->
            <th
              v-for="header in headerGroup.headers"
              :key="header.id"
              :colSpan="header.colSpan"
              :class="headerClasses()"
            >
              <!-- :style="{ width: `${header.getSize()}%` }"-->
              <template v-if="header.column.getCanSort()">
                <vl-button
                  :icon="sortIcon(header.column)"
                  a11y-label="sort column"
                  mod-icon-after
                  mod-naked-action
                  mod-narrow
                  @click="header.column.getToggleSortingHandler()?.($event)"
                >
                  <FlexRender
                    v-if="!header.isPlaceholder"
                    :props="header.getContext()"
                    :render="header.column.columnDef.header"
                  />
                </vl-button>
              </template>
              <FlexRender
                v-else-if="!header.isPlaceholder"
                :props="header.getContext()"
                :render="header.column.columnDef.header"
              />
            </th>
          </tr>
        </thead>
        <tbody v-if="!fetching && data && data.length > 0">
          <template v-for="row in table.getRowModel().rows" :key="row.id">
            <tr :class="rowClasses(row)">
              <!--              <td v-if="table.options.enableRowSelection || table.options.enableMultiRowSelection">-->
              <!--                <vl-checkbox :checked="row.original.checked" @click="selectRow(row)" />-->
              <!--              </td>-->
              <td
                v-for="cell in row.getVisibleCells()"
                :key="cell.id"
                :style="{
                  width: `${cell.column.columnDef.size}%`,
                }"
                v-bind="bindCellContext(cell)"
                @click="modClickableRows && cellClicked(cell)"
              >
                <router-link
                  v-if="isColumnLink(cell.column.columnDef) && row.original.routerLink"
                  :to="row.original.routerLink"
                >
                  <FlexRender :props="cell.getContext()" :render="cell.column.columnDef.cell" />
                </router-link>
                <FlexRender v-else :props="cell.getContext()" :render="cell.column.columnDef.cell" />
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else-if="fetching">
          <tr>
            <td :colspan="table.getVisibleFlatColumns().length">
              <div class="vl-u-align-center">
                <vl-loader />
              </div>
            </td>
          </tr>
          <tr v-for="i in DEFAULT_PAGE_SIZE - 1" :key="i">
            <td :colspan="table.getVisibleFlatColumns().length" />
          </tr>
        </tbody>
      </vl-data-table>
    </vl-column>
    <vl-column v-if="!modHidePagination && data && data.length" class="vl-u-spacer-top--small">
      <vl-action-group mod-space-between style="flex-direction: row-reverse">
        <vl-data-table-extended-pager
          class="vl-u-flex-align-right"
          :can-next-page="table.getCanNextPage()"
          :can-previous-page="table.getCanPreviousPage()"
          :current-page-index="currentPageIndex"
          :page-size="table.getState().pagination.pageSize"
          :total-rows="totalItems"
          :visible-rows="table.getPaginationRowModel().rows.length"
          @pager-clicked="goPage"
        />
        <slot name="actions" />
      </vl-action-group>
    </vl-column>
    <vl-column v-if="hasNoResultsSlot && data && data.length === 0">
      <slot name="noresults" />
    </vl-column>
  </vl-grid>
</template>
<script lang="ts" src="./vl-data-table-extended.ts"></script>
<style lang="scss" scoped>
.vl-data-table {
  .vl-data-table__element--processing {
    color: #687483;
    background: #e2e6e9 !important;

    .vl-loader {
      opacity: 0.4;
    }
  }
  tr {
    th {
      line-height: 2.3;

      .vl-button {
        padding: 0;
      }
    }

    td {
      a {
        height: 100%;
        width: 100%;
        text-decoration: none;
        color: #333332;
      }
    }
  }
}
</style>
