<template>
  <form>
    <vl-properties-list v-vl-spacer:bottom.small>
      <vl-properties-label>{{ $t('sourcespecification.detail.id') }}</vl-properties-label>
      <vl-properties-data>{{ props.detail.id }}</vl-properties-data>
    </vl-properties-list>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="label"
        :label="t('sourcespecification.detail.label')"
        :placeholder="t('sourcespecification.detail.label')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('sourcespecification.detail.description')"
        :placeholder="t('sourcespecification.detail.description')"
      />
    </vl-form-column>
  </form>
</template>

<script lang="ts" setup>
import { SourceSpecificationDetailDto } from '@/api/portal-api/clients';
import { useSourceSpecificationStore } from '@/modules/configuration/store/source-specification/source-specification.store';
import { useForm } from 'vee-validate';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { object, string } from 'yup';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';

const store = useSourceSpecificationStore();

const { t } = useI18n();
const route = useRoute();

const props = defineProps<{
  detail: SourceSpecificationDetailDto;
}>();

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('deliveryspecification.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('deliveryspecification.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('deliveryspecification.detail.description') }),
  ),
});

const { resetForm, handleSubmit } = useForm({
  validationSchema,
});

const definitionId = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

onMounted(() => {
  resetForm({ values: props.detail });
});

const onSuccess = async (values) => {
  await store.update(definitionId.value, props.detail.id, {
    ...values,
    productProcessDefinitionId: definitionId.value,
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
