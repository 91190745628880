import { createInstance, dateReviver } from '@/api';
import { EntityModelClient, SearchEntityModelDto } from '@/api/portal-api/clients';
import { useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { i18n } from '@/i18n';

export interface ModelListStoreState {
    loading: boolean;
    items?: SearchEntityModelDto[];
}

export const client = new EntityModelClient('', createInstance(), dateReviver);

export const useEntityModelSelectStore = defineStore('configuration-entity-model-select-store', {
    state: (): ModelListStoreState => ({
        loading: false,
        items: [],
    }),
    getters: {
        getByContextType(): (contextTypeName: string) => SearchEntityModelDto[] {
            return (contextTypeName: string) => this.items?.filter((item) => item.contextTypeName === contextTypeName);
        },
    },
    actions: {
        clearItems() {
            this.items = [];
        },
        async getModels({ force = false, contextTypeName = '' } = {}) {
            if (this.items.length && !force) {
                return;
            }

            this.loading = true;
            useAlertStore().clearAlert();
            this.clearItems();

            try {
                const response = await client.searchAll(contextTypeName);
                this.items = response;
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entitymodel.select.error.title'),
                    i18n.global.t('entitymodel.select.error.content'),
                );
            } finally {
                this.loading = false;
            }
        },
    },
});
