import { createInstance, dateReviver } from '@/api';
import {
    ProductTransformationLookupLinkClient,
    ProductTransformationLookupLinkDetailDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, useAlertStore, VerticalPosition } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';

export interface ProductTransformationLookupLinkStoreState {
    items?: { key: string; value: ProductTransformationLookupLinkDetailDto[] }[];
    loading: boolean;
    reloading: boolean;
}

export const client = new ProductTransformationLookupLinkClient('', createInstance(), dateReviver);

export const useProductTransformationLookupLinkStore = defineStore('configuration-product-transformation-lookup-link-store', {
    state: (): ProductTransformationLookupLinkStoreState => ({
        items: [],
        loading: false,
        reloading: false,
    }),
    getters: {
        byDefinition: (state) => {
            return (definitionId: number): ProductTransformationLookupLinkDetailDto[] => {
                const key = `definition-${definitionId}`;

                return state.items.find((x) => x.key === key)?.value ?? [];
            };
        },
        bySourceEntity: (state) => {
            return (sourceEntityId: number): ProductTransformationLookupLinkDetailDto[] => {
                const key = `sourceentity-${sourceEntityId}`;

                return state.items.find((x) => x.key === key)?.value ?? [];
            };
        },
    },
    actions: {
        async getByDefinitionId(id: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);

            const definitionKey = `definition-${id}`;

            try {
                const response = await client.getByDefinition(id);

                this.items = this.items.filter((x) => x.key !== definitionKey);

                if (response.length) {
                    this.items = [...this.items, { key: definitionKey, value: response }];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productTransformationLookupLink.detail.error.title'),
                    i18n.global.t('productTransformationLookupLink.detail.error.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async getBySourceEntity(id: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);

            const key = `sourceentity-${id}`;

            try {
                const response = await client.getBySourceEntity(id);

                this.items = this.items.filter((x) => x.key !== key);

                if (response.length) {
                    this.items = [...this.items, { key, value: response }];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productTransformationLookupLink.detail.error.title'),
                    i18n.global.t('productTransformationLookupLink.detail.error.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async create(sourceEntityId: number, keyAttributeId: number, valueAttributeId: number) {
            this.reloading = true;

            try {
                await client.create({
                    sourceEntityId,
                    keyAttributeId,
                    valueAttributeId,
                });

                await this.getBySourceEntity(sourceEntityId, true);
                useAlertStore().setSuccess(
                    i18n.global.t('productTransformationLookupLink.create.success.title'),
                    null,
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productTransformationLookupLink.create.error.title'),
                    i18n.global.t('productTransformationLookupLink.create.error.content'),
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } finally {
                this.reloading = false;
            }
        },
        async update(sourceEntityId: number, linkId: number, keyAttributeId: number, valueAttributeId: number) {
            this.reloading = true;

            try {
                await client.update(linkId, {
                    sourceEntityId,
                    keyAttributeId,
                    valueAttributeId,
                });
                await this.getBySourceEntity(sourceEntityId, true);

                useAlertStore().setSuccess(
                    i18n.global.t('productTransformationLookupLink.update.success.title'),
                    null,
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productTransformationLookupLink.update.error.title'),
                    i18n.global.t('productTransformationLookupLink.update.error.content'),
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } finally {
                this.reloading = false;
            }
        },
        async remove(sourceEntityId: number, linkId: number) {
            this.reloading = true;

            try {
                await client.remove(linkId);

                await this.getBySourceEntity(sourceEntityId, true);

                useAlertStore().setSuccess(
                    i18n.global.t('productTransformationLookupLink.delete.success.title'),
                    null,
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productTransformationLookupLink.delete.error.title'),
                    i18n.global.t('productTransformationLookupLink.delete.error.content'),
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } finally {
                this.reloading = false;
            }
        },
        clear(id: number) {
            const definitionKey = `definition-${id}`;

            this.items = this.items.filter((x) => x.key !== definitionKey);
        },
    },
});
