import { createInstance, dateReviver } from '@/api';
import { EntityModelClient, OrderDirection, SearchEntityModelDto } from '@/api/portal-api/clients';
import { useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { i18n } from '@/i18n';

export interface ModelListFilter {
    id?: number | null;
    code?: string;
    label?: string;
    description?: string;
    contextType?: string;
    entityType?: string;
    hasHistory?: boolean | undefined;
    statusType?: string;
    order?: string;
    orderDirection?: OrderDirection;
    pageNumber?: number;
    pageSize?: number;
}

export interface ModelListStoreState {
    filter: ModelListFilter;
    loading: boolean;
    items?: SearchEntityModelDto[];
    totalCount?: number;
}

export const client = new EntityModelClient('', createInstance(), dateReviver);

export const useEntityModelListStore = defineStore('configuration-entity-model-list-store', {
    state: (): ModelListStoreState => ({
        filter: {
            orderDirection: OrderDirection.Ascending,
            pageNumber: 1,
            pageSize: 25,
        },
        totalCount: 0,
        loading: false,
        items: [],
    }),
    getters: {
        metaData(): {
            totalRows: number;
            resultsPerPage: number;
            currentPage: number | undefined;
            serverside: boolean;
        } {
            return {
                totalRows: this.totalCount,
                resultsPerPage: this.filter.pageSize,
                currentPage: this.filter.pageNumber,
                serverside: true,
            };
        },
        getEntityModel(): (id: number) => SearchEntityModelDto | undefined {
            return (id: number) => this.items?.find((item) => item.id === id);
        },
    },
    actions: {
        init(filter: ModelListFilter) {
            this.filter = { ...this.filter, ...filter };
            this.items = [];
        },
        clearFilter() {
            this.filter = {
                orderDirection: OrderDirection.Ascending,
                pageNumber: 1,
                pageSize: 25,
            };

            useAlertStore().clearAlert();

            this.items = [];
        },
        resetPager() {
            this.filter.pageNumber = 1;
        },
        updateItem(item: SearchEntityModelDto) {
            const index = this.items?.findIndex((i) => i.id === item.id);
            if (index !== undefined && index !== -1) {
                this.items?.splice(index, 1, item);
            }
        },
        async getModels() {
            this.loading = true;
            useAlertStore().clearAlert();

            const order = mapToQueryableOrder(this.filter.order);

            try {
                const response = await client.search(
                    this.filter.id,
                    this.filter.code,
                    this.filter.label,
                    this.filter.description,
                    this.filter.contextType,
                    this.filter.entityType,
                    this.filter.hasHistory,
                    this.filter.statusType,
                    order,
                    this.filter.orderDirection,
                    this.filter.pageNumber,
                    this.filter.pageSize,
                );

                this.items = response.items;
                this.filter.pageNumber = response.page;
                this.filter.pageSize = response.pageSize;
                this.totalCount = response.totalCount;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('entitymodel.list.error.title'),
                    i18n.global.t('entitymodel.list.error.content'),
                );
            } finally {
                this.loading = false;
            }
        },
        updatePage(pageNumber: number) {
            this.filter.pageNumber = pageNumber;
        },
        updateSorting(column: string, order: string) {
            this.filter.order = column;
            this.filter.orderDirection = order === 'Asc' ? OrderDirection.Ascending : OrderDirection.Descending;
        },
    },
});

function mapToQueryableOrder(order: string) {
    switch (order) {
        case 'contextTypeName':
            return 'contextType';
        case 'entityTypeName':
            return 'entityType';
        case 'statusId':
            return 'status';
        default:
            return order;
    }
}
