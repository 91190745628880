import { isDate, isValid, parse } from 'date-fns';

export function parseFormattedDateString(value, format = 'dd.MM.yyyy') {
    if (!value) {
        return null;
    }
    const parsedDate = isDate(value) ? value : parse(value, format, new Date());
    if (isValid(parsedDate)) {
        return parsedDate;
    }
    return null;
}
