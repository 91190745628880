<template>
  <vl-layout>
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <vl-title tag-name="h1">
          {{ $t('configuration.title') }}
        </vl-title>
      </vl-column>
    </vl-grid>
    <vl-tabs :active-tab="data.activeTab" :hash-change="false" @tab-change="onTabChange">
      <vl-tab v-if="isFeatureEnabled('configurationEntityModelSearch')" :id="'entitymodel'" label="Entiteitsmodellen">
        <EntityModelSearch v-if="data.activeTab === 0"></EntityModelSearch>
      </vl-tab>
      <vl-tab
        v-if="isFeatureEnabled('configurationDeliveryProcessDefinitionSearch')"
        :id="'deliveryprocessdefinition'"
        label="Leveringsprocesdefinities"
      >
        <DeliveryProcessDefinitionSearch v-if="data.activeTab === 1"></DeliveryProcessDefinitionSearch>
      </vl-tab>
      <vl-tab
        v-if="isFeatureEnabled('configurationProductProcessDefinitionSearch')"
        :id="'productprocessdefinition'"
        label="Productprocesdefinities"
      >
        <ProductProcessDefinitionSearch v-if="data.activeTab === 2"></ProductProcessDefinitionSearch>
      </vl-tab>
      <vl-tab
        v-if="isFeatureEnabled('configurationProcessTriggerSearch')"
        :id="'processtrigger'"
        label="Processtriggers"
      >
        <ProcessTriggerSearch v-if="data.activeTab === 3"></ProcessTriggerSearch>
      </vl-tab>
      <vl-tab v-if="isFeatureEnabled('configurationOrganisationSearch')" :id="'organisation'" label="Organisaties">
        <OrganisationSearch v-if="data.activeTab === 4"></OrganisationSearch>
      </vl-tab>
      <vl-tab v-if="isFeatureEnabled('configurationSystemTableSearch')" :id="'systemtable'" label="Basistabellen">
        <SystemTableSearch v-if="data.activeTab === 5"></SystemTableSearch>
      </vl-tab>
    </vl-tabs>
  </vl-layout>
</template>
<script lang="ts" setup>
import { isFeatureEnabled } from '@/common/config/config';
import { reactive, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import EntityModelSearch from './entity-model/EntityModelSearch.vue';
import DeliveryProcessDefinitionSearch from './delivery-process-definition/DeliveryProcessDefinitionSearch.vue';
import ProductProcessDefinitionSearch from './product-process-definition/ProductProcessDefinitionSearch.vue';
import ProcessTriggerSearch from './process-trigger/ProcessTriggerSearch.vue';
import OrganisationSearch from './organisation/OrganisationSearch.vue';
import SystemTableSearch from './systemtable/SystemTableSearch.vue';

const tabs = [
  { index: 0, hash: 'entitymodel' },
  { index: 1, hash: 'deliveryprocessdefinition' },
  { index: 2, hash: 'productprocessdefinition' },
  { index: 3, hash: 'processtrigger' },
  { index: 4, hash: 'organisation' },
  { index: 5, hash: 'systemtable' },
];

const data = reactive({
  activeTab: null,
});

const route = useRoute();
const router = useRouter();

onBeforeMount(() => {
  data.activeTab = tabs.find((t) => t.hash.toLowerCase() === route.hash?.replace('#', '')?.toLowerCase())?.index ?? 0;
});

const onTabChange = (tab) => {
  data.activeTab = tab.index;
  router.push({ query: {}, hash: `#` + tab.id });
};
</script>

<style lang="scss" scoped>
:deep(.vl-tabs) {
  margin-bottom: 0;
}
</style>
