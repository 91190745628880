<template>
  <vl-properties-list v-vl-spacer:bottom.small>
    <vl-properties-label>{{ $t('organisations.detail.id') }}</vl-properties-label>
    <vl-properties-data>{{ detail.id }}</vl-properties-data>
  </vl-properties-list>
  <vl-form-column v-vl-spacer:bottom.small>
    <PbsInputField name="name" :label="t('organisations.detail.name')" :placeholder="t('organisations.detail.name')" />
  </vl-form-column>
  <vl-form-column v-vl-spacer:bottom.small>
    <PbsInputField
      name="ovoNumber"
      :label="t('organisations.detail.ovoNumber')"
      :placeholder="t('organisations.detail.ovoNumber')"
    />
  </vl-form-column>
  <vl-form-column v-vl-spacer:bottom.small>
    <PbsInputField
      name="kboNumber"
      :label="t('organisations.detail.kboNumber')"
      :placeholder="t('organisations.detail.kboNumber')"
    />
  </vl-form-column>
</template>

<script lang="ts" setup>
import { OrganisationDetailDto } from '@/api/portal-api/clients';
import { useOrganisationDetailStore } from '@/modules/configuration/store/organisation/organisation-detail.store';
import { useForm } from 'vee-validate';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { object, string, mixed } from 'yup';
import PbsInputField from '@/components/forms/PbsInputField.vue';

const store = useOrganisationDetailStore();
const { t } = useI18n();

const validationSchema = object({
  name: string()
    .required(t('validations.messages.required', { _field_: t('organisations.detail.name') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('organisations.detail.name'), length: 256 })),
  kboNumber: string()
    .nullable()
    .max(10, t('validations.messages.maxChar', { _field_: t('organisations.detail.kboNumber'), length: 10 })),
  ovoNumber: string()
    .nullable()
    .max(25, t('validations.messages.maxChar', { _field_: t('organisations.detail.ovoNumber'), length: 25 })),
  custom: mixed().test(
    'custom-validation',
    'The properties do not meet the validation criteria',
    function (_, context) {
      const { kboNumber, ovoNumber } = context.parent;
      if (!kboNumber?.trim() && !ovoNumber?.trim()) {
        return context.createError({
          path: 'kboNumber',
          message: t('organisations.detail.kboOrOvoCodeRequired'),
        });
      }
      return true;
    },
  ),
});

const { resetForm, handleSubmit } = useForm({
  validationSchema,
});

onMounted(async () => {
  resetForm({ values: { ...detail.value } });
});

const detail = computed((): OrganisationDetailDto => {
  return store.detail;
});

const onSuccess = async (values) => {
  await store.update(detail.value.id, {
    name: values.name?.trim(),
    kboNumber: values.kboNumber?.trim(),
    ovoNumber: values.ovoNumber?.trim(),
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
