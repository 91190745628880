<template>
  <div v-if="props.id">
    <vl-popover>
      <template v-slot:label>
        <vl-button
          mod-icon
          mod-secondary
          mod-naked
          icon="nav-show-more-vertical"
          class="productprocessdefinition-action-button"
        >
        </vl-button>
      </template>
      <div class="vl-popover-content">
        <vl-link-list>
          <vl-link mod-block @click="showChangeStatusModal">{{ $t('productprocessdefinition.actions.changeStatus.label') }}</vl-link>
          <vl-link mod-block @click="showDuplicationModal">{{ $t('productprocessdefinition.actions.duplicate.label') }}</vl-link>
        </vl-link-list>
      </div>
    </vl-popover>
  </div>
</template>
<script lang="ts" setup>
import { useModalStore } from '@/modules/core/store/modal.store';
import { useI18n } from 'vue-i18n';
import DefinitionStatusForm from '../definition-status-form/DefinitionStatusForm.vue';
import DefinitionDuplicationForm from '../definition-duplication-form/DefinitionDuplicationForm.vue';

const modalStore = useModalStore();
const { t } = useI18n();

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const showChangeStatusModal = () => {
  const modalContent = {
    title: t('productprocessdefinition.actions.changeStatus.description'),
    component: DefinitionStatusForm,
    props: {
      id: props.id,
    },
  };

  modalStore.showModal(modalContent);
};

const showDuplicationModal = () => {
  const modalContent = {
    title: t('productprocessdefinition.actions.duplicate.description'),
    component: DefinitionDuplicationForm,
    props: {
      id: props.id,
    },
  };

  modalStore.showModal(modalContent);
};
</script>
<style lang="scss" scoped>
.productprocessdefinition-action-button {
  min-height: 1.2em;
}
</style>
