<template>
  <vl-layout>
    <PbsGoBackLink />
    <template v-if="!loading">
      <vl-grid>
        <vl-column width="8" width-xs="12">
          <vl-title tag-name="h1"> {{ $t('processtrigger.detail.title') }} </vl-title>
        </vl-column>
        <vl-column width="4" width-xs="12">
          <vl-action-group
            v-if="detail && !editStore.isEdit(ProcessTriggerEditType.ProcessTrigger, detail.id)"
            mod-align-right
            mod-space
            between
          >
            <vl-button
              icon="pencil"
              mod-icon-before
              mod-secondary
              :mod-disabled="editStore.editing"
              @click="editStore.openEdit(ProcessTriggerEditType.ProcessTrigger, detail.id)"
            >
              {{ $t('general.edit') }}
            </vl-button>
            <vl-button
              icon="trash"
              mod-icon-before
              mod-error
              @click="editStore.openDelete(ProcessTriggerEditType.ProcessTrigger, id)"
              >{{ $t('general.delete') }}</vl-button
            >
          </vl-action-group>
          <vl-action-group v-else mod-align-right mod-space between>
            <vl-button icon="save" :mod-loading="reloading" mod-icon-before @click="save">{{
              $t('general.save')
            }}</vl-button>
            <vl-button
              icon="cross"
              :mod-loading="loading"
              mod-icon-before
              mod-secondary
              mod-error
              @click="editStore.closeEdit"
            >
              {{ $t('general.cancel') }}
            </vl-button>
          </vl-action-group>
        </vl-column>
      </vl-grid>
      <vl-grid
        v-if="detail"
        v-vl-spacer:bottom.medium
        :class="{ edit: editStore.isEdit(ProcessTriggerEditType.ProcessTrigger, detail.id) }"
      >
        <vl-column>
          <ProcessTriggerRead v-if="!editStore.isEdit(ProcessTriggerEditType.ProcessTrigger, detail.id)" />
          <ProcessTriggerEdit v-else ref="editForm" />
        </vl-column>
      </vl-grid>
    </template>
    <template v-if="loading">
      <vl-region>
        <div v-vl-align:center>
          <vl-loader :message="$t('processtrigger.detail.loading')" />
        </div>
      </vl-region>
    </template>
    <vl-modal
      :id="deleteKey.toString()"
      :title="$t('processtrigger.detail.delete.title')"
      :open="editStore.isDelete(ProcessTriggerEditType.ProcessTrigger, deleteKey)"
    >
      <vl-action-group>
        <vl-button icon="trash" mod-icon-before mod-error @click="remove(deleteKey)">
          {{ $t('general.delete') }}
        </vl-button>
        <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeDelete">
          {{ $t('general.cancel') }}
        </vl-button>
      </vl-action-group>
    </vl-modal>
  </vl-layout>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref, onMounted } from 'vue';
import { useRoute, onBeforeRouteLeave, useRouter } from 'vue-router';
import { useProcessTriggerDetailStore } from '../../store/process-trigger/process-trigger-detail.store';
import {
  useProcessTriggerEditStore,
  ProcessTriggerEditType,
} from '../../store/process-trigger/process-trigger-edit.store';
import { ProcessTriggerDetailDto } from '@/api/portal-api/clients';
import PbsGoBackLink from '@/components/PbsGoBackLink.vue';
import ProcessTriggerRead from './ProcessTriggerRead.vue';
import ProcessTriggerEdit from './ProcessTriggerEdit.vue';

const store = useProcessTriggerDetailStore();
const editStore = useProcessTriggerEditStore();
const { t } = useI18n();

const route = useRoute();
const router = useRouter();

const editForm = ref();

const detail = computed((): ProcessTriggerDetailDto => {
  return store.detail;
});

const loading = computed((): boolean => {
  return store.loading;
});

const reloading = computed((): boolean => {
  return store.reloading;
});

const id = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

onMounted(async () => {
  await store.get(id.value);
});

const save = () => {
  editForm.value.onSubmit();
};

const remove = async (id: number) => {
  const isSuccess = await store.delete(id);
  editStore.closeDelete();
  if (isSuccess) {
    router.push({ name: 'configuration', hash: '#processtrigger' });
  }
};

onBeforeRouteLeave((to, from, next) => {
  const id = parseInt(from.params.id as string, 10);
  if (id && store) {
    store.clear(id);
  }
  next();
});
</script>
<style lang="scss" scoped>
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colorsRaw.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-form-group {
  background: $vl-alt-bg;
  padding: 3rem;
}
</style>
