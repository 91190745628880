<template>
  <vl-icon v-if="showIcon" icon="lock" class="status-icon" :style="getStyle" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const showIcon = computed(() => {
  return props.id !== 0;
});

const getStyle = computed(() => {
  let color = 'green';

  switch (props.id) {
    case 1:
      color = 'green';
      break;
    case 2:
      color = 'red';
      break;
    case 3:
      color = 'black';
      break;
  }
  return {
    color,
  };
});
</script>

<style lang="scss" scoped>
.status-icon {
  font-size: 24px;
}
</style>
