<template>
  <vl-layout>
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <vl-toaster
          v-if="alert && alert.isToast"
          :mod-top-right="alert.vertical === VerticalPosition.Top && alert.horizontal === HorizontalPosition.Right"
          :mod-top-left="alert.vertical === VerticalPosition.Top && alert.horizontal === HorizontalPosition.Left"
          :mod-bottom-right="
            alert.vertical === VerticalPosition.Bottom && alert.horizontal === HorizontalPosition.Right
          "
          :mod-bottom-left="alert.vertical === VerticalPosition.Bottom && alert.horizontal === HorizontalPosition.Left"
          fade-out
        >
          <vl-alert
            mod-fade-out
            :icon="alert.icon"
            :mod-error="alert.isError"
            :mod-success="alert.isSuccess"
            closable
            :title="alert.title"
            :content="alert.content"
            @close="closeAlert"
          />
        </vl-toaster>
        <vl-alert
          v-if="alert && !alert.isToast"
          :icon="alert.icon"
          :mod-error="alert.isError"
          :mod-success="alert.isSuccess"
          closable
          :title="alert.title"
          :content="alert.content"
          @close="closeAlert"
        />
      </vl-column>
    </vl-grid>
  </vl-layout>
</template>
<script lang="ts" setup>
import { useAlertStore, IAlert, VerticalPosition, HorizontalPosition } from '@/modules/core/store/alert.store';
import { computed } from 'vue';
const store = useAlertStore();

const alert = computed((): IAlert => {
  return store.alert;
});

const closeAlert = () => {
  store.clearAlert();
};
</script>
<style lang="scss" scoped>
.vl-toaster {
  width: 35rem;
}
</style>
