import { createPinia } from 'pinia';

import { useUserStore } from './user.store';
import { useSuppliersStore } from './supplier.store';
import { useOrganisationStore } from './organisation.store';
import { useDeliveryProcessDefinitionStore } from './deliveryProcessDefinition.store';
import { useProductSpecificationStore } from './productSpecification.store';
import { useDeliveryRunStore } from './deliveryRun.store';
import { useProductRunStore } from './productRun.store';
import { usePageStore } from './page.store';

export const stores = {
    UserStore: useUserStore,
    DeliveryProcessDefinitionStore: useDeliveryProcessDefinitionStore,
    ProductSpecificationStore: useProductSpecificationStore,
    DeliveryStore: useDeliveryRunStore,
    ProductStore: useProductRunStore,
    OrganisationStore: useOrganisationStore,
    SupplierStore: useSuppliersStore,
    PageStore: usePageStore,
};

const pinia = createPinia();
export default pinia;
