<template>
  <form class="vl-form" @submit.prevent="onSubmit()">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-properties-list v-if="data.definition">
          <vl-properties-label>{{ $t('productprocessdefinition.detail.id') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.id }}</vl-properties-data>
          <vl-properties-label>{{ $t('productprocessdefinition.detail.label') }}</vl-properties-label>
          <vl-properties-data>{{ data.definition.label }}</vl-properties-data>
        </vl-properties-list>
      </vl-column>
      <vl-column width="12" width-s="12" width-xs="12">
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsRadioButtonField
            name="duplicateEntityModels"
            :label="$t('productprocessdefinition.actions.duplicate.duplicateEntityModels')"
          />
        </vl-form-column>
        <vl-action-group v-vl-spacer:top.medium>
          <vl-button :mod-disabled="data.isSaving" :mod-loading="data.isSaving">{{
            $t('productprocessdefinition.actions.duplicate.label')
          }}</vl-button>
          <vl-button
            v-focus
            :mod-disabled="data.isSaving"
            mod-icon-before
            mod-secondary
            tag-name="a"
            @click.prevent="cancel"
          >
            {{ $t('general.cancel') }}
          </vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
  </form>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue';
import { useProductProcessDefinitionDetailStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-detail.store';
import { reactive } from 'vue';
import { useModalStore } from '@/modules/core/store/modal.store';
import { useRouter } from 'vue-router';
import { useForm, Field } from 'vee-validate';
import { boolean, object } from 'yup';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';

const router = useRouter();
const detailStore = useProductProcessDefinitionDetailStore();
const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const validationSchema = object({
  duplicateEntityModels: boolean(),
});

const { handleSubmit, resetForm } = useForm({ validationSchema });

const data = reactive({
  definition: null,
  isSaving: false,
});

onMounted(async () => {
  resetForm({
    values: { duplicateEntityModels: true },
  });

  const productProcessDefinition = await detailStore.get(props.id);
  if (productProcessDefinition) {
    data.definition = productProcessDefinition;
  }
});

const duplicate = async ({ duplicateEntityModels }: { duplicateEntityModels: boolean }): Promise<number> => {
  let id = null;
  try {
    data.isSaving = true;
    id = await detailStore.duplicate(props.id, {
      duplicateEntityModels,
    });
  } catch (e) {
    console.error(e);
  } finally {
    data.isSaving = false;
  }
  return id;
};

const cancel = () => {
  closeModal();
};

const closeModal = () => {
  useModalStore().hideModal();
};

const onSuccess = async (values) => {
  const id = await duplicate({
    duplicateEntityModels: values.duplicateEntityModels,
  });

  closeModal();

  if (id) {
    router.push({
      name: 'productprocessdefinition.detail',
      params: {
        id,
      },
    });
  }
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);
</script>
<style lang="scss" scoped></style>
