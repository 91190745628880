import { ProcessTriggerDetailDto } from '@/api/portal-api/clients';
import { computed, ref } from 'vue';
import { object, string, mixed, boolean, bool } from 'yup';

export const INVALID_CRON_SCHEDULE = 'Invalid cron schedule';

export const useProcessTriggerValidations = (t, detail: ProcessTriggerDetailDto = null) => {
    const timeTriggerValidationSchema = object({
        triggerType: object().required(
            t('validations.messages.required', { _field_: t('processtrigger.detail.triggerType') }),
        ),
        timeTriggerType: object()
            .nullable()
            .required(t('validations.messages.required', { _field_: t('processtrigger.detail.timeTriggerType') })),
        description: string().required(
            t('validations.messages.required', { _field_: t('processtrigger.detail.description') }),
        ),
        deliveryProcessDefinition: object().nullable(),
        productProcessDefinition: object().nullable(),
        schedule: string().required(
            t('validations.messages.required', { _field_: t('processtrigger.detail.schedule') }),
        ),
        validCronSchedule: bool().nullable().default(true),
        isEnabled: boolean(),
        custom: mixed().test(
            'custom-validation',
            'The properties do not meet the validation criteria',
            function (_, context) {
                const {
                    timeTriggerType,
                    deliveryProcessDefinition,
                    productProcessDefinition,
                    validCronSchedule,
                    schedule,
                } = context.parent;
                if (timeTriggerType?.name == 'Levering' && !deliveryProcessDefinition) {
                    return context.createError({
                        path: 'deliveryProcessDefinition',
                        message: t('validations.messages.required', {
                            _field_: t('processtrigger.detail.deliveryProcessDefinition'),
                        }),
                    });
                }
                if (timeTriggerType?.name == 'Product' && !productProcessDefinition) {
                    return context.createError({
                        path: 'productProcessDefinition',
                        message: t('validations.messages.required', {
                            _field_: t('processtrigger.detail.productProcessDefinition'),
                        }),
                    });
                }

                if (schedule && !validCronSchedule) {
                    return context.createError({
                        path: 'schedule',
                        message: t('processtrigger.detail.invalidCronExpression'),
                    });
                }
                return true;
            },
        ),
    });

    const defaultValidationSchema = object({
        triggerType: object().required(
            t('validations.messages.required', { _field_: t('processtrigger.detail.triggerType') }),
        ),
        description: string().required(
            t('validations.messages.required', { _field_: t('processtrigger.detail.description') }),
        ),
        deliveryProcessDefinition: object().required(
            t('validations.messages.required', { _field_: t('processtrigger.detail.deliveryProcessDefinition') }),
        ),
        isEnabled: boolean(),
    });

    const triggerTypeRef = ref(detail?.triggerType);

    const validationSchema = computed(() => {
        const isTimeTrigger = triggerTypeRef.value?.id === 3;
        return isTimeTrigger ? timeTriggerValidationSchema : defaultValidationSchema;
    });

    return {
        validationSchema,
        triggerTypeRef,
    };
};
