<template>
  <form>
    <vl-properties-list>
      <vl-properties-label>{{ $t('deliveryprocessdefinition.detail.id') }}</vl-properties-label>
      <vl-properties-data>{{ detail.id }}</vl-properties-data>
    </vl-properties-list>

    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="label"
        :label="t('deliveryprocessdefinition.create.label')"
        :placeholder="t('deliveryprocessdefinition.create.label')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('deliveryprocessdefinition.create.description')"
        :placeholder="t('deliveryprocessdefinition.create.description')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="organisation"
        :label="t('deliveryprocessdefinition.create.organisation')"
        :placeholder="$t('deliveryprocessdefinition.create.organisation')"
        :options="organisationOptions"
        :custom-label="getLabel"
        @search-change="onSearchChange"
      />
    </vl-form-column>
  </form>
</template>

<script lang="ts" setup>
import { DeliveryProcessDefinitionDetailDto, OrganisationSearchResultDto } from '@/api/portal-api/clients';
import { useOrganisationStore } from '@/modules/configuration/store/organisation/organisation.store';
import { useDeliveryProcessDefinitionDetailStore } from '@/modules/configuration/store/delivery-process-definition/delivery-process-definition-detail.store';
import { useForm } from 'vee-validate';
import { Ref, computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { object, string } from 'yup';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';

const store = useDeliveryProcessDefinitionDetailStore();
const organisationStore = useOrganisationStore();
const { t } = useI18n();

const organisationOptions: Ref<OrganisationSearchResultDto[]> = ref([]);

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('deliveryprocessdefinition.create.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('deliveryprocessdefinition.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('deliveryprocessdefinition.create.description') }),
  ),
  organisation: object().required(
    t('validations.messages.required', { _field_: t('deliveryprocessdefinition.create.organisation') }),
  ),
});

const { resetForm, handleSubmit } = useForm({
  validationSchema,
});

onMounted(async () => {
  organisationOptions.value = await organisationStore.getOrganisations();

  const currentOrg = organisationOptions.value?.find((x) => x.ovoNumber === detail.value.ovoNumber);

  resetForm({ values: { ...detail.value, organisation: currentOrg } });
});

const detail = computed((): DeliveryProcessDefinitionDetailDto => {
  return store.detail;
});

const onSuccess = async (values) => {
  // map organisation to dtoprop
  await store.update(detail.value.id, {
    label: values.label,
    description: values.description,
    ovoNumber: values.organisation.ovoNumber,
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const onSearchChange = async (query: string) => {
  organisationOptions.value = organisationStore.filter(query);
};

const getLabel = (entry: OrganisationSearchResultDto) => {
  return `${entry?.name} (${entry?.ovoNumber})`;
};

defineExpose({ onSubmit });
</script>
