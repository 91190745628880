<template>
  <vl-properties-list>
    <vl-properties-label>{{ $t('producttransformation.detail.id') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.id }}
    </vl-properties-data>
    <vl-properties-label>{{ $t('producttransformation.detail.transformationType') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.transformationType.name }}
    </vl-properties-data>
    <template v-if="isFeatureSupported('fromEntity')">
      <vl-properties-label>{{ $t('producttransformation.detail.fromEntity') }}</vl-properties-label>
      <vl-properties-data>
        {{ getEntityLabel(props.detail.fromEntity) }}
      </vl-properties-data>
    </template>
    <template v-if="isFeatureSupported('toEntity')">
      <vl-properties-label>{{ $t('producttransformation.detail.toEntity') }}</vl-properties-label>
      <vl-properties-data>
        {{ getEntityLabel(props.detail.toEntity) }}
      </vl-properties-data>
    </template>
    <template v-if="isFeatureSupported('parameter')">
      <vl-properties-label>{{ $t('producttransformation.detail.parameter') }}</vl-properties-label>
      <vl-properties-data>
        {{ props.detail.parameter }}
      </vl-properties-data>
    </template>
  </vl-properties-list>
  <template v-if="isLookupType">
    <vl-title tag-name="h4"> {{ $t('producttransformation.detail.lookupLink') }} </vl-title>
    <ProductTransformationLookupLinkRead v-if="props.detail.lookupLink" :detail="props.detail.lookupLink" />
  </template>
  <ProductTransformationAttributesRead v-if="isFeatureSupported('attributeMappings')" :detail="detail" />
</template>

<script lang="ts" setup>
import {
  ProductEntityDetailDto,
  ProductTransformationDetailDto,
  SourceEntityDetailDto,
} from '@/api/portal-api/clients';
import ProductTransformationAttributesRead from '../product-transformation/ProductTransformationAttributesRead.vue';
import ProductTransformationLookupLinkRead from '../product-transformation-lookup-link/ProductTransformationLookupLinkRead.vue';
import {
  TransformationTypeFeature,
  isLookupLinkType,
  isTransformationTypeFeatureSupported,
} from '@/modules/configuration/common';
import { computed } from 'vue';

const props = defineProps<{
  detail: ProductTransformationDetailDto;
}>();

const isFeatureSupported = (feature: keyof TransformationTypeFeature) => {
  return isTransformationTypeFeatureSupported(props.detail.transformationType.id, feature);
};

const isLookupType = computed(() => {
  return isLookupLinkType(props.detail.transformationType);
});

const getEntityLabel = (entity: SourceEntityDetailDto | ProductEntityDetailDto): string => {
  return entity ? `[${entity.id}] ${entity.label}` : '-';
};
</script>
