import { EnvironmentConfig } from '@/common/models/environment';
import local from './environments/local.json';
import dev from './environments/dev.json';
import tst from './environments/tst.json';
import bet from './environments/bet.json';
import prd from './environments/prd.json';

async function fetchEnvironment(): Promise<string> {
    const environmentResponse = await fetch(import.meta.env.BASE_URL + 'environment.json');
    const environmentJson = await environmentResponse.json();
    return environmentJson.env;
}

function selectConfig(environment: string): EnvironmentConfig {
    switch (environment) {
        case 'local':
            return local;
        case 'dev':
            return dev;
        case 'tst':
            return tst;
        case 'bet':
            return bet;
        case 'prd':
            return prd;
        default:
            return prd;
    }
}

let config: EnvironmentConfig = local;

export async function loadEnvironmentConfig(): Promise<EnvironmentConfig> {
    const environment = await fetchEnvironment();
    config = selectConfig(environment);
    return config;
}

export function isFeatureEnabled(key: FeatureKeys): boolean {
    return config.features[key];
}

export type FeatureKeys = keyof typeof config.features;

export { config };
