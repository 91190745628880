<template>
  <vl-properties>
    <vl-properties-column>
      <vl-properties-list>
        <vl-properties-label>{{ $t('entitymodel.detail.id') }}</vl-properties-label>
        <vl-properties-data>{{ detail.id }}</vl-properties-data>
        <vl-properties-label>{{ $t('entitymodel.detail.code') }}</vl-properties-label>
        <vl-properties-data>{{ detail.code }}</vl-properties-data>
        <vl-properties-label>{{ $t('entitymodel.detail.label') }}</vl-properties-label>
        <vl-properties-data>{{ detail.label }}</vl-properties-data>
        <vl-properties-label>{{ $t('entitymodel.detail.description') }}</vl-properties-label>
        <vl-properties-data>{{ detail.description }}</vl-properties-data>
        <vl-properties-label>{{ $t('entitymodel.detail.contextType') }}</vl-properties-label>
        <vl-properties-data>{{ detail.contextType?.name }}</vl-properties-data>
        <vl-properties-label>{{ $t('entitymodel.detail.entityType') }}</vl-properties-label>
        <vl-properties-data>{{ detail.entityType?.name }}</vl-properties-data>
        <vl-properties-label>{{ $t('entitymodel.detail.hasHistory') }}</vl-properties-label>
        <vl-properties-data>{{ detail.hasHistory ? $t('general.yes') : $t('general.no') }}</vl-properties-data>
        <vl-properties-label>{{ $t('entitymodel.detail.status') }}</vl-properties-label>
        <vl-properties-data>{{ getStatusLabel(detail.statusId) }}</vl-properties-data>
      </vl-properties-list>
    </vl-properties-column>
  </vl-properties>
</template>

<script lang="ts" setup>
import { EntityModelDetailDto } from '@/api/portal-api/clients';
import { useEntityModelDetailStore } from '../../store/entity-model/entity-model-detail.store';
import { computed } from 'vue';
import { ENUM_StatusTypes } from '../../common';

const store = useEntityModelDetailStore();

const detail = computed((): EntityModelDetailDto => {
  return store.detail;
});

const getStatusLabel = (statusId: number): string => {
  return ENUM_StatusTypes.find((status) => status.id === statusId)?.name ?? '-';
};
</script>
