import { defineStore } from 'pinia';

import { clients, DeliveryProcessDefinition } from '@/api';

export type DeliveryProcessDefinitionStoreActions = {
    fetchDefinitions: () => Promise<void>;
};

export type DeliveryProcessDefinitionStoreState = {
    definitions: DeliveryProcessDefinition[];
};

export const useDeliveryProcessDefinitionStore = defineStore<
    string,
    DeliveryProcessDefinitionStoreState,
    {},
    DeliveryProcessDefinitionStoreActions
>('delivery-process-definition-store', {
    state: () => ({
        definitions: [] as DeliveryProcessDefinition[],
    }),
    actions: {
        async fetchDefinitions(): Promise<void> {
            const definitions = await clients.DeliveryProcessDefinitionClient.getAll();
            this.definitions = definitions;
        },
    },
});

export interface IDeliveryProcessDefinitionStore
    extends DeliveryProcessDefinitionStoreState,
        DeliveryProcessDefinitionStoreActions {}
