<template>
  <vl-functional-header
    v-vl-spacer:bottom.small
    :title="$t('app.title')"
    :tabNavigationItems="tabNavigationItems"
    title-link="/"
  >
  </vl-functional-header>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/store/user.store';
import { getDomainConfigurations } from '@/core/security/domain.config.ts';

const { hasRolesAsync } = useUserStore();
const route = useRoute();

const tabNavigationItems = ref([]);

watchEffect(async () => {
  const navigationItems = await Promise.all(
    getDomainConfigurations().map(async (d) => {
      const isInRole = await hasRolesAsync(d.roles);
      if (isInRole) {
        return {
          name: d.title,
          link: d.route,
          active: route.path.startsWith(d.route),
        };
      }
    }),
  );

  tabNavigationItems.value = navigationItems.filter((x) => !!x);
});
</script>
