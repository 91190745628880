<template>
  <vl-multiselect
    v-bind="$attrs"
    :id="id"
    ref="multiselect"
    :model-value="data.value"
    :v-model="data.value"
    :mod-multiple="false"
    :allowEmpty="true"
    :options="options"
    type="search"
    :track-by="keySelector"
    mod-block
    @update:model-value="handleUpdateModelValue"
    @search-change="handleSearchChange"
  />
</template>

<script lang="ts" setup>
import { reactive, useAttrs, watch } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(['update:modelValue', 'search-change']);

const handleSearchChange = (search: string) => {
  emit('search-change', search);
};

const handleUpdateModelValue = (value: any) => {
  data.value = value;
  const modelValue = value?.[props.keySelector];
  emit('update:modelValue', modelValue);
};

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [String, Number],
    default: '',
    required: false,
  },
  keySelector: {
    type: String,
    required: false,
    default: 'id',
  },
});

watch(
  () => props.modelValue,
  (value) => {
    data.value = props.options?.find((option) => (option as any)[props.keySelector] === value);
  },
);

const data = reactive({
  value: null as any,
});

const attrs = useAttrs();
</script>
