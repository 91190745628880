import { App } from 'vue';
import { createI18n } from 'vue-i18n';
import nlBE from '../locales/nl-BE.json';

export const defaultLocale = import.meta.env.VUE_APP_I18N_LOCALE || 'en';
export const fallbackLocale = import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en';

export const i18n = createI18n({
    silentTranslationWarn: true,
    allowComposition: true,
    locale: defaultLocale,
    fallbackLocale: fallbackLocale,
    fallbackRoot: false,
    legacy: false,
    messages: {
        'nl-BE': nlBE,
    },
});

export default {
    install(app: App) {
        app.use(i18n);
        app.config.globalProperties.i18n = i18n;
        app.config.globalProperties.$t = i18n.global.t;
        app.config.globalProperties.$te = i18n.global.te;
    },
};
