<template>
  <vl-grid>
    <vl-column>
      <vl-data-table-extended
        :data="items"
        :columns="columnMap"
        :meta="{
          totalRows: props.detail.attributeMappings?.length,
          resultsPerPage: props.detail.attributeMappings?.length,
          currentPage: 1,
        }"
        :fetching="linkStore.reloading"
        mod-zebra
        mod-hide-pagination
      >
        <template v-slot:noresults>
          <div v-vl-align:left>
            <div class="vl-u-spacer--large">
              <p>{{ $t('entitylinkAttributes.detail.empty') }}</p>
            </div>
          </div>
        </template>
      </vl-data-table-extended>
    </vl-column>
  </vl-grid>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { ProductEntityLinkAttributeMappingDto, ProductEntityLinkDetailDto } from '@/api/portal-api/clients';
import { useI18n } from 'vue-i18n';
import { ColumnDefExtended } from '@/components/vl-data-table-extended/types';
import { CellContext, RowData } from '@tanstack/vue-table';
import { useProductProcessDefinitionEditStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-edit.store';
import { useProductEntityLinkStore } from '@/modules/configuration/store/product-entity-link/product-entity-link.store';

const { t } = useI18n();
const editStore = useProductProcessDefinitionEditStore();
const linkStore = useProductEntityLinkStore();

const props = defineProps<{
  detail: ProductEntityLinkDetailDto;
  specificationId: number;
}>();

const items = computed((): ProductEntityLinkAttributeMappingDto[] => {
  return props.detail?.attributeMappings ?? [];
});

const emits = defineEmits(['update:modelValue', 'update-link']);

const columnMap = computed((): ColumnDefExtended<RowData>[] => {
  return [
    {
      accessorFn: (x) => x,
      header: t('entitylink.detail.fromAttribute'),
      size: 45,
      cell: (from: CellContext<ProductEntityLinkAttributeMappingDto, ProductEntityLinkAttributeMappingDto>) => {
        return from.getValue().fromAttribute?.code;
      },
    },
    {
      accessorFn: (x) => x,
      header: t('entitylink.detail.toAttribute'),
      size: 45,
      cell: (to: CellContext<ProductEntityLinkAttributeMappingDto, ProductEntityLinkAttributeMappingDto>) => {
        return to.getValue().toAttribute?.code;
      },
    },
  ];
});
</script>
