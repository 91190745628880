<template>
  <form>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="transformationType"
        :label="t('producttransformation.detail.transformationType')"
        :placeholder="$t('producttransformation.detail.transformationType')"
        :options="transformationTypes"
        :custom-label="(e) => e.name"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="fromEntity"
        :label="t('producttransformation.detail.fromEntity')"
        :placeholder="$t('producttransformation.detail.fromEntity')"
        :options="fromEntityOptions"
        :custom-label="fromEntityLabel"
        :mod-disabled="!isFeatureSupported('fromEntity')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="toEntity"
        :label="t('producttransformation.detail.toEntity')"
        :placeholder="$t('producttransformation.detail.toEntity')"
        :options="toEntityOptions"
        :custom-label="toEntityLabel"
        :mod-disabled="!isFeatureSupported('toEntity')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="parameter"
        :label="t('producttransformation.detail.parameter')"
        :placeholder="t('producttransformation.detail.parameter')"
        :mod-disabled="!isFeatureSupported('parameter')"
      />
    </vl-form-column>
    <vl-form-column v-if="isLookupType" v-vl-spacer:bottom.small>
      <PbsSelectField
        name="lookupLink"
        :label="t('producttransformation.detail.lookupLink')"
        :placeholder="$t('producttransformation.detail.lookupLink')"
        :options="lookupLinkOptions"
        :custom-label="lookupLinkLabel"
      />
    </vl-form-column>
    <vl-form-column v-if="canAddAttributeMapping" v-vl-spacer:bottom.small>
      <ProductTransformationAttributesEdit
        :definition-id="definitionId"
        :from-entity-id="fromEntityId"
        :to-entity-id="toEntityId"
      />
    </vl-form-column>
    <PbsErrorField name="custom-validation" />
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { useProductTransformationStore } from '@/modules/configuration/store/product-transformation/product-transformation.store';
import {
  EnumerationDto,
  ProductTransformationDetailDto,
  ProductTransformationLookupLinkDetailDto,
  ProductTransformationUpdateDto,
  TransformationTypeDto,
} from '@/api/portal-api/clients';
import { useProductEntityStore } from '@/modules/configuration/store/product-entity/product-entity.store';
import { ProductEntityDetailDto } from '@/api/portal-api/clients';
import { SourceEntityDetailDto } from '@/api/portal-api/clients';
import ProductTransformationAttributesEdit from '../product-transformation/ProductTransformationAttributesEdit.vue';
import { useProductTransformationValidations } from './product-transformation-validations';
import {
  ENUM_TransformationTypes,
  TransformationTypeFeature,
  getLookupOptions,
  isLookupLinkType,
  isTransformationTypeFeatureSupported,
} from '@/modules/configuration/common';
import { useSourceEntityStore } from '@/modules/configuration/store/source-entity/source-entity.store';
import { useProductTransformationLookupLinkStore } from '@/modules/configuration/store/product-transformation-lookup-link/product-transformation-lookup-link.store';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsErrorField from '@/components/forms/PbsErrorField.vue';

const { t } = useI18n();

const store = useProductTransformationStore();
const productEntityStore = useProductEntityStore();
const sourceEntityStore = useSourceEntityStore();
const lookupLinkStore = useProductTransformationLookupLinkStore();
const props = defineProps<{
  detail: ProductTransformationDetailDto;
  definitionId: number;
}>();

const { validationSchema } = useProductTransformationValidations(t, props.definitionId);

const { resetForm, setValues, handleSubmit, values, setFieldValue } = useForm({
  validationSchema,
});

const isFeatureSupported = (feature: keyof TransformationTypeFeature) => {
  return isTransformationTypeFeatureSupported(transformationType.value?.id, feature);
};

const isLookupType = computed(() => {
  return isLookupLinkType(transformationType.value);
});

const lookupLinkOptions = computed((): ProductTransformationLookupLinkDetailDto[] => {
  if (isLookupType.value) {
    const lookupLinks = lookupLinkStore.byDefinition(props.definitionId);

    return getLookupOptions(values.fromEntity, lookupLinks) ?? [];
  }

  return [];
});

const transformationTypes = computed((): EnumerationDto[] => {
  const supportedTransformationTypes = ['Attribuut mapping', 'Attribuut lookup', 'Nabewerking'];
  return ENUM_TransformationTypes.filter((x) => supportedTransformationTypes.includes(x.name));
});

const fromEntityOptions = computed((): SourceEntityDetailDto[] => {
  return sourceEntityStore.byDefinition(props.definitionId);
});

const toEntityOptions = computed((): ProductEntityDetailDto[] => {
  return productEntityStore.byDefinition(props.definitionId);
});

const fromEntityId = computed(() => {
  return values.fromEntity?.id ?? 0;
});

const toEntityId = computed(() => {
  return values.toEntity?.id ?? 0;
});

const transformationType = computed((): TransformationTypeDto => {
  return values.transformationType;
});

const attributeMappings = computed(() => {
  return values.attributeMappings ?? [];
});

const canAddAttributeMapping = computed(() => {
  return fromEntityId.value && toEntityId.value && isFeatureSupported('attributeMappings');
});

const fromEntityLabel = (productEntity: SourceEntityDetailDto) => {
  return `${productEntity.code} (${productEntity.label})`;
};

const toEntityLabel = (operationEntity: ProductEntityDetailDto) => {
  return `${operationEntity.code} (${operationEntity.label})`;
};

const lookupLinkLabel = (link: ProductTransformationLookupLinkDetailDto) => {
  return `${link.sourceEntity.label} (${link.keyAttribute.code} - ${link.valueAttribute.code})`;
};

watch(fromEntityId, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return;
  }

  const fromEntity = fromEntityOptions.value.find((x) => x.id === newValue);
  if (fromEntity) {
    updateFromAttributeValues(fromEntity);
    if (oldValue > 0) {
      setFieldValue('lookupLink', null, true);
    }
  }
});

watch(toEntityId, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return;
  }

  const toEntity = toEntityOptions.value.find((x) => x.id === newValue);
  if (toEntity) {
    updateToAttributeValues(toEntity);
  }
});

watch(transformationType, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return;
  }

  if (!newValue) {
    return;
  }

  if (!isLookupLinkType(newValue)) {
    setFieldValue('lookupLink', null, false);
  }

  if (!isFeatureSupported('attributeMappings')) {
    setFieldValue('attributeMappings', [], false);
  }

  if (!isFeatureSupported('fromEntity')) {
    setFieldValue('fromEntity', null, false);
  }

  if (!isFeatureSupported('toEntity')) {
    setFieldValue('toEntity', null, false);
  }

  if (!isFeatureSupported('parameter')) {
    setFieldValue('parameter', null, false);
  }
});

onMounted(async () => {
  await Promise.all([
    productEntityStore.getByDefinition(props.definitionId),
    sourceEntityStore.getByDefinition(props.definitionId),
    lookupLinkStore.getByDefinitionId(props.definitionId),
  ]);

  resetForm({
    values: getInitialFormValues(),
  });
});

const updateFromAttributeValues = (fromEntity: SourceEntityDetailDto) => {
  const fromAttributes = fromEntity.model?.attributes;
  if (!fromAttributes) {
    return;
  }

  let shouldUpdate = false;
  const updatedAttributeMappings = attributeMappings.value.map((x) => {
    const mapping = { ...x };
    const fromAttribute = fromAttributes.find((x) => x.id === mapping.from?.id);
    if (!fromAttribute) {
      mapping.from = null;
      shouldUpdate = true;
    }
    return mapping;
  });

  if (shouldUpdate) {
    setValues(
      {
        ...values,
        attributeMappings: updatedAttributeMappings,
      },
      true,
    );
  }
};

const updateToAttributeValues = (toEntity: ProductEntityDetailDto) => {
  const toAttributes = toEntity.model?.attributes;
  if (!toAttributes) {
    return;
  }

  let shouldUpdate = false;
  const updatedAttributeMappings = attributeMappings.value.map((x) => {
    const mapping = { ...x };
    const toAttribute = toAttributes.find((x) => x.id === mapping.to?.id);
    if (!toAttribute) {
      mapping.to = null;
      shouldUpdate = true;
    }
    return mapping;
  });

  if (shouldUpdate) {
    setValues({
      ...values,
      attributeMappings: updatedAttributeMappings,
    });
  }
};

const getInitialFormValues = () => {
  const values = {
    transformationType: props.detail.transformationType,
    fromEntity: props.detail.fromEntity,
    toEntity: props.detail.toEntity,
    parameter: props.detail.parameter,
    attributeMappings: getInitialAttributeMappings(),
    lookupLink: props.detail.lookupLink,
  };

  return values;
};

const getInitialAttributeMappings = () => {
  return props.detail.attributeMappings
    .map((x) => ({
      id: x.id,
      from: x.fromAttribute,
      to: x.toAttribute,
    }))
    .sort((x, y) => (x.from.code > y.from.code ? 1 : -1));
};

const createAttributeMappingsToUpdate = () => {
  if (!isFeatureSupported('attributeMappings')) {
    return [];
  }

  return values.attributeMappings.map((x) => ({
    id: x.id,
    fromAttributeId: x.from.id,
    toAttributeId: x.to.id,
  }));
};

const onSuccess = async (values) => {
  const attributeMappings = createAttributeMappingsToUpdate();

  const updateDto: ProductTransformationUpdateDto = {
    productProcessDefinitionId: props.definitionId,
    transformationType: values.transformationType.name,
    fromEntityId: values.fromEntity ? values.fromEntity.id : null,
    toEntityId: values.toEntity ? values.toEntity.id : null,
    parameter: values.parameter,
    attributeMappings,
    lookupLinkId: values?.lookupLink?.id,
  };

  await store.update(props.definitionId, props.detail.id, updateDto);
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
