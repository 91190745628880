import { useUserStore } from '@/store/user.store';
import { NavigationGuard, NavigationGuardNext, RouteLocation } from 'vue-router';

export interface MetaDataRoles {
    hasRoles?: string[];
}

export function createHasRoleGuard(): NavigationGuard {
    return async function (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): Promise<void> {
        // Gets metadata from the route definition to determine if it is a public route.
        const { hasRoles } = to.meta as MetaDataRoles;
        if (hasRoles && hasRoles?.length) {
            const store = useUserStore();
            const canEnter = await store.hasRolesAsync(hasRoles);
            if (!canEnter) {
                // evt richting unauthorized pagina sturen
                next({
                    name: 'unauthorized',
                });
                return;
            }
        }
        next();
        return;
    };
}
