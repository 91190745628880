<template>
  <vl-modal :id="modalId" :key="renderKey" :title="title" :closable="true" :open="open">
    <component :is="content.component" v-bind="content.props"></component>
  </vl-modal>
</template>
<script lang="ts" setup>
import { useModalStore } from '@/modules/core/store/modal.store';
import { computed, onMounted, onUnmounted, ref } from 'vue';

const store = useModalStore();
const renderKey = ref(0);
const modalId = 'pbs-modal';

// Force rerender of the modal because the modal does not update correctly on its own!!!!
const forceRerender = () => {
  renderKey.value++;
};

const open = computed(() => {
  return store.open;
});

const content = computed(() => {
  return store.content;
});

const title = computed(() => {
  return content.value?.title;
});

const closedHandler = (evt: any) => {
  if (evt.detail.modalId !== modalId) {
    return;
  }

  store.hideModal();
  forceRerender();
};

onMounted(() => {
  document.addEventListener('closed', closedHandler);
});

onUnmounted(() => {
  document.removeEventListener('closed', closedHandler);
});
</script>
<style lang="scss" scoped></style>
