<template>
  <vl-properties-list v-vl-spacer:bottom.small>
    <vl-properties-label>{{ $t('entityAttribute.detail.id') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.id }}</vl-properties-data>
    <vl-properties-label>{{ $t('entityAttribute.detail.code') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.code }}</vl-properties-data>
    <vl-properties-label>{{ $t('entityAttribute.detail.label') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.label }}</vl-properties-data>
    <vl-properties-label>{{ $t('entityAttribute.detail.description') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.description }}</vl-properties-data>
    <vl-properties-label>{{ $t('entityAttribute.detail.order') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.order }}</vl-properties-data>
    <vl-properties-label>{{ $t('entityAttribute.detail.dataType') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.dataType.name }}</vl-properties-data>
    <vl-properties-label>{{ $t('entityAttribute.detail.length') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.length }}</vl-properties-data>
    <vl-properties-label>{{ $t('entityAttribute.detail.decimalCount') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.decimalCount || '-' }}</vl-properties-data>
    <vl-properties-label>{{ $t('entityAttribute.detail.isIdentifying') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.isIdentifying ? $t('general.yes') : $t('general.no') }}</vl-properties-data>
    <vl-properties-label>{{ $t('entityAttribute.detail.isMandatory') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.isMandatory ? $t('general.yes') : $t('general.no') }}</vl-properties-data>
    <vl-properties-label>{{ $t('entityAttribute.detail.isCalculated') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.isCalculated ? $t('general.yes') : $t('general.no') }}</vl-properties-data>
  </vl-properties-list>
</template>
<script lang="ts" setup>
import { EntityAttributeDetailDto } from '@/api/portal-api/clients';
const props = defineProps<{
  detail: EntityAttributeDetailDto;
}>();
</script>
