import { ProductProcessDefinition } from '@/api';
import { ENUM_StatusTypes } from '@/modules/configuration/common';

export const getDefinitionLabel = (definition: ProductProcessDefinition) => {
    // toon status label indien de status verschillend van Actief
    let label = definition.label;
    if (definition.statusId != 1) {
        const statusLabel = ENUM_StatusTypes.find((x) => x.id == definition.statusId)?.name;
        label = `${definition.label} - ${statusLabel}`;
    }
    return label;
};
