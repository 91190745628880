import { format } from 'date-fns-tz';

export function defaultDatetimeFormatter(value?: Date, emptyText = ''): string {
    if (!value) {
        return emptyText;
    }
    return format(value, 'dd.MM.yyyy HH:mm:ss');
}

export function defaultDateFormatter(value: Date, emptyText = ''): string {
    if (!value) {
        return emptyText;
    }
    return format(value, 'dd.MM.yyyy');
}

export function dateTimeFilter(value: unknown, emptyText = ''): string {
    if (!value) {
        return emptyText;
    }

    if (typeof value === 'string') {
        return defaultDatetimeFormatter(new Date(value), emptyText);

        // parses ISO 8601 string into Date, due to date-fns library, all conversion scenarios should be supported
        // const date = parseISO(value) // + "Z", undefined Kind in .NET DateTime results in missing Z suffix
        // const timeZone = 'Europe/Brussels'
        // const zonedDate = utcToZonedTime(date, timeZone) //this translation step is only necessary if the Date is UTC, not Local
        // return defaultDatetimeFormatter(zonedDate, emptyText);
    }
    if (value instanceof Date) {
        return defaultDatetimeFormatter(value, emptyText);
    }
    return emptyText;
}

export function dateFilter(value: unknown, emptyText = ''): string {
    if (!value) {
        return emptyText;
    }

    if (typeof value === 'string') {
        return defaultDateFormatter(new Date(value), emptyText);

        // parses ISO 8601 string into Date, due to date-fns library, all conversion scenarios should be supported
        // const date = parseISO(value) // + "Z", undefined Kind in .NET DateTime results in missing Z suffix
        // const timeZone = 'Europe/Brussels'
        // const zonedDate = utcToZonedTime(date, timeZone) //this translation step is only necessary if the Date is UTC, not Local
        // return defaultDateFormatter(zonedDate, emptyText);
    }
    if (value instanceof Date) {
        return defaultDateFormatter(value, emptyText);
    }
    return emptyText;
}
