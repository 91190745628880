<template>
  <form>
    <vl-properties-list>
      <vl-properties-label>{{ $t('operationentity.detail.id') }}</vl-properties-label>
      <vl-properties-data>{{ detail.id }}</vl-properties-data>
    </vl-properties-list>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="code"
        :label="t('operationentity.detail.code')"
        :placeholder="t('operationentity.detail.code')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="label"
        :label="t('operationentity.detail.label')"
        :placeholder="t('operationentity.detail.label')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('operationentity.detail.description')"
        :placeholder="t('operationentity.detail.description')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="model"
        :label="t('operationentity.detail.model')"
        :placeholder="$t('operationentity.detail.model')"
        :options="entityModels"
        :custom-label="entityModelLabel"
        :mod-loading="fetchingModels"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="crs"
        :label="t('operationentity.detail.crs')"
        :placeholder="$t('operationentity.detail.crs')"
        :options="sridTypes"
        :custom-label="(e) => e.name"
      />
    </vl-form-column>
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { OperationEntityDetailDto, EnumerationDto, SearchEntityModelDto } from '@/api/portal-api/clients';
import { useForm, Field } from 'vee-validate';
import { object, string } from 'yup';
import { useI18n } from 'vue-i18n';
import { useOperationEntityStore } from '@/modules/configuration/store/operation-entity/operation-entity.store';
import { useEntityModelSelectStore } from '@/modules/configuration/store/entity-model/entity-model-select.store';
import { ENUM_SridTypes } from '@/modules/configuration/common';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';

const { t } = useI18n();
const store = useOperationEntityStore();
const entityModelSelectStore = useEntityModelSelectStore();

const props = defineProps<{
  detail: OperationEntityDetailDto;
  specificationId: number;
}>();

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('operationentity.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('operationentity.detail.label'), length: 256 })),
  code: string()
    .required(t('validations.messages.required', { _field_: t('operationentity.detail.code') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('operationentity.detail.code'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('operationentity.detail.description') }),
  ),
  crs: object().required(t('validations.messages.required', { _field_: t('operationentity.detail.crs') })),
  model: object().required(t('validations.messages.required', { _field_: t('operationentity.detail.model') })),
});

const { resetForm, handleSubmit } = useForm({
  validationSchema,
});

const detail = computed((): OperationEntityDetailDto => {
  return props.detail;
});

const sridTypes = computed((): EnumerationDto[] => {
  return ENUM_SridTypes;
});

const fetchingModels = computed((): Boolean => {
  return entityModelSelectStore.loading;
});

const entityModels = computed((): SearchEntityModelDto[] => {
  return entityModelSelectStore.items ?? [];
});

onMounted(async () => {
  await entityModelSelectStore.getModels({ force: true, contextTypeName: 'Operation' });
  resetForm({ values: props.detail });
});

const onSuccess = async (values) => {
  await store.update(props.specificationId, props.detail.id, {
    code: values.code,
    label: values.label,
    description: values.description,
    modelId: values.model.id,
    crs: values.crs.name,
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const entityModelLabel = (entityModel: SearchEntityModelDto) => {
  return `${entityModel.code} (${entityModel.label})`;
};

defineExpose({ onSubmit });
</script>
