import { computed, defineComponent } from 'vue';
import VlDataTableExtendedPagerPage from '../data-table-extended-pager-page/vl-data-table-extended-pager-page.vue';

export const VlDataTableExtendedPagerDots = '...';

export default defineComponent({
    name: 'VlDataTableExtendedPager',
    components: {
        VlDataTableExtendedPagerPage,
    },
    props: {
        currentPageIndex: {
            type: Number,
            default: 0,
        },
        pageSize: {
            type: Number,
            default: 25,
        },
        totalRows: {
            type: Number,
            default: 1,
        },
        visibleRows: {
            type: Number,
            default: 1,
        },
        canNextPage: {
            type: Boolean,
            default: false,
        },
        canPreviousPage: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['pager-clicked'],
    setup(props, { emit }) {
        const totalPages = computed(() => {
            return Math.ceil(props.totalRows / props.pageSize);
        });

        const firstRowIndex = computed(() => {
            return props.currentPageIndex * props.pageSize + 1;
        });

        const lastRowIndex = computed(() => {
            return firstRowIndex.value - 1 + props.visibleRows;
        });

        const pageItems = computed(() => {
            const pageArray: string[] = [];
            const pageCount = totalPages.value;
            const currentPage = props.currentPageIndex + 1;

            if (pageCount > 1) {
                if (pageCount < 7) {
                    for (let i = 1; i <= pageCount; i++) {
                        pageArray.push(i.toString());
                    }
                } else {
                    for (let i = 1; i <= 7; i++) {
                        switch (i) {
                            case 1:
                                pageArray.push('1');
                                break;
                            case 2:
                                if (currentPage <= 4) {
                                    pageArray.push('2');
                                } else {
                                    pageArray.push(VlDataTableExtendedPagerDots);
                                }
                                break;
                            case 3:
                                if (currentPage <= 4) {
                                    pageArray.push('3');
                                } else if (pageCount - currentPage < 4) {
                                    pageArray.push((pageCount - 4).toString());
                                } else {
                                    pageArray.push((currentPage - 1).toString());
                                }
                                break;
                            case 4:
                                if (currentPage <= 4) {
                                    pageArray.push('4');
                                } else if (pageCount - currentPage < 4) {
                                    pageArray.push((pageCount - 3).toString());
                                } else {
                                    pageArray.push(currentPage.toString());
                                }
                                break;
                            case 5:
                                if (currentPage <= 4) {
                                    pageArray.push('5');
                                } else if (pageCount - currentPage < 4) {
                                    pageArray.push((pageCount - 2).toString());
                                } else {
                                    pageArray.push((currentPage + 1).toString());
                                }
                                break;
                            case 6:
                                if (pageCount - currentPage < 4) {
                                    pageArray.push((pageCount - 1).toString());
                                } else {
                                    pageArray.push(VlDataTableExtendedPagerDots);
                                }
                                break;
                            case 7:
                                pageArray.push(pageCount.toString());
                                break;
                        }
                    }
                }
            }
            return pageArray;
        });

        function nextPage() {
            if (props.canNextPage) {
                emit('pager-clicked', props.currentPageIndex + 2);
            }
        }

        function previousPage() {
            if (props.canPreviousPage) {
                emit('pager-clicked', props.currentPageIndex);
            }
        }

        function goPage(page: number) {
            emit('pager-clicked', page);
        }

        function clickablePage(page: string) {
            return !(!parseInt(page) || parseInt(page) - 1 === props.currentPageIndex);
        }

        return {
            firstRowIndex,
            lastRowIndex,
            pageItems,
            nextPage,
            previousPage,
            goPage,
            clickablePage,
            VlDataTableExtendedPagerDots,
        };
    },
});
