import { FeatureKeys, isFeatureEnabled } from '@/common/config/config';
import { NavigationGuard, NavigationGuardNext, RouteLocation } from 'vue-router';

export interface MetaDataFeatures {
    feature?: FeatureKeys;
}

export function createHasFeatureGuard(): NavigationGuard {
    return async function (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): Promise<void> {
        const { feature } = to.meta as MetaDataFeatures;
        if (feature && !isFeatureEnabled(feature)) {
            next({
                name: 'home',
            });
        } else {
            next();
        }
    };
}
