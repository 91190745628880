import { vMaska } from 'maska';
import { App } from 'vue';

export function registerDirectives(app) {
    app.directive('scroll', {
        mounted(el, binding) {
            if (binding.value !== false) {
                el.scrollIntoView(true);
                // Adjust scroll position by header height
                const headerHeight = 50;
                window.scrollTo(0, window.scrollY - headerHeight);
            }
        },
    });

    app.directive('focus', {
        mounted: function (el) {
            el.focus();
        },
    });

    app.directive('maska', vMaska);
}

export default {
    install(app: App) {
        registerDirectives(app);
    },
};
