<template>
  <vl-layout>
    <vl-grid v-vl-spacer:bottom.medium class="pbs-form-group" mod-stacked-large>
      <vl-column width="3" width-s="6" width-xs="12">
        <vl-form-message-label for="id">{{
          $t('productprocessdefinition.list.filter.id.label')
        }}</vl-form-message-label>
        <vl-input-field
          id="id"
          v-model="filter.id"
          name="id"
          type="number"
          :placeholder="$t('productprocessdefinition.list.filter.id.label')"
          mod-block
        />
      </vl-column>

      <vl-column width="3" width-s="6" width-xs="12">
        <vl-form-message-label for="label">{{
          $t('productprocessdefinition.list.filter.label.label')
        }}</vl-form-message-label>
        <vl-input-field
          id="label"
          v-model="filter.label"
          name="label"
          type="search"
          :placeholder="$t('productprocessdefinition.list.filter.label.label')"
          mod-block
        />
      </vl-column>
      <vl-column width="6" width-s="6" width-xs="12">
        <vl-form-message-label for="description">{{
          $t('productprocessdefinition.list.filter.description.label')
        }}</vl-form-message-label>
        <vl-input-field
          id="description"
          v-model="filter.description"
          name="description"
          type="search"
          :placeholder="$t('productprocessdefinition.list.filter.description.label')"
          mod-block
        />
      </vl-column>
      <vl-column width="6" width-s="6" width-xs="12">
        <vl-form-message-label for="description">{{
          $t('productprocessdefinition.list.filter.organisation.label')
        }}</vl-form-message-label>
        <vl-multiselect
          id="organisation"
          v-model="filter.organisation"
          :options="organisationOptions"
          :mod-multiple="false"
          name="organisation"
          type="search"
          :placeholder="$t('productprocessdefinition.list.filter.organisation.label')"
          mod-block
          :custom-label="getLabel"
          @search-change="onSearchChange"
        />
      </vl-column>
      <vl-column width="6" width-s="6" width-xs="12">
        <vl-form-message-label for="statusId">{{
          $t('productprocessdefinition.list.filter.status.label')
        }}</vl-form-message-label>
        <PbsSelect
          id="statusId"
          v-model="filter.statusType"
          :placeholder="$t('vl-select.placeholder')"
          :options="statusTypes"
          :custom-label="(e) => e.name"
          keySelector="name"
        />
      </vl-column>

      <vl-column width="9" width-s="9" width-xs="12">
        <vl-action-group>
          <vl-button :mod-loading="loading" @click="search({ resetPager: true })">{{
            $t('productprocessdefinition.list.filter.search')
          }}</vl-button>
          <vl-button mod-secondary :mod-loading="loading" @click="clear">{{
            $t('productprocessdefinition.list.filter.clear')
          }}</vl-button>
        </vl-action-group>
      </vl-column>
      <vl-column width="3" width-s="3" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button mod-secondary @click="create">{{ $t('productprocessdefinition.list.filter.create') }}</vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>

    <vl-grid>
      <vl-data-table-extended
        v-if="items"
        :data="items"
        :columns="columnMap"
        :fetching="loading"
        :meta="metaData"
        mod-clickable-rows
        mod-zebra
        @column-clicked="columnClicked"
        @pager-clicked="pagerClicked"
      >
        <template v-if="!loading && !alert" v-slot:noresults>
          <div v-vl-align:left>
            <div class="vl-u-spacer--large">
              <vl-title tag-name="h2">{{ $t('productprocessdefinition.list.table.noResults.title') }}</vl-title>
              <p>{{ $t('productprocessdefinition.list.table.noResults.subtitle') }}</p>
            </div>
          </div>
        </template>
      </vl-data-table-extended>
    </vl-grid>
  </vl-layout>
</template>
<script lang="ts" setup>
import { Ref, computed, ref, h } from 'vue';
import { onMounted, onUnmounted } from 'vue';
import { ColumnDefExtended } from '@/components/vl-data-table-extended/types';
import { RowData } from '@tanstack/vue-table';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import {
  useProductProcessDefinitionListStore,
  ProductProcessDefinitionFilter,
} from '../../store/product-process-definition/product-process-definition-list.store';
import { IAlert, useAlertStore } from '@/modules/core/store/alert.store';
import {
  ProductProcessDefinitionSearchResultDto,
  OrganisationSearchResultDto,
  EnumerationDto,
} from '@/api/portal-api/clients';
import { useOrganisationStore } from '../../store/organisation/organisation.store';
import { ENUM_StatusTypes } from '../../common';
import { VlActionGroup } from '@govflanders/vl-ui-design-system-vue3';
import DefinitionActionButton from './subcomponents/definition-action-button/DefinitionActionButton.vue';
import PbsSelect from '@/components/forms/PbsSelect.vue';

const store = useProductProcessDefinitionListStore();
const organisationStore = useOrganisationStore();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const organisationOptions: Ref<OrganisationSearchResultDto[]> = ref([]);

const filter = computed((): ProductProcessDefinitionFilter => {
  return store.filter ?? {};
});

const loading = computed((): boolean => {
  return store.loading;
});

const items = computed((): ProductProcessDefinitionSearchResultDto & { routerLink?: string }[] => {
  return store.items?.map((i) => {
    return {
      ...i,
      routerLink: `/configuration/productprocessdefinition/${i.id}`,
    };
  });
});

const metaData = computed(() => {
  return store.metaData;
});

const columnMap = computed((): ColumnDefExtended<RowData>[] => {
  return [
    {
      accessorKey: 'id',
      header: t('productprocessdefinition.list.table.header.id'),
      enableSorting: true,
      size: 5,
      link: true,
    },
    {
      accessorKey: 'label',
      header: t('productprocessdefinition.list.table.header.label'),
      enableSorting: true,
      sortDescFirst: false,
      size: 50,
      link: true,
    },
    {
      accessorKey: 'organisation',
      header: t('productprocessdefinition.list.table.header.organisation'),
      enableSorting: true,
      sortDescFirst: false,
      size: 30,
      link: true,
    },
    {
      accessorKey: 'ovoNumber',
      header: t('productprocessdefinition.list.table.header.ovoNumber'),
      enableSorting: true,
      sortDescFirst: false,
      size: 20,
      link: true,
    },
    {
      accessorKey: 'statusId',
      header: t('productprocessdefinition.list.table.header.status'),
      enableSorting: true,
      size: 20,
      link: true,
      cell: (props) => {
        const statusType = statusTypes.value.find((s) => s.id === props?.getValue());
        return statusType?.name || '-';
      },
    },
    {
      accessorKey: '',
      header: t('productprocessdefinition.list.table.header.actions'),
      size: 10,
      cell: (props) => {
        const id = parseInt(props.row.getValue('id'), 10);
        return h(VlActionGroup, {}, () => [h(DefinitionActionButton, { id })]);
      },
    },
  ];
});

const statusTypes = computed((): EnumerationDto[] => {
  return ENUM_StatusTypes;
});

const alert = computed((): IAlert => {
  return useAlertStore().alert;
});

onMounted(() => {
  setTimeout(async () => {
    organisationOptions.value = await organisationStore.getOrganisations();
    store.init(createFilterFromRoute());
    search();
  }, 0);
});

onUnmounted(() => {
  store.clearFilter();
});

const clear = async () => {
  store.clearFilter();
  await search();
};

const search = async ({ resetPager }: { resetPager: boolean } = { resetPager: false }) => {
  if (resetPager) {
    store.resetPager();
  }

  await store.getDefinitions();
  if (!alert.value) {
    updateRoute();
  }
};

const create = () => {
  router.push({ name: 'productprocessdefinition.create' });
};

const createFilterFromRoute = (): ProductProcessDefinitionFilter => {
  const filter: ProductProcessDefinitionFilter = {};

  Object.keys(route.query).forEach((k) => {
    if (k === 'organisation') {
      filter[k] = organisationOptions.value.find(
        (x) => x.ovoNumber === encodeURIComponent(route.query[k] as string | number | boolean),
      );
    } else {
      filter[k] = encodeURIComponent(route.query[k] as string | number | boolean);
    }
  });

  return filter;
};

const updateRoute = () => {
  const query = {};
  Object.keys(store.filter).forEach((k) => {
    if (k === 'organisation') {
      query[k] = store.filter[k]?.ovoNumber ? encodeURIComponent(store.filter[k].ovoNumber) : '';
    } else {
      query[k] = encodeURIComponent(store.filter[k]);
    }
  });

  router.push({
    ...route,
    path: route.path,
    query,
  });
};

const pagerClicked = (pageNumber: number) => {
  store.updatePage(pageNumber);
  search();
};

const columnClicked = (evt: { key: string; direction: string }) => {
  store.updateSorting(evt.key, evt.direction);
  search({ resetPager: true });
};

const onSearchChange = async (query: string) => {
  organisationOptions.value = organisationStore.filter(query);
};

const getLabel = (entry: OrganisationSearchResultDto) => {
  return `${entry?.name} (${entry?.ovoNumber})`;
};
</script>
<style lang="scss" scoped>
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colorsRaw.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-form-group {
  background: $vl-alt-bg;
  padding: 3rem;
}

.vl-grid {
  flex-grow: 1;
}
</style>
