import { EntityAttributeDetailDto } from '@/api/portal-api/clients';
import { object, string, array, number, mixed } from 'yup';
import {
    getLookupOptions,
    hasSameEntityModel,
    isEntityMappingType,
    isLookupLinkType,
    isTransformationTypeFeatureSupported,
} from '@/modules/configuration/common';
import { useProductTransformationLookupLinkStore } from '@/modules/configuration/store/product-transformation-lookup-link/product-transformation-lookup-link.store';

export const useProductTransformationValidations = (t, definitionId: number = null) => {
    const validationSchema = object({
        transformationType: object().required(
            t('validations.messages.required', { _field_: t('producttransformation.detail.transformationType') }),
        ),
        fromEntity: object()
            .nullable()
            .when('transformationType', {
                is: (transformationType) => isTransformationTypeFeatureSupported(transformationType?.id, 'fromEntity'),
                then: (s) =>
                    s.required(
                        t('validations.messages.required', { _field_: t('producttransformation.detail.fromEntity') }),
                    ),
                otherwise: (s) => s.optional(),
            }),
        toEntity: object()
            .nullable()
            .when('transformationType', {
                is: (transformationType) => isTransformationTypeFeatureSupported(transformationType?.id, 'toEntity'),
                then: (s) =>
                    s.required(
                        t('validations.messages.required', { _field_: t('producttransformation.detail.toEntity') }),
                    ),
                otherwise: (s) => s.optional(),
            }),
        lookupLink: object()
            .nullable()
            .when('transformationType', {
                is: (transformationType) => isLookupLinkType(transformationType),
                then: (s) =>
                    s.required(
                        t('validations.messages.required', { _field_: t('producttransformation.detail.lookupLink') }),
                    ),
                otherwise: (s) => s.optional(),
            }),
        parameter: string()
            .nullable()
            .when('transformationType', {
                is: (transformationType) => isTransformationTypeFeatureSupported(transformationType?.id, 'parameter'),
                then: (s) =>
                    s.required(
                        t('validations.messages.required', { _field_: t('producttransformation.detail.parameter') }),
                    ),
                otherwise: (s) => s.optional(),
            }),
        attributeMappings: array()
            .nullable()
            .of(
                object().shape({
                    id: number().optional().nullable(),
                    from: object()
                        .required(
                            t('validations.messages.required', {
                                _field_: t('producttransformation.detail.fromAttribute'),
                            }),
                        )
                        .test(
                            'dataType-match',
                            t('validations.messages.matchPrerequisites', {
                                _field_: t('producttransformation.detail.attributeMapping'),
                            }),
                            checkEntityAttributesDataType({ testTo: true }),
                        ),
                    to: object()
                        .required(
                            t('validations.messages.required', {
                                _field_: t('producttransformation.detail.toAttribute'),
                            }),
                        )
                        .test(
                            'dataType-match',
                            t('validations.messages.matchPrerequisites', {
                                _field_: t('producttransformation.detail.attributeMapping'),
                            }),
                            checkEntityAttributesDataType({ testFrom: true }),
                        ),
                }),
            )
            .when('transformationType', {
                is: (transformationType) =>
                    isTransformationTypeFeatureSupported(transformationType?.id, 'attributeMappings'),
                then: (s) =>
                    s
                        .strict()
                        .required(t('producttransformation.detail.attributeMappingsRequired'))
                        .min(1, t('producttransformation.detail.attributeMappingsRequired')),
                otherwise: (s) => s.optional(),
            }),
        custom: mixed().test(
            'custom-validation',
            'The properties do not meet the validation criteria',
            function (_, context) {
                return customValidation(t, _, context, definitionId);
            },
        ),
    });

    return {
        validationSchema,
    };
};

function customValidation(t: any, value: any, context: any, definitionId: number = null) {
    const { fromEntity, toEntity, transformationType } = context.parent;

    if (!validateEntityMappingHasSameModel(transformationType, fromEntity, toEntity)) {
        return context.createError({
            message: t('producttransformation.detail.entityModelsNotEqual'),
            path: 'fromEntity',
        });
    }

    if (isLookupLinkType(transformationType)) {
        const lookupLinks = useProductTransformationLookupLinkStore().byDefinition(definitionId);

        if (!getLookupOptions(fromEntity, lookupLinks)?.length) {
            return context.createError({
                message: t('producttransformation.detail.noLookuplinkOnSourceEntity'),
                path: 'lookupLink',
            });
        }
    }

    return true;
}

function validateEntityMappingHasSameModel(transformationType, fromEntity, toEntity) {
    if (fromEntity && toEntity && isEntityMappingType(transformationType)) {
        return hasSameEntityModel(fromEntity, toEntity);
    }
    return true;
}

function checkEntityAttributesDataType({ testFrom, testTo }: { testFrom?: boolean; testTo?: boolean }) {
    const testFunction = (value, ctx) => {
        if (testFrom) {
            const { from } = ctx.parent;
            const to = value as EntityAttributeDetailDto;
            return !from || !to || (from.dataType && to.dataType && from.dataType.id === to.dataType.id);
        }
        if (testTo) {
            const { to } = ctx.parent;
            const from = value as EntityAttributeDetailDto;
            return !to || !from || (to.dataType && from.dataType && to.dataType.id === from.dataType.id);
        }
        return true;
    };
    return testFunction;
}
