<template>
  <form @submit="onSubmit">
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="label"
        :label="t('deliveryspecification.detail.label')"
        :placeholder="t('deliveryspecification.detail.label')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('deliveryspecification.detail.description')"
        :placeholder="t('deliveryspecification.detail.description')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="tolerance"
        type="number"
        step="0.001"
        :label="t('deliveryspecification.detail.tolerance')"
        :placeholder="t('deliveryspecification.detail.tolerance')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="schema"
        :label="t('deliveryspecification.detail.scheme')"
        :placeholder="t('deliveryspecification.detail.scheme')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="hasExactPrecision" :label="t('deliveryspecification.detail.hasExactPrecision')" />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="skipImport" :label="t('deliveryspecification.detail.skipImport')" />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="skipCheck" :label="t('deliveryspecification.detail.skipCheck')" />
    </vl-form-column>
    <vl-action-group v-vl-spacer:bottom.small>
      <vl-button icon="save" :mod-loading="reloading" mod-icon-before type="submit">
        {{ $t('general.save') }}
      </vl-button>
      <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeCreate">
        {{ $t('general.cancel') }}
      </vl-button>
    </vl-action-group>
  </form>
</template>
<script lang="ts" setup>
import { useDeliverySpecificationStore } from '@/modules/configuration/store/delivery-specification/delivery-specification.store';
import { useDeliveryProcessDefinitionEditStore } from '@/modules/configuration/store/delivery-process-definition/delivery-process-definition-edit.store';
import { useForm } from 'vee-validate';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { boolean, number, object, string } from 'yup';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';

const store = useDeliverySpecificationStore();
const editStore = useDeliveryProcessDefinitionEditStore();

const { t } = useI18n();
const route = useRoute();

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('deliveryspecification.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('deliveryspecification.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('deliveryspecification.detail.description') }),
  ),
  schema: string().required(t('validations.messages.required', { _field_: t('deliveryspecification.detail.scheme') })),
  tolerance: number().required(
    t('validations.messages.required', { _field_: t('deliveryspecification.detail.tolerance') }),
  ),
  hasExactPrecision: boolean(),
  skipImport: boolean(),
  skipCheck: boolean(),
});

const { handleSubmit } = useForm({
  validationSchema,
});

const reloading = computed((): boolean => {
  return store.reloading;
});

const definitionId = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

const onSuccess = async (values) => {
  await store.create(definitionId.value, {
    deliveryProcessDefinitionId: definitionId.value,
    label: values.label,
    description: values.description,
    schema: values.schema,
    tolerance: values.tolerance,
    hasExactPrecision: values.hasExactPrecision,
    skipImport: values.skipImport,
    skipCheck: values.skipCheck,
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);
</script>
<style lang="scss" scoped>
:deep(.vl-accordion__panel) {
  padding-right: 0;
}
</style>
