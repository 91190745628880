<template>
  <component :is="tagName" :class="classes">
    <vl-link class="vl-pager__element__cta" v-bind="$attrs" @click="handleClick">
      <span class="vl-u-visually-hidden">{{ a11yLabel }}</span
      >{{ number }}
    </vl-link>
  </component>
</template>
<script lang="ts" src="./vl-data-table-extended-pager-page.ts"></script>

