import { DeliveryFilter, Paging, Sorting } from '@/api';
import { Dictionary } from '@/types';

export function getDates(value: Date | string): Date[] | undefined {
    if (!value) {
        return;
    }

    if (typeof value === 'string') {
        value = new Date(value);
    }

    value && value.setHours(0, 0, 0, 0);
    return [value];
}

export function filterFromQueryString(queryString: Dictionary<string | (string | null)[]> = {}): DeliveryFilter {
    const filter: DeliveryFilter = {};

    if (queryString.definition) {
        let value: string;
        if (Array.isArray(queryString.definition)) {
            value = queryString.definition[0] || '';
        } else {
            value = queryString.definition;
        }
        filter.definition = parseInt(decodeURIComponent(value), 10);
    }
    if (queryString.supplier) {
        let value: string;
        if (Array.isArray(queryString.supplier)) {
            value = queryString.supplier[0] || '';
        } else {
            value = queryString.supplier;
        }
        filter.supplier = parseInt(decodeURIComponent(value), 10);
    }
    if (queryString.fileName) {
        let value: string;
        if (Array.isArray(queryString.fileName)) {
            value = queryString.fileName[0] || '';
        } else {
            value = queryString.fileName;
        }
        filter.fileName = decodeURIComponent(value);
    }
    if (queryString.username) {
        let value: string;
        if (Array.isArray(queryString.username)) {
            value = queryString.username[0] || '';
        } else {
            value = queryString.username;
        }
        filter.username = decodeURIComponent(value);
    }
    if (queryString.startExecutionFrom) {
        let value: string;
        if (Array.isArray(queryString.startExecutionFrom)) {
            value = queryString.startExecutionFrom[0] || '';
        } else {
            value = queryString.startExecutionFrom;
        }
        filter.startExecutionFrom = value ? new Date(decodeURIComponent(value)) : null;
    }
    if (queryString.startExecutionTo) {
        let value: string;
        if (Array.isArray(queryString.startExecutionTo)) {
            value = queryString.startExecutionTo[0] || '';
        } else {
            value = queryString.startExecutionTo;
        }
        filter.startExecutionTo = value ? new Date(decodeURIComponent(value)) : null;
    }
    if (queryString.status) {
        let value: string;
        if (Array.isArray(queryString.status)) {
            value = queryString.status[0] || '';
        } else {
            value = queryString.status;
        }
        filter.status = parseInt(decodeURIComponent(value), 10);
    }

    return filter;
}

export function pagingFromQueryString(queryString: Dictionary<string | (string | null)[]> = {}): Paging {
    const paging: Paging = { pageNumber: 1, pageSize: 10 };
    if (queryString.pageNumber) {
        let value: string;
        if (Array.isArray(queryString.pageNumber)) {
            value = queryString.pageNumber[0] || '';
        } else {
            value = queryString.pageNumber;
        }
        paging.pageNumber = parseInt(decodeURIComponent(value), 10);
    }
    if (queryString.pageSize) {
        let value: string;
        if (Array.isArray(queryString.pageSize)) {
            value = queryString.pageSize[0] || '';
        } else {
            value = queryString.pageSize;
        }
        paging.pageSize = parseInt(decodeURIComponent(value), 10);
    }
    return paging;
}

export function sortingFromQueryString(queryString: Dictionary<string | (string | null)[]> = {}): Sorting {
    const sorting: Sorting = {};
    if (queryString.by) {
        let value: string;
        if (Array.isArray(queryString.by)) {
            value = queryString.by[0] || '';
        } else {
            value = queryString.by;
        }
        sorting.by = decodeURIComponent(value);
    }
    if (queryString.descending) {
        let value: string;
        if (Array.isArray(queryString.descending)) {
            value = queryString.descending[0] || '';
        } else {
            value = queryString.descending;
        }
        sorting.descending = !!value;
    }
    return sorting;
}
