<template>
  <vl-form-message-label v-if="label" :for="name">
    {{ label }}
  </vl-form-message-label>
  <Field v-slot="{ handleChange, value, errors }" :name="name">
    <vl-input-field
      v-bind="attrs"
      :id="name"
      :name="name"
      :modelValue="value?.toString()"
      :mod-error="!!errors.length"
      mod-block
      @input="handleChange"
    ></vl-input-field>
    <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
  </Field>
</template>

<script lang="ts" setup>
import { Field } from 'vee-validate';
import { useAttrs } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
});

const attrs = useAttrs();
</script>
