<template>
  <vl-title tag-name="h4"> {{ $t('producttransformation.detail.attributes') }} </vl-title>
  <vl-data-table-extended
    :data="items"
    :columns="columnMap"
    :meta="{
      totalRows: props.detail.attributeMappings?.length,
      resultsPerPage: props.detail.attributeMappings?.length,
      currentPage: 1,
    }"
    mod-zebra
    mod-hide-pagination
  >
    <template v-slot:noresults>
      <div v-vl-align:left>
        <div class="vl-u-spacer--large">
          <p>{{ $t('productTransformationAttributes.detail.empty') }}</p>
        </div>
      </div>
    </template>
  </vl-data-table-extended>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { EntityAttributeDetailDto, ProductTransformationDetailDto } from '@/api/portal-api/clients';
import { useI18n } from 'vue-i18n';
import { ColumnDefExtended } from '@/components/vl-data-table-extended/types';
import { RowData } from '@tanstack/vue-table';

const { t } = useI18n();
const props = defineProps<{
  detail: ProductTransformationDetailDto;
}>();

const items = computed(() => {
  return (
    props.detail.attributeMappings
      ?.map((f) => {
        return {
          from: getAttributeLabel(f.fromAttribute),
          to: getAttributeLabel(f.toAttribute),
        };
      })
      .sort((x, y) => (x.from > y.from ? 1 : -1)) ?? []
  );
});

const getAttributeLabel = (attribute: EntityAttributeDetailDto): string => {
  if (!attribute) {
    return '/';
  }

  const code = attribute.code ?? '';
  const label = attribute.label ?? '';
  if (code?.toLowerCase() === label?.toLowerCase()) {
    return code;
  }
  return `${code} - ${label}`;
};

const columnMap = computed((): ColumnDefExtended<RowData>[] => {
  return [
    {
      accessorKey: 'from',
      header: t('producttransformation.detail.fromKey'),
      size: 50,
    },
    {
      accessorKey: 'to',
      header: t('producttransformation.detail.toKey'),
      size: 50,
    },
  ];
});
</script>
