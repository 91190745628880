import { RouteRecordRaw } from 'vue-router';

import OrganisationSelection from './OrganisationSelection.vue';
import { OrganisationType } from './OrganisationType';

export type OrganisationService = OrganisationType;

export * from './organisation.guard';

export function createOrganisationRoutes(): Array<RouteRecordRaw> {
    return [
        {
            name: 'organisation.switch',
            path: '/organisation/switch',
            component: OrganisationSelection,
        },
    ];
}
