import { createInstance, dateReviver } from '@/api';
import {
    SourceEntityCreateDto,
    SourceEntityClient,
    SourceEntityDetailDto,
    SourceEntityUpdateDto,
    DataConnectionDto,
    DataConnectionClient,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useProductProcessDefinitionEditStore } from '../product-process-definition/product-process-definition-edit.store';

export interface sourceentityStoreState {
    items?: DataConnectionDto[]
    loading: boolean
}

export const client = new DataConnectionClient('', createInstance(), dateReviver);

export const useDataconnectionStore = defineStore('dataconnection-store', {
    state: (): sourceentityStoreState => ({
        items: [],
        loading: false,
    }),
    getters: {},
    actions: {
        async get(){
            this.loading = true
            try {
                const response = await client.get();
                this.items = response
            } catch {
                useAlertStore().setError(
                    i18n.global.t('dataconnection.detail.error.get.title'),
                    i18n.global.t('dataconnection.detail.error.get.content'),
                );
            } finally {
                this.loading = false;
            }
        },
    },
});
