import Vue, { reactive } from 'vue';

import { clients, IProductRunClient, ProductRun } from '@/api';

export class ProductMetadataService {
    public state: {
        product: ProductRun;
        loading: boolean;
        readonly: boolean;
    } = reactive({ product: {}, loading: false, readonly: false });

    constructor(private productClient: IProductRunClient) {}

    get product(): ProductRun {
        return this.state.product;
    }

    get loading(): boolean {
        return this.state.loading;
    }
    get readonly(): boolean {
        return this.state.readonly;
    }

    setMetadataUuid(uuid: string): void {
        this.state.product = { ...this.product, metadata: { ...(this.product.metadata || {}), uuid } };
    }
    setVersionDate(versionDate: Date): void {
        this.state.product = { ...this.product, metadata: { ...(this.product.metadata || {}), versionDate } };
    }
    setDescription(versionDescription: string): void {
        this.state.product = { ...this.product, metadata: { ...(this.product.metadata || {}), versionDescription } };
    }
    setTemporalLimitationFrom(temporalLimitationFrom?: Date): void {
        this.state.product = {
            ...this.product,
            metadata: { ...(this.product.metadata || {}), temporalLimitationFrom },
        };
    }
    setTemporalLimitationTo(temporalLimitationTo?: Date): void {
        this.state.product = { ...this.product, metadata: { ...(this.product.metadata || {}), temporalLimitationTo } };
    }

    setProduct(payload): void {
        this.setMetadataUuid(payload.uuid);
        this.setVersionDate(payload.versionDate);
        this.setDescription(payload.versionDescription);
        this.setTemporalLimitationFrom(payload.temporalLimitationFrom);
        this.setTemporalLimitationTo(payload.temporalLimitationTo);
    }

    async update(payload): Promise<boolean> {
        this.setProduct(payload);

        if (!this.product?.id || this.readonly) {
            return false;
        }
        this.state.loading = true;
        try {
            await this.productClient.updateMetadata(this.product.id, this.product.metadata || {});
            this.getData(this.product.id);
            this.state.loading = false;
            return true;
        } catch (e) {
            this.state.loading = false;
            throw e;
        }
    }
    async init(productId: number): Promise<void> {
        this.reset();
        await this.getData(productId);
    }

    async getData(productId: number): Promise<void> {
        this.state.product = await this.productClient.get(productId);
        this.state.readonly = !!this.state.product.metadata?.uuid;
    }

    reset(): void {
        this.state.product = {};
        this.state.loading = false;
        this.state.readonly = false;
    }
}

export const ProductMetadataServiceInstance = new ProductMetadataService(clients.ProductRunClient);
