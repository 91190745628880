<template>
  <form>
    <vl-properties-list>
      <vl-properties-label>{{ $t('processtrigger.detail.id') }}</vl-properties-label>
      <vl-properties-data>{{ detail.id }}</vl-properties-data>
    </vl-properties-list>

    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="triggerType"
        :label="t('processtrigger.detail.triggerType')"
        :placeholder="$t('processtrigger.detail.triggerType')"
        :options="triggerTypes"
        :custom-label="enumerationLabel"
      />
    </vl-form-column>

    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('processtrigger.detail.description')"
        :placeholder="t('processtrigger.detail.description')"
      />
    </vl-form-column>

    <vl-form-column v-if="isTimeTrigger" v-vl-spacer:bottom.small>
      <PbsSelectField
        name="timeTriggerType"
        :label="t('processtrigger.detail.timeTriggerType')"
        :placeholder="$t('processtrigger.detail.timeTriggerType')"
        :options="timeTriggerTypes"
        :custom-label="enumerationLabel"
      />
    </vl-form-column>

    <vl-form-column v-if="isTimeTrigger && isTimeTriggerType('Levering')" v-vl-spacer:bottom.small>
      <PbsSelectField
        name="deliveryProcessDefinition"
        :label="t('processtrigger.detail.deliveryProcessDefinition')"
        :placeholder="$t('processtrigger.detail.deliveryProcessDefinition')"
        :options="deliveryProcessDefinitionOptions"
        :custom-label="(e) => e.label"
        @search-change="onSearchDeliveryProcessDefinitions"
      />
    </vl-form-column>

    <vl-form-column v-if="isTimeTrigger && isTimeTriggerType('Product')" v-vl-spacer:bottom.small>
      <PbsSelectField
        name="productProcessDefinition"
        :label="t('processtrigger.detail.productProcessDefinition')"
        :placeholder="$t('processtrigger.detail.productProcessDefinition')"
        :options="productProcessDefinitionOptions"
        :custom-label="(e) => e.label"
        @search-change="onSearchProductProcessDefinitions"
      />
    </vl-form-column>

    <vl-form-column v-if="isTimeTrigger && hasTimeTriggerType" v-vl-spacer:bottom.small>
      <PbsInputField
        name="schedule"
        :label="t('processtrigger.detail.schedule')"
        :placeholder="t('processtrigger.detail.schedule')"
      />
      <div v-if="!isInvalidCronSchedule && cronDescription">{{ cronDescription }}</div>
    </vl-form-column>

    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="isEnabled" :label="$t('processtrigger.detail.isEnabled')" />
    </vl-form-column>
  </form>
</template>

<script lang="ts" setup>
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { useProcessTriggerDetailStore } from '../../store/process-trigger/process-trigger-detail.store';
import { useRouter } from 'vue-router';
import { Ref, computed, onMounted, ref, watch } from 'vue';
import {
  EnumerationDto,
  DeliveryProcessDefinitionSearchResultDto,
  ProductProcessDefinitionSearchResultDto,
  ProcessTriggerDetailDto,
} from '@/api/portal-api/clients';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsGoBackLink from '@/components/PbsGoBackLink.vue';
import { useDeliveryProcessDefinitionListStore } from '../../store/delivery-process-definition/delivery-process-definition-list.store';
import { useProcessTriggerValidations, INVALID_CRON_SCHEDULE } from './process-trigger-validations';
import { ENUM_TimeTriggerTypes, ENUM_TriggerTypes } from '../../common';
import { useProductProcessDefinitionListStore } from '../../store/product-process-definition/product-process-definition-list.store';

const store = useProcessTriggerDetailStore();
const deliveryProcessDefinitionStore = useDeliveryProcessDefinitionListStore();
const productProcessDefinitionStore = useProductProcessDefinitionListStore();
const { t } = useI18n();

const deliveryProcessDefinitionOptions: Ref<DeliveryProcessDefinitionSearchResultDto[]> = ref([]);
const productProcessDefinitionOptions: Ref<ProductProcessDefinitionSearchResultDto[]> = ref([]);

const detail = computed((): ProcessTriggerDetailDto => {
  return store.detail;
});

const { validationSchema, triggerTypeRef } = useProcessTriggerValidations(t, detail.value);

const { resetForm, handleSubmit, values, setFieldValue } = useForm({
  validationSchema,
});

const triggerType = computed(() => {
  return values.triggerType;
});

const supportedTriggerTypes = [3];

const triggerTypes = computed((): EnumerationDto[] => {
  return ENUM_TriggerTypes.filter((e) => supportedTriggerTypes.includes(e.id));
});

const timeTriggerTypes = computed((): EnumerationDto[] => {
  return ENUM_TimeTriggerTypes;
});

const isTimeTrigger = computed(() => {
  return values.triggerType?.id === 3;
});

const hasTimeTriggerType = computed(() => {
  return !!values.timeTriggerType;
});

const isTimeTriggerType = (type: string) => {
  return values.timeTriggerType?.name === type;
};

watch(triggerType, (newValue, oldValue) => {
  triggerTypeRef.value = newValue;
});

const schedule = computed(() => {
  return values.schedule;
});

watch(schedule, async (newValue, oldValue) => {
  if (newValue) {
    await store.getDescription(newValue);
  }
});

const isInvalidCronSchedule = computed(() => {
  return !values.validCronSchedule;
});

const cronDescription = computed(() => {
  return store.cronDescription;
});

watch(cronDescription, (newValue, oldValue) => {
  const validCronSchedule = newValue !== INVALID_CRON_SCHEDULE;
  setFieldValue('validCronSchedule', validCronSchedule);
  console.log('validCronSchedule', validCronSchedule);
});

onMounted(async () => {
  deliveryProcessDefinitionOptions.value = await deliveryProcessDefinitionStore.getAll();
  productProcessDefinitionOptions.value = await productProcessDefinitionStore.getAll();

  let timeTriggerType = null;
  if (detail.value.triggerType?.id == 3) {
    if (detail.value.deliveryProcessDefinition) {
      timeTriggerType = ENUM_TimeTriggerTypes[0];
    } else if (detail.value.productProcessDefinition) {
      timeTriggerType = ENUM_TimeTriggerTypes[1];
    } else if (detail.value.job) {
      timeTriggerType = ENUM_TimeTriggerTypes[2];
    }
  }

  resetForm({
    values: {
      triggerType: detail.value.triggerType,
      timeTriggerType,
      description: detail.value.description,
      schedule: detail.value.schedule,
      job: detail.value.job,
      deliveryProcessDefinition: detail.value.deliveryProcessDefinition,
      productProcessDefinition: detail.value.productProcessDefinition,
      isEnabled: detail.value.isEnabled,
    },
  });
});

const onSuccess = async (values) => {
  const timeTriggerType = values.timeTriggerType?.id;

  await store.update(detail.value.id, {
    triggerType: values.triggerType.name,
    description: values.description,
    deliveryProcessDefinitionId: timeTriggerType == 1 ? values.deliveryProcessDefinition?.id : null,
    productProcessDefinitionId: timeTriggerType == 2 ? values.productProcessDefinition?.id : null,
    job: timeTriggerType == 3 ? 'SwitchJob' : null,
    schedule: values.schedule,
    isEnabled: values.isEnabled,
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  console.log(values); // current form values
  console.log(errors); // a map of field names and their first error message
  console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const onSearchDeliveryProcessDefinitions = async (query: string) => {
  deliveryProcessDefinitionOptions.value = deliveryProcessDefinitionStore.search(query);
};

const onSearchProductProcessDefinitions = async (query: string) => {
  productProcessDefinitionOptions.value = productProcessDefinitionStore.search(query);
};

const enumerationLabel = (enumerationDto: EnumerationDto) => {
  return `${enumerationDto.name}`;
};

defineExpose({ onSubmit });
</script>
, DeliveryProcessDefinitionSearchResultDtoRef, , ref
