import { createInstance, dateReviver } from '@/api';
import {
    CreateProductSpecificationDto,
    ProductSpecificationClient,
    ProductSpecificationDetailDto,
    UpdateProductSpecificationDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useProductProcessDefinitionEditStore } from '../product-process-definition/product-process-definition-edit.store';

export interface ProductSpecificationStoreState {
    items?: { key: string; value: ProductSpecificationDetailDto[] }[];
    loading: boolean;
    reloading: boolean;
}

export const client = new ProductSpecificationClient('', createInstance(), dateReviver);

export const useProductSpecificationStore = defineStore('configuration-product-specification-store', {
    state: (): ProductSpecificationStoreState => ({
        items: [],
        loading: false,
        reloading: false,
    }),
    getters: {
        byDefinition: (state) => {
            return (definitionId: number): ProductSpecificationDetailDto[] => {
                const key = `definition-${definitionId}`;

                return state.items.find((x) => x.key === key)?.value ?? [];
            };
        },
    },
    actions: {
        async getByDefinition(definitionId: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);
            const key = `definition-${definitionId}`;

            try {
                const response = await client.getByDefinition(definitionId);
                const items = this.items.filter((x) => x.key !== key);
                if (response?.length) {
                    this.items = [...items, { key, value: response }];
                } else {
                    this.items = [...items];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productspecification.detail.error.get.title'),
                    i18n.global.t('productspecification.detail.error.get.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async create(definitionId: number, dto: CreateProductSpecificationDto) {
            this.reloading = true;
            try {
                const response = await client.create(dto);
                useAlertStore().setSuccess(
                    i18n.global.t('productspecification.detail.success.create.title'),
                    null,
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                this.getByDefinition(definitionId, true);
                return response.id;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productspecification.detail.error.create.title'),
                    i18n.global.t('productspecification.detail.error.create.content'),
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } finally {
                this.reloading = false;
                useProductProcessDefinitionEditStore().closeCreate();
            }
        },
        async update(definitionId: number, specificationId: number, dto: UpdateProductSpecificationDto) {
            this.reloading = true;
            try {
                await client.update(specificationId, dto);
                useAlertStore().setSuccess(
                    i18n.global.t('productspecification.detail.success.update.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                this.getByDefinition(definitionId, true);
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('productspecification.detail.error.update.title'),
                    i18n.global.t('productspecification.detail.error.update.content'),
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } finally {
                this.reloading = false;
                useProductProcessDefinitionEditStore().closeEdit();
            }
        },
        async remove(definitionId: number, specificationId: number) {
            this.reloading = true;
            try {
                await client.delete(specificationId);
                useAlertStore().setSuccess(
                    i18n.global.t('productspecification.detail.success.delete.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                await this.getByDefinition(definitionId, true);
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productspecification.detail.error.delete.title'),
                    i18n.global.t('productspecification.detail.error.delete.content'),
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } finally {
                this.reloading = false;
            }
        },
        clear(definitionId: number) {
            const key = `definition-${definitionId}`;

            const specifications = this.items.find((x) => x.key === key)?.value;

            if (specifications) {
                specifications.forEach((specification) => {
                    // useProductEntityStore().clear(specification.id);
                });
            }

            this.items = this.items.filter((x) => x.key !== key);
        },
    },
});
