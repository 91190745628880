import { createInstance, dateReviver } from '@/api';
import {
    CreateDeliveryProcessDefinitionDto,
    DeliveryProcessDefinitionClient,
    DeliveryProcessDefinitionDetailDto,
    UpdateDeliveryProcessDefinitionDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useDeliverySpecificationStore } from '../delivery-specification/delivery-specification.store';
import { useDeliveryProcessDefinitionEditStore } from './delivery-process-definition-edit.store';
import { useOperationSpecificationStore } from '../operation-specification/operation-specification.store';
import { ENUM_StatusTypes } from '../../common';

export interface DeliveryProcessDefinitionDetailStoreState {
    detail?: DeliveryProcessDefinitionDetailDto;
    loading: boolean;
    reloading: boolean;
}

export const client = new DeliveryProcessDefinitionClient('', createInstance(), dateReviver);

export const useDeliveryProcessDefinitionDetailStore = defineStore(
    'configuration-delivery-process-definition-detail-store',
    {
        state: (): DeliveryProcessDefinitionDetailStoreState => ({
            detail: null,
            loading: false,
            reloading: false,
        }),
        getters: {
            isDraft(): boolean {
                return this.detail?.statusId === ENUM_StatusTypes.find((x) => x.name === 'Draft')?.id;
            },
        },
        actions: {
            async get(id: number, reloading: boolean = false) {
                reloading ? (this.reloading = true) : (this.loading = true);
                try {
                    this.detail = await client.getDetail(id);
                    return this.detail;
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('deliveryprocessdefinition.detail.error.get.title'),
                        i18n.global.t('deliveryprocessdefinition.detail.error.get.content'),
                    );
                } finally {
                    reloading ? (this.reloading = false) : (this.loading = false);
                }
            },
            async create(dto: CreateDeliveryProcessDefinitionDto): Promise<number> {
                this.loading = true;
                let response = null;
                try {
                    response = await client.create(dto);
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('deliveryprocessdefinition.create.error.title'),
                        i18n.global.t('deliveryprocessdefinition.create.error.content'),
                    );
                } finally {
                    useDeliveryProcessDefinitionEditStore().closeEdit();
                    this.loading = true;
                }
                return response?.id;
            },
            async update(id: number, dto: UpdateDeliveryProcessDefinitionDto) {
                this.reloading = true;
                try {
                    await client.update(id, dto);
                    useAlertStore().setSuccess(
                        i18n.global.t('deliveryprocessdefinition.detail.success.update.title'),
                        '',
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                    await this.get(id, true);
                    return true;
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('deliveryprocessdefinition.detail.error.update.title'),
                        i18n.global.t('deliveryprocessdefinition.detail.error.update.content'),
                    );
                } finally {
                    useDeliveryProcessDefinitionEditStore().closeEdit();
                    this.reloading = false;
                }
            },
            async delete(id: number) {
                this.reloading = true;
                try {
                    await client.delete(id);
                    useAlertStore().setSuccess(
                        i18n.global.t('deliveryprocessdefinition.detail.success.delete.title'),
                        '',
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                    await this.get(id, true);
                    return true;
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('deliveryprocessdefinition.detail.error.delete.title'),
                        i18n.global.t('deliveryprocessdefinition.detail.error.delete.content'),
                    );
                } finally {
                    this.reloading = false;
                }
            },
            async changeStatus(id: number, statusType: string): Promise<boolean> {
                this.reloading = true;
                try {
                    await client.changeStatus(id, statusType);
                    useAlertStore().setSuccess(
                        i18n.global.t('deliveryprocessdefinition.detail.success.update.title'),
                        '',
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                    await this.get(id, true);
                    return true;
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('deliveryprocessdefinition.detail.error.update.title'),
                        i18n.global.t('deliveryprocessdefinition.detail.error.update.content'),
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                    return false;
                } finally {
                    this.reloading = false;
                }
            },
            async duplicate(
                id: number,
                {
                    duplicateEntityModels = false,
                    duplicateProductProcessDefinitions = false,
                }: { duplicateEntityModels: boolean; duplicateProductProcessDefinitions: boolean },
            ): Promise<number> {
                let response = null;
                try {
                    response = await client.duplicate(id, {
                        duplicateEntityModels,
                        duplicateProductProcessDefinitions,
                    });
                    useAlertStore().setSuccess(
                        i18n.global.t('deliveryprocessdefinition.detail.success.duplicate.title'),
                        '',
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                } catch (error) {
                    useAlertStore().setError(
                        i18n.global.t('deliveryprocessdefinition.detail.error.duplicate.title'),
                        i18n.global.t('deliveryprocessdefinition.detail.error.duplicate.content'),
                        VerticalPosition.Bottom,
                        HorizontalPosition.Right,
                    );
                }
                return response?.id;
            },
            clear(id: number) {
                this.detail = null;
                useDeliverySpecificationStore().clear(id);
                useOperationSpecificationStore().clear(id);
            },
        },
    },
);
