<template>
  <form>
    <vl-properties-list v-vl-spacer:bottom.small>
      <vl-properties-label>{{ $t('operationspecification.detail.id') }}</vl-properties-label>
      <vl-properties-data>{{ props.detail.id }}</vl-properties-data>
    </vl-properties-list>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="label"
        :label="t('operationspecification.detail.label')"
        :placeholder="t('operationspecification.detail.label')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('operationspecification.detail.description')"
        :placeholder="t('operationspecification.detail.description')"
      />
    </vl-form-column>
  </form>
</template>
<script lang="ts" setup>
import { OperationSpecificationDetailDto } from '@/api/portal-api/clients';
import { useOperationSpecificationStore } from '@/modules/configuration/store/operation-specification/operation-specification.store';
import { useForm } from 'vee-validate';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { object, string } from 'yup';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';

const store = useOperationSpecificationStore();

const { t } = useI18n();
const route = useRoute();

const props = defineProps<{
  detail: OperationSpecificationDetailDto;
}>();

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('operationspecification.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('operationspecification.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('operationspecification.detail.description') }),
  ),
});

const { resetForm, handleSubmit } = useForm({
  validationSchema,
});

const definitionId = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

onMounted(() => {
  resetForm({ values: props.detail });
});

const onSuccess = async (values) => {
  await store.update(definitionId.value, props.detail.id, {
    label: values.label,
    description: values.description,
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
<style lang="scss" scoped>
:deep(.vl-accordion__panel) {
  padding-right: 0;
}
</style>
