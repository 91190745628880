<template>
  <vl-pager mod-align="right" mod-align-l="right" mod-align-m="right" mod-align-xs="right">
    <vl-pager-bounds
      :from="firstRowIndex.toString()"
      :to="lastRowIndex.toString()"
      :total="totalRows.toString()"
      page-label="Pagina"
      prefix="van"
    />
    <vl-pager-item
      v-if="totalRows > pageSize"
      a11y-label="vorige"
      label="Vorige"
      type="previous"
      @click="previousPage"
    />
    <vl-data-table-extended-pager-page
      v-for="page in pageItems"
      :key="page"
      :mod-active="page === (currentPageIndex + 1).toString() || page === VlDataTableExtendedPagerDots"
      :number="page"
      a11y-label="page"
      @click="clickablePage(page) && goPage(parseInt(page))"
    />
    <vl-pager-item v-if="totalRows > pageSize" a11y-label="volgende" label="Volgende" type="next" @click="nextPage" />
  </vl-pager>
</template>
<script lang="ts" src="./vl-data-table-extended-pager.ts" />
