import { i18n } from '@/i18n';
import { configuratiebeheerder, InternalUserRoleName } from './roleTypes';
import { FeatureKeys, isFeatureEnabled } from '@/common/config/config';

export type IDomainConfiguration = {
    title: string;
    content: string;
    roles: string[];
    route: string;
    feature?: FeatureKeys;
    icon?: string;
};

const domainConfiguration: IDomainConfiguration[] = [
    {
        title: i18n.global.t('navigation.deliveries.title'),
        content: i18n.global.t('navigation.deliveries.subtitle'),
        roles: [InternalUserRoleName],
        route: '/delivery',
        icon: 'delivery',
    },
    {
        title: i18n.global.t('navigation.products.title'),
        content: i18n.global.t('navigation.products.subtitle'),
        roles: [InternalUserRoleName],
        route: '/product',
        icon: 'product',
    },
    {
        title: i18n.global.t('navigation.configuration.title'),
        content: i18n.global.t('navigation.configuration.subtitle'),
        roles: [configuratiebeheerder],
        route: '/configuration',
        feature: 'configuration',
        icon: 'configuration',
    },
];

const filterDomainByFeature = (domain) => {
    return domain.feature ? isFeatureEnabled(domain.feature) : true;
};

export const getDomainConfigurations = () => domainConfiguration.filter(filterDomainByFeature);
