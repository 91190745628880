import { defineStore } from 'pinia';

import { clients, ProductSpecification } from '@/api';

export type ProductSpecificationStoreActions = {
    fetchSpecifications: () => Promise<void>;
};

export type ProductSpecificationStoreState = {
    specifications: ProductSpecification[];
};

export const useProductSpecificationStore = defineStore<
    string,
    ProductSpecificationStoreState,
    {},
    ProductSpecificationStoreActions
>('product-specification-store', {
    state: () => ({
        specifications: [] as ProductSpecification[],
    }),
    actions: {
        async fetchSpecifications(): Promise<void> {
            const specifications = await clients.ProductSpecificationClient.getAll();
            this.specifications = specifications;
        },
    },
});

export interface IProductSpecificationStore extends ProductSpecificationStoreState, ProductSpecificationStoreActions {}
